<div class="max-w-screen-max-screen">
  <div class="bg-black-200 rounded-[0.625rem] p-6">
    <div class="bg-white rounded-[0.625rem] p-6 r-screen-[25rem] relative">
      <div class="flex gap-2">
        <est-input appliedClass="w-[9.375rem]" label="Legal entity id" [control]="legalEntityIdControl"></est-input>
        <est-button
          (click)="open()"
          appliedClasses="mb-4"
          buttonStyleType="button--primary"
          buttonType="button"
          label="Open customer card"
        ></est-button>
      </div>

      <!-- <est-button
        (click)="openInviteCustomerOnsiteFlow()"
        buttonStyleType="button--primary"
        buttonType="button"
        label="Invtie customer onsite"
      >
      </est-button> -->
    </div>
  </div>
</div>
