<div class="sticky top-0 bg-white z-10">
  <div class="flex py-6 px-6 justify-between">
    <div class="flex gap-2 items-center sticky top-0">
      <p class="text-xl font-medium mb-1">
        {{ "UPLOAD_FILE.NAME" | translate }}
      </p>
      <button
        [matTooltip]="'UPLOAD_FILE.NAME' | translate"
        class="text-xs text-white bg-primary font-normal rounded-full w-4 h-4 inline-block text-center -translate-y-0.5 cursor-pointer"
        type="button"
      >
        ?
      </button>
    </div>
    <button (click)="closeDialog()" type="button">
      <img
        [alt]="'CLOSE_ICON' | translate"
        height="44"
        ngSrc="assets/images/icons/close-icon.svg"
        priority
        width="44"
      />
    </button>
  </div>
  <mat-divider />
  <div class="max-h-[calc(100dvh-14rem)] overflow-y-auto relative p-6">
    <div class="flex gap-2">
      <div class="flex-1">
        <est-upload-file-drop-zone
          [maxFileSize]="componentInputs.maxSizeInMb || 5"
          (fileUpload)="updateControl($event)"
          [isMultiple]="componentInputs.isMultiple"
          [supportedTypes]="componentInputs.supportedTypes"
          appliedClasses="!h-[20rem]"
        ></est-upload-file-drop-zone>
      </div>
      @if (controlFilesArray && controlFilesArray.length > 0) {
        <div class="w-[25rem] flex-col overflow-y-auto max-h-[20rem]">
          @for (file of controlFilesArray; track $index) {
            <div
              class="flex justify-between items-center bg-black-100 border border-black-300 border-dashed p-4 rounded-md mb-1"
            >
              <div class="flex gap-2">
                <div class="py-4 px-8 bg-white rounded flex justify-center items-center">
                  <img
                    class="max-w-none"
                    [alt]="'FILE' | translate"
                    width="38"
                    height="39"
                    priority
                    ngSrc="assets/images/icons/pdf.svg"
                  />
                </div>
                <div class="text-xs font-light max-w-[75%]">
                  <p>
                    <span class="font-medium">{{ ("FILE_NAME" | translate) + ": " }}</span>
                    <span>{{ getFilename(file) }}</span>
                  </p>
                  <p>
                    <span class="font-medium">{{ ("FILE_SIZE" | translate) + ": " }}</span>
                    <span>{{ getFileSize(file) }}</span>
                  </p>
                  @if (!isSupportedExtension(file)) {
                    <p class="text-error-200 py-2">
                      <span [innerHTML]="'VALIDATION.INVALIDE_FILE_EXTENSION' | translate"> </span>
                    </p>
                  }
                  @if (isFileCorrupted.get(file)) {
                    <p class="text-error-200 py-2">
                      <span [innerHTML]="'VALIDATION.CORRUPTED_FILE' | translate"> </span>
                    </p>
                  }
                </div>
              </div>
              <button (click)="deleteFile($index)" type="button" class="bg-white p-1.5 rounded border border-black-400">
                <img
                  [alt]="'UPLOAD_FILE.DELETE_FILE' | translate"
                  ngSrc="assets/images/icons/trash-icon.svg"
                  width="12"
                  class="max-w-none"
                  height="12"
                  priority
                />
              </button>
            </div>
          }
        </div>
      }
    </div>
  </div>

  <div class="py-3 bg-black-50 flex border border-black-100 px-6 sticky bottom-0 z-10 justify-between">
    <est-button
      (click)="closeDialog()"
      [appliedClasses]="'!py-3 !px-6'"
      [label]="'CANCEL'"
      buttonStyleType="button--tertiary"
      buttonType="button"
    />
    <est-button
      (click)="saveAndCloseDialog()"
      [appliedClasses]="'!py-3 !px-6'"
      [label]="'CONFIRM'"
      buttonStyleType="button--primary"
      buttonType="button"
    />
  </div>
</div>
