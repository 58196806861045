<div class="bg-white rounded-[0.625rem] r-screen-[25rem] relative p-6">
  <est-subheader
    date="Mon Feb 19 2024 14:20:06 GMT+0200"
    label="TERMS_AND_CONDITIONS.NAME"
    name="Jesper Thorkildsen"
    tooltip="TERMS_AND_CONDITIONS.NAME"
  >
  </est-subheader>
  <form
    [formGroup]="termsAndConditionsFormControls.termsAndConditionsFormGroup"
    (change)="updateUnitTermsAndConditions()"
    class="mt-12"
  >
    <div class="flex flex-col">
      <div class="flex gap-2 items-center mb-2">
        <p class="font-medium">{{ "TERMS_AND_CONDITIONS.USAGE_TYPE" | translate }}</p>
        <est-tooltip tooltip="TERMS_AND_CONDITIONS.USAGE_TYPE"></est-tooltip>
      </div>
      <div class="mb-5 text-sm">
        {{ "TERMS_AND_CONDITIONS.USAGE_TYPE_DESCRIPTION" | translate }}
      </div>
      <est-usage-type-select-field
        [control]="termsAndConditionsFormControls.usageTypeControl"
      ></est-usage-type-select-field>
      <p class="mt-6 text-sm font-medium">{{ "VISIBLE_ON_END_USER_SERVICE" | translate }}</p>

      <mat-radio-group
        [formControl]="termsAndConditionsFormControls.usageTypeVisibleControl"
        class="termsAndConditions flex flex-col"
      >
        <mat-radio-button [value]="true">{{ "YES" | translate }}</mat-radio-button>
        <mat-radio-button [value]="false">{{ "NO" | translate }}</mat-radio-button>
      </mat-radio-group>

      <hr class="my-6 border-black-100" />

      @if (unitListingType === UnitListingType.Leasing) {
        <div class="flex gap-2 items-center mb-4">
          <p class="font-medium">{{ "TERMS_AND_CONDITIONS.RENTAL_AGREEMENT_TERMS.NAME" | translate }}</p>
          <est-tooltip tooltip="TERMS_AND_CONDITIONS.RENTAL_AGREEMENT_TERMS.NAME"></est-tooltip>
        </div>
        <div class="text-sm mb-4 font-light">
          {{ "TERMS_AND_CONDITIONS.RENTAL_AGREEMENT_TERMS.DESCRIPTION" | translate }}
        </div>

        <div class="flex flex-col gap-4">
          <div class="flex flex-row flex-wrap gap-4">
            <est-select-field
              [control]="termsAndConditionsFormControls.depositMonthsControl"
              [dropDownLabel]="'DEPOSIT'"
              [dropDownOptions]="UNIT_DEPOSIT_MONTHS_OPTIONS"
              [selectMultipleOptions]="false"
            ></est-select-field>
            <est-currency-field
              label="Deposit-Amount"
              [postfixText]="'DKK'"
              appliedClass="!-mr-2"
              [value]="rentPrice * termsAndConditionsFormControls!.depositMonthsControl.value"
            >
            </est-currency-field>
            @for (value of currencies; track $index) {
              <est-currency-field
                appliedClass="!-mr-2"
                label="Deposit-Amount"
                [postfixText]="value.iso"
                [value]="
                  calculateBaseRentFromExchangeRate(
                    value.exchangeRate,
                    rentPrice * termsAndConditionsFormControls!.depositMonthsControl!.value,
                    value.iso
                  )
                "
              >
              </est-currency-field>
            }
          </div>
          <div class="flex flex-row flex-wrap gap-4">
            <est-select-field
              [control]="termsAndConditionsFormControls!.prepaidMonthsControl"
              [dropDownLabel]="'TERMS_AND_CONDITIONS.RENTAL_AGREEMENT_TERMS.PREPAID_RENT'"
              [dropDownOptions]="UNIT_PREPAID_MONTHS_OPTIONS"
              [selectMultipleOptions]="false"
            ></est-select-field>

            <est-currency-field
              label="Prepaid-Amount"
              [postfixText]="'DKK'"
              appliedClass="!-mr-2"
              [value]="rentPrice * termsAndConditionsFormControls!.prepaidMonthsControl.value"
            >
            </est-currency-field>
            @for (value of currencies; track $index) {
              <est-currency-field
                label="Prepaid-Amount"
                appliedClass="!-mr-2"
                [postfixText]="value.iso"
                [value]="
                  calculateBaseRentFromExchangeRate(
                    value.exchangeRate,
                    rentPrice * termsAndConditionsFormControls!.prepaidMonthsControl.value!,
                    value.iso
                  )
                "
              >
              </est-currency-field>
            }
          </div>
          <div class="flex flex-row flex-wrap gap-4">
            <est-select-field
              [control]="termsAndConditionsFormControls.nonCancellationPeriodControl"
              [dropDownLabel]="'TERMS_AND_CONDITIONS.RENTAL_AGREEMENT_TERMS.NON_CANCELLATION_PERIOD'"
              [dropDownOptions]="UNIT_NON_CANCELLATION_PERIOD_OPTIONS"
              [predefinedOptions]="[noneOption]"
              [selectMultipleOptions]="false"
            ></est-select-field>

            <est-select-field
              [control]="termsAndConditionsFormControls.noticePeriodControl"
              [dropDownLabel]="'TERMS_AND_CONDITIONS.RENTAL_AGREEMENT_TERMS.NOTICE_PERIOD'"
              [dropDownOptions]="UNIT_NOTICE_PERIOD_OPTIONS"
              [selectMultipleOptions]="false"
            ></est-select-field>
          </div>
          <div class="flex flex-row flex-wrap gap-4">
            <est-select-field
              [control]="termsAndConditionsFormControls.interiorMaintenanceControl"
              [dropDownLabel]="'TERMS_AND_CONDITIONS.RENTAL_AGREEMENT_TERMS.INTERIOR_MAINTENANCE'"
              [dropDownOptions]="UNIT_MAINTENANCE_OPTIONS"
              [selectMultipleOptions]="false"
            ></est-select-field>

            <est-select-field
              [control]="termsAndConditionsFormControls.exteriorMaintenanceControl"
              [dropDownLabel]="'TERMS_AND_CONDITIONS.RENTAL_AGREEMENT_TERMS.EXTERIOR_MAINTENANCE'"
              [dropDownOptions]="UNIT_MAINTENANCE_OPTIONS"
              [selectMultipleOptions]="false"
            ></est-select-field>
          </div>
        </div>

        <hr class="my-6 border-black-100" />
      }

      <div class="flex gap-2 items-center mb-2">
        <p class="font-medium">{{ "TERMS_AND_CONDITIONS.PET_POLICY.NAME" | translate }}</p>
        <est-tooltip tooltip="TERMS_AND_CONDITIONS.PET_POLICY.NAME"></est-tooltip>
      </div>

      <div class="mb-2 text-sm font-light">
        {{ "TERMS_AND_CONDITIONS.PET_POLICY.DESCRIPTION" | translate }}
      </div>

      <mat-slide-toggle
        (change)="updatePetPolicyControlsState($event.checked)"
        [formControl]="termsAndConditionsFormControls.followsPropertyPolicy"
        class="mb-4 mt-4"
        >{{ "TERMS_AND_CONDITIONS.PET_POLICY.FOLLOW_PROPERTY_POLICY" | translate }}
      </mat-slide-toggle>

      @if (termsAndConditionsFormControls.followsPropertyPolicy.value) {
        <p class="font-light text-sm text-error-100 mb-4">
          {{ "UNIT.CHANGES_ARE_NOT_ALLOWED" | translate }}
        </p>
      } @else {
        <p class="font-light text-sm text-success-dark mb-4">
          {{ "UNIT.CHANGES_ARE_ALLOWED" | translate }}
        </p>
      }

      <mat-slide-toggle [formControl]="termsAndConditionsFormControls.petPolicyAllowedControl" class="mb-4"
        >{{ "TERMS_AND_CONDITIONS.PET_POLICY.PETS_ALLOWED_ON_UNIT" | translate }}
      </mat-slide-toggle>

      @if (termsAndConditionsFormControls.petPolicyAllowedControl.value) {
        <est-select-field
          [control]="termsAndConditionsFormControls.numberOfPetsControl"
          [selectMultipleOptions]="false"
          [dropDownOptions]="numberOfPetsOptions"
          [dropDownLabel]="'TERMS_AND_CONDITIONS.PET_POLICY.NUMBER_OF_PETS'"
          appliedClass="mb-4"
        ></est-select-field>
        <div class="flex flex-row gap-4">
          <est-select-field
            [control]="termsAndConditionsFormControls.petTypesControl"
            [selectMultipleOptions]="true"
            [dropDownOptions]="unitPetOptions"
            [initialValue]="selectedPetTypes"
            [dropDownLabel]="'TERMS_AND_CONDITIONS.PET_POLICY.ALLOWED_PET_TYPES'"
            appliedClass="mb-4"
          ></est-select-field>
          <est-button
            (click)="openPetPolicyDialog()"
            [disabled]="termsAndConditionsFormControls.petTypesControl.value!.length === 0"
            appliedClasses="!py-1 !px-5 !mt-2"
            buttonStyleType="button--secondary"
            buttonType="button"
            imageAlt="ADD"
            label="DETAILS.NAME"
          ></est-button>
        </div>

        <p class="font-medium text-sm">{{ "VISIBLE_ON_END_USER_SERVICE" | translate }}</p>

        <mat-radio-group [formControl]="termsAndConditionsFormControls.petPolicyVisibleControl" class="flex flex-col">
          <mat-radio-button [value]="true">{{ "YES" | translate }}</mat-radio-button>
          <mat-radio-button [value]="false">{{ "NO" | translate }}</mat-radio-button>
        </mat-radio-group>
      }
    </div>
  </form>
  @if (isLoading) {
    <est-loading-overlay appliedClasses="!bg-white/60 "></est-loading-overlay>
  }
</div>
