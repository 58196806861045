import { NgClass, NgOptimizedImage } from "@angular/common";
import {
  booleanAttribute,
  Component,
  effect,
  inject,
  Input,
  input,
  OnInit,
  signal,
  WritableSignal,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { MatHeaderCell, MatTableModule } from "@angular/material/table";
import { TranslateModule } from "@ngx-translate/core";
import { PartialTranslatePipe } from "@root/core/pipes/partial-translate.pipe";
import { UnitFieldType } from "@root/data/market/import/enums/unit-field-type.enum";
import { IParseCsvResponse } from "@root/data/market/import/models/parse-csv-response.model";
import { ImportType } from "@root/data/market/import/units/enums/import-type.enum";
import { UnitType } from "@root/data/market/import/units/enums/unit-type.enum";
import { getMappedUnitValue } from "@root/data/market/import/utilities/import.utilities";
import { BaseInfniniteScrollingTableComponent } from "@root/shared/abstracts/base-infninite-scrolling-table/base-infninite-scrolling-table.component";
import { SUPPORTED_DATE_FORMAT_FOR_DISPLAY } from "@root/shared/constants/supported-date-formats.constants";
import { DatepickerComponent } from "@root/shared/datepicker/datepicker.component";
import { InputComponent } from "@root/shared/input/input.component";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { SelectFieldTwoWayBindComponent } from "@root/shared/select-field-two-way-bind/select-field-two-way-bind.component";
import { convertIsoDateToDateString, convertStringDateToIso } from "@root/shared/utilities/date.utilities";
import { UNITS_FIELDS_REQUIRED_HASHMAP } from "@root/views/main/toolbox/import-data/constants/units-fields-required.constants";
import { UNITS_FIELDS_TYPES_HASHMAP } from "@root/views/main/toolbox/import-data/constants/units-fields-types.constants";
import { IMatchColumnsInputs } from "@root/views/main/toolbox/import-data/import-table/match-columns/match-columns-inputs.interface";
import { MatchColumnsComponent } from "@root/views/main/toolbox/import-data/import-table/match-columns/match-columns.component";
import { UNIT_COLUMNS_DROPDOWN_OPTIONS_MAPPER } from "@root/views/main/toolbox/import-data/mappers/unit-columns-dropdown-options.mapper";

@Component({
  selector: "est-import-table",
  standalone: true,
  imports: [
    MatHeaderCell,
    MatTableModule,
    NgClass,
    TranslateModule,
    MatCheckbox,
    PartialTranslatePipe,
    NgOptimizedImage,
    FormsModule,
    MatSlideToggle,
    SelectFieldTwoWayBindComponent,
    DatepickerComponent,
    InputComponent,
  ],
  templateUrl: "./import-table.component.html",
  styleUrl: "./import-table.component.scss",
})
export class ImportTableComponent extends BaseInfniniteScrollingTableComponent implements OnInit {
  @Input() override sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "",
    sortDescending: false,
  });
  selectMode = input(false, {
    transform: booleanAttribute,
  });
  showStatus = input(false, {
    transform: booleanAttribute,
  });
  parsedCsvResponse = input<IParseCsvResponse>(); // Used for updating recognized and unrecognized columns numbers
  override data = input.required<{ [key: string]: string }[]>();
  resetEditRowsHashMap = input<boolean>();
  importType = input<ImportType>(ImportType.Unit);
  unitType = input<UnitType>(UnitType.Residential);
  csvColumnToDtoFieldHashMap = input.required<Map<string, string>>();
  checkboxHashMap = input<Map<number, boolean>>(new Map<number, boolean>());
  isAllCheckboxSelected = false;
  isOneCheckboxSelected = false;
  firstColumnKey = "firstColumnKey"; // Can either be status or select column
  editColumnKey = "editColumnKey";
  editRowsHashMap = new Map<number, boolean>(); // Stores the rows which are being edited
  protected readonly UNITS_FIELDS_TYPES_HASHMAP = UNITS_FIELDS_TYPES_HASHMAP;
  protected readonly UnitFieldType = UnitFieldType;
  protected readonly convertStringDateToIso = convertStringDateToIso;
  protected readonly convertIsoDateToDateString = convertIsoDateToDateString;
  protected readonly SUPPORTED_DATE_FORMAT_FOR_DISPLAY = SUPPORTED_DATE_FORMAT_FOR_DISPLAY;
  protected readonly UNIT_COLUMNS_DROPDOWN_OPTIONS_MAPPER = UNIT_COLUMNS_DROPDOWN_OPTIONS_MAPPER;
  protected readonly UNITS_FIELDS_REQUIRED_HASHMAP = UNITS_FIELDS_REQUIRED_HASHMAP;
  readonly #matDialog = inject(MatDialog);

  #resetEditRowsHashMapEffect = effect(() => {
    if (this.resetEditRowsHashMap()) this.clearEditRowsHashmap();
  });

  override ngOnInit() {
    super.ngOnInit();
    this.#appendTableUtilityColumns();
    this.updateCheckboxUi();
  }

  selectAll(): void {
    for (let i = 0; i < this.data().length; i++) {
      this.checkboxHashMap().set(i, true);
    }
  }

  deselectAll(): void {
    this.checkboxHashMap().clear();
  }

  updateCheckboxHashMap(index: number, value: boolean): void {
    this.checkboxHashMap().set(index, value);
  }

  updateCheckboxUi(): void {
    // A more efficient approach rather than getters
    if (!this.selectMode()) return;
    let isAllSelected = true;
    let isOneSelected = false;
    for (let i = 0; i < this.data().length; i++) {
      if (!this.checkboxHashMap().get(i)) {
        isAllSelected = false;
      } else {
        isOneSelected = true;
      }
    }
    this.isAllCheckboxSelected = isAllSelected;
    this.isOneCheckboxSelected = isOneSelected;
  }

  clearEditRowsHashmap() {
    this.editRowsHashMap.clear();
  }

  openMatchColumnDialog(csvColumnName: string): void {
    this.#matDialog.open<MatchColumnsComponent, IMatchColumnsInputs>(MatchColumnsComponent, {
      width: "40rem",
      data: {
        importType: this.importType(),
        unitType: this.unitType(),
        csvColumnName,
        csvColumnToDtoFieldHashMap: this.csvColumnToDtoFieldHashMap(),
        callbackFn: (csvColumnName: string, mappedDtoFieldName: string) =>
          this.#updateColumnMapping(csvColumnName, mappedDtoFieldName),
      },
    });
  }

  #updateColumnMapping(csvColumnName: string, mappedDtoFieldName: string): void {
    if (!this.csvColumnToDtoFieldHashMap().has(csvColumnName)) {
      this.parsedCsvResponse() && this.parsedCsvResponse()!.recognizedColumnsNo++;
      this.parsedCsvResponse() && this.parsedCsvResponse()!.unrecognizedColumnsNo--;
    }
    this.csvColumnToDtoFieldHashMap().set(csvColumnName, mappedDtoFieldName);
    this.#transformColumnData(csvColumnName);
  }

  #transformColumnData(columnName: string): void {
    this.data().forEach((row) => {
      row[columnName] = getMappedUnitValue(
        row[columnName],
        this.csvColumnToDtoFieldHashMap().get(columnName) || columnName,
      );
    });
  }

  #appendTableUtilityColumns(): void {
    this.displayedColumns.splice(0, 0, this.firstColumnKey);
    this.selectMode() && this.displayedColumns.push(this.editColumnKey);
  }
}
