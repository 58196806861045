import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NoteCategoryService } from "@root/data/market/noteCategories/services/note-category.service";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { FormGroup, NonNullableFormBuilder, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { SystemConfigurationsService } from "@root/data/market/system-configurations/services/system-configurations.service";
import { forkJoin, Observable, Subscription, tap } from "rxjs";
import { ISystemLanguageConfiguration } from "@root/data/market/system-configurations/language/models/system-language-configuration.model";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { UsersService } from "@root/data/market/users/services/users.service";
import { ColorPickerInputComponent } from "@root/shared/color-picker-input/color-picker-input.component";
import { ExternalTitleInputComponent } from "@root/shared/external-title-input/external-title-input.component";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";

@Component({
  selector: "est-note-category-popup",
  standalone: true,
  imports: [
    DialogHeaderComponent,
    SmallHeaderComponent,
    ButtonComponent,
    MatSlideToggle,
    TranslateModule,
    LoadingOverlayComponent,
    ColorPickerInputComponent,
    ExternalTitleInputComponent,
    MatCheckbox,
    SelectFieldComponent,
  ],
  templateUrl: "./note-category-popup.component.html",
  styleUrl: "./note-category-popup.component.scss",
})
export class NoteCategoryPopupComponent implements OnInit, OnDestroy {
  readonly componentInputs = inject(MAT_DIALOG_DATA);
  readonly #noteCategoryService = inject(NoteCategoryService);
  readonly #userService = inject(UsersService);
  readonly #systemConfigurationService = inject(SystemConfigurationsService);
  readonly #dialogRef: MatDialogRef<NoteCategoryPopupComponent> = inject(MatDialogRef);
  readonly #fb = inject(NonNullableFormBuilder);
  #noteCategorySubscription!: Subscription;
  systemLanguageConfiguration!: ISystemLanguageConfiguration;
  isFormSubmitting: boolean = false;
  isPageLoading: boolean = false;
  userRoleOptions: IDropdownOption[] = [];

  formGroup = this.#fb.group({
    gdprProtected: this.#fb.control(false),
    roleIds: this.#fb.control<number[]>([]),
    colour: this.#fb.control("#000000", Validators.required),
    noteCategoryNames: this.#fb.array<FormGroup>([]),
  });

  ngOnInit() {
    this.isPageLoading = true;
    if (this.componentInputs.isEditPopup) {
      return;
    } else {
      this.#initializeCreatePopup();
    }
  }

  ngOnDestroy() {
    this.#noteCategorySubscription.unsubscribe();
  }

  protected getLanguageFromId(languageId: string): ILanguage {
    return this.systemLanguageConfiguration.allowedLanguages
      .map((x) => x.language)
      .find((x) => x.languageId == languageId)!;
  }

  protected toggleRole(value: number, event: MatCheckboxChange) {
    const isAdded = this.formGroup.controls.roleIds.value.includes(value);
    if (isAdded && !event.checked) {
      this.formGroup.controls.roleIds.patchValue(this.formGroup.controls.roleIds.value.filter((x) => x !== value));
    } else {
      this.formGroup.controls.roleIds.patchValue([...this.formGroup.controls.roleIds.value, value]);
    }
  }

  protected isDefaultLanguage(languageId: string): boolean {
    return this.systemLanguageConfiguration.defaultEndUserLanguage.languageId === languageId;
  }

  #initializeCreatePopup() {
    this.#noteCategorySubscription = forkJoin([this.#getSystemLanguageConfiguration(), this.#getUserRoles()]).subscribe(
      {
        next: () => {
          const systemAdminRoleId = this.userRoleOptions.find((x) => x.label === "SETTINGS.USERS.SYSTEM_ADMIN")!.value;
          this.formGroup.controls.roleIds.patchValue([systemAdminRoleId]);
        },
        complete: () => (this.isPageLoading = false),
      },
    );
  }

  #getSystemLanguageConfiguration(): Observable<ISystemLanguageConfiguration> {
    return this.#systemConfigurationService.getLanguageSystemConfiguration().pipe(
      tap((data) => {
        this.systemLanguageConfiguration = data;
        data.allowedLanguages.forEach((allowedLanguage) => {
          this.formGroup.controls.noteCategoryNames.push(
            this.#fb.group({
              name: this.#fb.control("", Validators.required),
              languageId: this.#fb.control(allowedLanguage.language.languageId),
            }),
          );
        });
      }),
    );
  }

  #getUserRoles(): Observable<IDropdownOption[]> {
    return this.#userService.getAllUserRoles().pipe(tap((data) => (this.userRoleOptions = data)));
  }

  submitForm() {
    if (!this.formGroup.valid) {
      markAllControlsAsTouchedAndDirty(this.formGroup);
      return;
    }
    this.isFormSubmitting = true;
    if (this.componentInputs.isEditPopup) {
      return;
    } else {
      this.#createNoteCategory();
    }
  }

  #createNoteCategory() {
    this.#noteCategoryService.createNoteCategory(this.formGroup.value).subscribe(() => {
      this.isFormSubmitting = false;
      this.closeDialog();
    });
  }

  closeDialog(isCancel: boolean = false) {
    this.#dialogRef.close(isCancel);
  }
}
