import { Routes } from "@angular/router";
import { PropertiesOverviewComponent } from "@root/views/main/properties/properties-overview/properties-overview.component";
import { DivisionPropertiesComponent } from "@root/views/main/division/division-properties/division-properties.component";

export const propertiesRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "properties",
  },
  {
    path: "overview",
    component: PropertiesOverviewComponent,
    data: {
      name: "OVERVIEW",
    },
  },
  {
    path: "properties",
    component: DivisionPropertiesComponent,
    data: {
      name: "PROPERTIES.OVERVIEW",
    },
  },
];
