import { DatePipe, NgOptimizedImage, SlicePipe } from "@angular/common";
import { Component, inject, input } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { TranslateModule } from "@ngx-translate/core";
import { CustomDatePipe } from "@root/core/pipes/custom-date.pipe";
import { IPropertyMediaPackageOverview } from "@root/data/market/properties/models/property-media-package-overview.interface";
import { ICardAction } from "@root/shared/interfaces/card-Action.interface";
import { LinkedUnitsTablePopupComponent } from "@root/shared/linked-units-table-popup/linked-units-table-popup.component";
import { LinkingSource } from "../enums/linking-source.enum";
import { MatDialog } from "@angular/material/dialog";
import { ILinkedUnitsTablePopupInputs } from "@root/shared/linked-units-table-popup/linked-units-table-popup.inputs";

@Component({
  selector: "est-media-packages-card",
  standalone: true,
  imports: [
    MatIcon,
    MatMenu,
    MatMenuItem,
    TranslateModule,
    MatMenuTrigger,
    SlicePipe,
    LinkedUnitsTablePopupComponent,
    DatePipe,
    NgOptimizedImage,
    CustomDatePipe,
  ],
  templateUrl: "./media-packages-card.component.html",
  styleUrl: "./media-packages-card.component.scss",
})
export class MediaPackagesCardComponent {
  readonly #matDialog = inject(MatDialog);

  appliedClass = input<string>("");
  actions = input.required<ICardAction[]>();
  propertyId = input.required<number>();
  mediaPackage = input.required<IPropertyMediaPackageOverview>();
  linkingSource = input.required<LinkingSource>();

  openLinkedPopup() {
    this.#matDialog.open<LinkedUnitsTablePopupComponent, ILinkedUnitsTablePopupInputs>(LinkedUnitsTablePopupComponent, {
      width: "72rem",
      maxHeight: "95dvh",
      data: {
        packageId: this.mediaPackage().id,
        propertyId: this.propertyId(),
        linkingSource: this.linkingSource(),
        packageName: this.mediaPackage().internalTitle,
      },
    });
  }
}
