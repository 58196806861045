export enum UnitHiddenFieldsEnum {
  UnitId2 = "UnitId2",
  UnitId3 = "UnitId3",
  DateOfAvailability = "DateOfAvailability",
  RenovationEndDate = "RenovationEndDate",
  AddressFloor = "AddressFloor",
  AddressSide = "AddressSide",
  PlotNumber = "PlotNumber",
  AdministrationStartDate = "AdministrationStartDate",
  AdministrationEndDate = "AdministrationEndDate",
}
