import { CurrencyFormatConverterPipe } from "@root/shared/pipe/currency-format-converter.pipe";
import { REGEX_PATTERNS } from "@root/shared/constants/regex-patterns.constants";

const currencyConverter = new CurrencyFormatConverterPipe();
export const calculateBaseRentFromExchangeRate = (exchangeRate: number, rent: number, currencyIso: string) => {
  rent = replaceSeparators(rent.toString());
  const calculatedBaseRent = rent * exchangeRate;
  return currencyConverter.transform(calculatedBaseRent, currencyIso);
};

export const calculateConversionRate = (exchangeRate: number, currencyIso: string, defaultCurrency: string): string => {
  return `(${currencyConverter.transform(1000, defaultCurrency, 0)} ${defaultCurrency} = ${currencyConverter.transform(1000 * exchangeRate, currencyIso)} ${currencyIso})`;
};

export const convertCurrencyToIsoFormat = (payload: any) => {
  return Object.keys(payload).reduce(
    (acc, key) => {
      if (typeof payload[key] === "number" && payload[key] !== 0) {
        acc[key] = currencyConverter.transform(payload[key] as number, payload.defaultCurrency.iso);
      } else {
        acc[key] = payload[key];
      }
      return acc;
    },
    {} as typeof payload,
  );
};

export const replaceSeparators = (rent: string) => {
  let cleanedValue: string = rent;
  if (REGEX_PATTERNS.dotThousandSeparator.test(rent)) {
    cleanedValue = rent.replace(/\./g, "").replace(",", ".");
  } else if (REGEX_PATTERNS.commaThousandSeparator.test(rent)) {
    cleanedValue = rent.replace(/,/g, "");
  }
  return parseFloat(cleanedValue);
};
