<div class="bg-black-200 rounded-[0.625rem] p-6 relative">
  <div class="bg-white rounded-[0.625rem] r-screen-[25rem] relative">
    <div class="flex gap-2 items-center p-6">
      <p class="font-bold text-2xl">{{ "EVENTS.EDIT_EVENT_ON_PROPERTY" | translate }}</p>
      <est-tooltip tooltip="EVENTS.EDIT_EVENT_ON_PROPERTY"></est-tooltip>
    </div>
    <hr class="border-black-100" />
    <div class="p-6 font-light">
      <p class="font-medium text-xl mb-4">{{ "EVENTS.DETAILS_ABOUT_EVENT" | translate }}</p>
      <p class="text-sm mb-6 w-3/4">{{ "EVENTS.DETAILS_ABOUT_EVENT_DESCRIPTION" | translate }}</p>
      <div class="flex items-center gap-2 mb-2">
        <p class="font-medium">{{ "EVENTS.EVENT_TYPE" | translate }}</p>
        <est-tooltip tooltip="EVENTS.EVENT_TYPE"></est-tooltip>
      </div>
      <p class="text-sm mb-4">{{ "EVENTS.EVENT_TYPE_DESCRIPTION" | translate }}</p>
      <p class="text-sm">{{ "EVENTS.PUBLIC_EVENT_EXAMPLE" | translate }}</p>
      <p class="text-sm mb-6">{{ "EVENTS.PRIVATE_EVENT_EXAMPLE" | translate }}</p>
      <div class="flex gap-4">
        <est-select-field
          [control]="formControls.type"
          [dropDownOptions]="componentFormControls.eventTypeOptions"
          [selectMultipleOptions]="false"
          dropDownLabel="EVENTS.EVENT_TYPE"
        ></est-select-field>
        <est-select-field
          [control]="formControls.responsibleUserId"
          [dropDownOptions]="responsibleAgentsOptions"
          [selectMultipleOptions]="false"
          dropDownLabel="RESPONSIBLE_AGENT"
        ></est-select-field>
      </div>
    </div>
    <hr class="border-black-100" />
    <div class="p-6">
      <div class="flex items-center gap-2 mb-2">
        <p class="font-medium">{{ "EVENTS.INTERNAL_TITLE_OF_EVENT" | translate }}</p>
        <est-tooltip tooltip="EVENTS.INTERNAL_TITLE_OF_EVENT"></est-tooltip>
      </div>
      <p class="text-sm font-light mb-2">{{ "EVENTS.TITLE_OF_EVENT_DESCRIPTION" | translate }}</p>
      <est-input [control]="formControls.internalTitle" appliedClass="!w-[37.5rem]" label="INTERNAL_TITLE" />
      <div class="flex items-center gap-2 mb-2 mt-6">
        <p class="font-medium">{{ "EVENTS.EXTERNAL_TITLE_OF_EVENT" | translate }}</p>
        <est-tooltip tooltip="EVENTS.EXTERNAL_TITLE_OF_EVENT"></est-tooltip>
      </div>
      <p class="text-sm font-light mb-2">{{ "EVENTS.TITLE_OF_EVENT_DESCRIPTION" | translate }}</p>
      <est-input [control]="formControls.externalTitle" appliedClass="!w-[37.5rem]" label="EXTERNAL_TITLE" />
    </div>
    @if (selectedUnitsIds.length !== 0) {
      <hr class="border-black-100" />
      <est-selected-units
        [propertyId]="propertyId()"
        (selectedUnitsIds)="selectedUnits($event)"
        [fetchSelectedUnits]="assignUnits"
        [selectedUnitsId]="selectedUnitsIds"
      ></est-selected-units>
    }

    <hr class="border-black-100" />
    <div class="p-6">
      <div class="flex items-center gap-2 mb-2">
        <p class="font-medium">{{ "EVENTS.ADDRESS_OF_EVENT" | translate }}</p>
        <est-tooltip tooltip="EVENTS.ADDRESS_OF_EVENT"></est-tooltip>
      </div>
      <p class="text-sm font-light mb-6 w-1/2">{{ "EVENTS.ADDRESS_OF_EVENT_DESCRIPTION" | translate }}</p>
      <est-address
        [formGroup]="formControls.address"
        [formattedAddressSignal]="componentFormControls.formattedAddressSignal"
        [hasVat]="false"
        [lookups]="addressLookupsOptions"
      ></est-address>
      <p class="mt-6 font-light text-sm mb-4">{{ "EVENTS.SHOW_ADDRESS_CONFIRMATION" | translate }}</p>
      <p class="font-medium text-sm mb-2">{{ "ADDRESS.GEOGRAPHIC_LOCATION" | translate }}</p>
      <est-geographical-location
        [formattedAddressSignal]="componentFormControls.formattedAddressSignal"
        [latitudeControl]="formControls.address.controls.latitude!"
        [longitudeControl]="formControls.address.controls.longitude!"
      ></est-geographical-location>
    </div>
    <hr class="border-black-100" />
    <div class="p-6">
      <div class="flex items-center gap-2 mb-2">
        <p class="font-medium">{{ "DATE_AND_TIME" | translate }}</p>
        <est-tooltip tooltip="DATE_AND_TIME"></est-tooltip>
      </div>
      <p class="text-sm font-light mb-6">{{ "EVENTS.SELECT_DATE_AND_TIME" | translate }}</p>
      <p class="text-sm font-medium mb-2">{{ "DATE_AND_TIME" | translate }}</p>
      <div class="flex gap-4 mb-6">
        <est-datepicker [control]="formControls.date" appliedClasses="!w-[9.375rem]" label="DATE"></est-datepicker>
        <est-time-picker
          [control]="formControls.startTime"
          [isoDate]="formControls.date.value"
          label="STARTING_TIME"
        ></est-time-picker>
        <est-time-picker
          [control]="formControls.endTime"
          [isoDate]="formControls.date.value"
          label="END_TIME"
        ></est-time-picker>
      </div>
      <p class="text-sm font-medium mb-2">{{ "REPETITION" | translate }}</p>
      <p class="text-sm font-light mb-4">{{ "EVENTS.SCHEDULE_RECURRING_EVENT" | translate }}</p>
      <div class="flex gap-4">
        <est-button
          (click)="openRepetitionModal()"
          [label]="formControls.repetition.disabled ? 'SCHEDULE' : 'EVENTS.CHANGE_SCHEDULED_REPETITION'"
          buttonStyleType="button--primary"
          buttonType="button"
        ></est-button>
        @if (formControls.repetition.enabled) {
          <est-button
            (click)="formControls.repetition.disable({ emitEvent: false })"
            buttonStyleType="button--secondary"
            buttonType="button"
            label="EVENTS.DELETE_REPETITION"
          ></est-button>
        }
      </div>
      @if (formControls.repetition.enabled && formControls.date.valid) {
        <div class="mt-2 text-sm font-light">
          @switch (formControls.repetition.value.repetitionCycle) {
            @case (RepetitionCycle.Weekly) {
              <span [innerHTML]="('EVENTS.HAPPENS_EVERY_WEEK' | translate) + ' '"></span>
            }
            @case (RepetitionCycle.Monthly) {
              <span
                [innerHTML]="
                  ('EVENTS.HAPPENS_EVERY_MONTH'
                    | translate: { number: DateTime.fromISO(formControls.date.value!).day }) + ' '
                "
              ></span>
            }
            @default {
              <span [innerHTML]="('EVENTS.HAPPENS_EVERY_DAY' | translate) + ' '"></span>
            }
          }
          <span
            [innerHTML]="'EVENTS.STARTING_AND_ENDING' | translate: { startDate: eventStartDate, endDate: eventEndDate }"
          ></span>
        </div>
      }
    </div>
    <hr class="border-black-100" />
    <div class="p-6">
      <est-list-users-for-event
        (checkAllItems)="checkAllItems($event)"
        (checkItem)="checkItem($event)"
        [checkboxControl]="formControls.assignedUsersIds"
        [eventDate]="formControls.date.value"
        [propertyId]="propertyId()"
      ></est-list-users-for-event>
    </div>
    <hr class="border-black-100" />
    <div class="p-6">
      <div class="flex items-center gap-2 mb-2">
        <p class="font-medium">{{ "EVENTS.USE_BOOKING" | translate }}</p>
        <est-tooltip tooltip="EVENTS.USE_BOOKING"></est-tooltip>
      </div>
      <p class="text-sm font-light mb-4">{{ "EVENTS.USE_BOOKING_DESCRIPTION" | translate }}</p>
      <mat-slide-toggle
        (change)="componentFormControls.toggleBookingFormGroup($event.checked)"
        [formControl]="componentFormControls.bookingToggleControl"
        class="text-sm font-light"
        >{{ "EVENTS.ENABLE_BOOKING" | translate }}
      </mat-slide-toggle>
      @if (componentFormControls.bookingToggleControl.value) {
        <div class="flex gap-4 mt-4 mb-4">
          <est-select-field
            [control]="bookingFormControls.type"
            [selectMultipleOptions]="false"
            [dropDownOptions]="componentFormControls.bookingTypeOptions"
            dropDownLabel="BOOKING_TYPE"
          ></est-select-field>
          @if (bookingFormControls.type.value === PropertyEventBookingType.Limited) {
            <est-input
              [control]="bookingFormControls.maximumAttendants"
              label="MAXIMUM_ATTENDANTS"
              type="number"
              appliedClass="!w-[12.5rem]"
            ></est-input>
          } @else if (bookingFormControls.type.value === PropertyEventBookingType.TimeSlot) {
            <est-select-field
              [control]="bookingFormControls.timeSlotInterval"
              appliedClass="!w-[9.375rem]"
              dropDownLabel="TIMESLOT_INTERVAL"
              [selectMultipleOptions]="false"
              [dropDownOptions]="componentFormControls.timeSlotsIntervalOptions"
            ></est-select-field>
            <est-select-field
              [control]="bookingFormControls.intervalsBreak"
              appliedClass="!w-[9.375rem]"
              dropDownLabel="BREAK_BETWEEN_INTERVALS"
              [selectMultipleOptions]="false"
              [dropDownOptions]="componentFormControls.breakOptions"
            ></est-select-field>
          }
        </div>
        @if (bookingFormControls.type.value === PropertyEventBookingType.TimeSlot) {
          <est-timeslots
            [formArray]="bookingFormControls.timeSlots"
            [intervalInMinutes]="bookingFormControls.timeSlotInterval.value"
            [breakInMinutes]="bookingFormControls.intervalsBreak.value"
            [startDateIso]="formControls.startTime.value"
            [endDateIso]="formControls.endTime.value"
            [existingTimeslots]="existingTimeslots"
          ></est-timeslots>
        }
        <div class="mt-6">
          <div class="flex items-center gap-2 mb-2">
            <p class="font-medium">{{ "EVENTS.BOOKING_FORM" | translate }}</p>
            <est-tooltip tooltip="EVENTS.BOOKING_FORM"></est-tooltip>
          </div>
          <p class="text-sm font-light mb-4">{{ "EVENTS.BOOKING_FORM_DESCRIPTION" | translate }}</p>
          <est-select-field
            [dropDownOptions]="[]"
            [selectMultipleOptions]="false"
            dropDownLabel="EVENTS.BOOKING_FORM"
            appliedClass="!w-[38.75rem]"
          />
        </div>
      }
    </div>
    <hr class="border-black-100" />
    <div class="p-6">
      <div class="flex items-center gap-2 mb-2">
        <p class="font-medium">{{ "EVENTS.PUBLISH_EVENT" | translate }}</p>
        <est-tooltip tooltip="EVENTS.PUBLISH_EVENT"></est-tooltip>
      </div>
      <p class="text-sm font-light mb-4">{{ "EVENTS.PUBLISH_EVENT_DESCRIPTION" | translate }}</p>
      <div class="flex flex-col gap-4">
        <mat-slide-toggle [formControl]="formControls.publishOnWebsite" class="text-sm font-light"
          >{{ "EVENTS.PUBLISH_ON_WEBSITE" | translate }}
        </mat-slide-toggle>
        <mat-slide-toggle [formControl]="formControls.publishOnEido" class="text-sm font-light"
          >{{ "EVENTS.PUBLISH_ON_EIDO" | translate }}
        </mat-slide-toggle>
      </div>
    </div>
    <div class="dialog__footer !static">
      <est-button (click)="goToEventsList()" buttonStyleType="button--tertiary" buttonType="button" label="CANCEL" />
      <div class="flex gap-4">
        @if (isDraft) {
          <est-button
            (click)="saveAsDraft()"
            buttonStyleType="button--secondary"
            buttonType="button"
            label="SAVE_AS_DRAFT"
          />
          <est-button
            (click)="editAndPublishEvent()"
            [label]="
              formControls.publishOnWebsite.value || formControls.publishOnEido.value
                ? 'EVENTS.EDIT_AND_PUBLISH_EVENT'
                : 'EVENTS.EDIT_EVENT'
            "
            buttonStyleType="button--primary"
            buttonType="button"
          />
        } @else {
          <est-button
            buttonStyleType="button--primary"
            buttonType="button"
            label="SAVE"
            (click)="openEditEventConfirmationModal()"
          />
        }
      </div>
    </div>
  </div>
  @if (isPageLoading) {
    <est-loading-overlay appliedClasses="bg-white/75"></est-loading-overlay>
  }
</div>
