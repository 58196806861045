import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { APIS } from "@root/core/api/market.apis";
import { ITimezone } from "@root/data/market/timezones/models/timezone.model";
import { IPaginationPayload } from "@root/shared/interfaces/pagination-payload.interface";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";

@Injectable({
  providedIn: "root",
})
export class TimezonesRepository {
  readonly #http = inject(HttpClient);

  getSystemTimeZones(): Observable<ITimezone[]> {
    return this.#http.get<ITimezone[]>(APIS.timezones.getSystemTimeZones);
  }

  getPaginatedSystemTimezones(params: IPaginationPayload): Observable<IPaginatedResponse<ITimezone>> {
    return this.#http.get<IPaginatedResponse<ITimezone>>(APIS.timezones.getPaginatedSystemTimezones, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }
}
