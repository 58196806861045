import { Component, inject, input } from "@angular/core";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { BaseCompositeSelectSearchFieldComponent } from "@root/shared/abstracts/base-composite-select-search-field/base-composite-select-search-field.component";
import { TimezonesService } from "@root/data/market/timezones/services/timezones.service";
import { Observable } from "rxjs";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";

@Component({
  selector: "est-timezone-search-select-field",
  standalone: true,
  imports: [SelectSearchFieldComponent],
  templateUrl: "./timezone-search-select-field.component.html",
  styleUrl: "./timezone-search-select-field.component.scss",
})
export class TimezoneSearchSelectFieldComponent extends BaseCompositeSelectSearchFieldComponent {
  timezoneService = inject(TimezonesService);
  label = input<string>();

  override getHttpServiceCall(pageNumber: number): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    return this.timezoneService.getPaginatedSystemTimeZones({
      search: this.searchSignal(),
      pageNumber,
      pageSize: this.paginatedOptions.pageSize(),
      isInitial: this.includeInitialValue(),
    });
  }
}
