export const UNITS_COLUMN_NAMES = {
  unitId1: "CustomUnitId1",
  unitId2: "CustomUnitId2",
  unitId3: "CustomUnitId3",
  housingType: "HousingType",
  listingType: "ListingType",
  constructionYear: "ConstructionYear",
  plotNumber: "PlotNumber",
  administrationStartDate: "AdministrationStartDate",
  administrationEndDate: "AdministrationEndDate",
  vacancyDate: "VacancyDate",
  longitude: "Longitude",
  latitude: "Latitude",
  isUnderRenovation: "IsUnderRenovation",
  renovationStartDate: "RenovationStartDate",
  renovationEndDate: "RenovationEndDate",
  seoTitle: "SeoTitle",
  metaDescription: "MetaDescription",
  externalLink1: "ExternalLink1",
  externalLink2: "ExternalLink2",
  externalLink3: "ExternalLink3",
  energyLabel: "EnergyLabel",
  floor: "Floor",
  houseNumber: "HouseNumber",
  street: "Street",
  door: "Door",
  side: "Side",
  zipCode: "ZipCode",
  city: "City",
  country: "Country",
  paymentFrequency: "PaymentFrequency",
  salePrice: "SalePrice",
  rentPrice: "RentPrice",
  acontoHeat: "AcontoHeat",
  acontoWater: "AcontoWater",
  acontoElectricity: "AcontoElectricity",
  acontoOperatingCosts: "AcontoOperatingCosts",
  acontoResidentsSociety: "AcontoResidentsSociety",
  internetFee: "InternetFee",
  antennaFee: "AntennaFee",
  usageType: "UsageType",
  usageTypeVisible: "UsageTypeVisible",
  depositMonths: "DepositMonths",
  prepaidMonths: "PrepaidMonths",
  nonCancellationPeriodMonths: "NonCancellationPeriodMonths",
  rentalCondition: "RentalCondition",
  rentalPeriodMonths: "RentalPeriodMonths",
  noticePeriodDays: "NoticePeriodDays",
  interiorMaintenance: "InteriorMaintenance",
  exteriorMaintenance: "ExteriorMaintenance",
  internalFloors: "InternalFloors",
  roomQuantity: "RoomQuantity",
  area: "Area",
  registeredArea: "RegisteredArea",
  plotArea: "PlotArea",
  unitOrientation: "UnitOrientation",
  commonArea: "CommonArea",
  internalArea: "InternalArea",
  bedroomQuantity: "BedroomQuantity",
  balconyQuantity: "BalconyQuantity",
  terraceQuantity: "TerraceQuantity",
  gardenQuantity: "GardenQuantity",
  restroomQuantity: "RestroomQuantity",
  livingRoomQuantity: "LivingRoomQuantity",
  internalStorageRoomQuantity: "InternalStorageRoomQuantity",
  additionalRoomQuantity: "AdditionalRoomQuantity",
  studentFriendly: "StudentFriendly",
  seniorFriendly: "SeniorFriendly",
  shareFriendly: "ShareFriendly",
  stepFreeEntrance: "StepFreeEntrance",
  stepFreeEntrancePath: "StepFreeEntrancePath",
  wideEntrance: "WideEntrance",
  parkingAccessible: "ParkingAccessible",
  stepFreeBedroomEntrance: "StepFreeBedroomEntrance",
  wideBedroomEntrance: "WideBedroomEntrance",
  stepFreeBathroomEntrance: "StepFreeBathroomEntrance",
  wideBathroomEntrance: "WideBathroomEntrance",
  bathroomGrabBar: "BathroomGrabBar",
  restroomGrabBar: "RestroomGrabBar",
  stepFreeShower: "StepFreeShower",
  showerChair: "ShowerChair",
} as const;
