import { Routes } from "@angular/router";
import { ConfirmationPageComponent } from "./confirmation-page/confirmation-page.component";
import { WelcomePageComponent } from "./welcome-page/welcome-page.component";

export const textPageRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "welcome-page",
  },
  {
    path: "welcome-page",
    component: WelcomePageComponent,
    data: {
      name: "TEXT_PAGE.WELCOME_PAGE.NAME",
    },
  },
  {
    path: "confirmation-page",
    component: ConfirmationPageComponent,
    data: {
      name: "TEXT_PAGE.CONFIRMATION_PAGE.NAME",
    },
  },
];
