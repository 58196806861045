import { NgClass, NgOptimizedImage } from "@angular/common";
import { Component, computed, effect, inject } from "@angular/core";
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from "@angular/material/expansion";
import { MatIcon } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { IPropertyEventInstance } from "@root/data/market/properties/models/property-event-instance.model";
import { ButtonStyleType } from "@root/shared/button/button-style.type";
import { ButtonComponent } from "@root/shared/button/button.component";
import { EventInstanceFilter } from "@root/shared/enums/property-event-instances-filtering.enum";
import { EventInstanceSortBy } from "@root/shared/enums/property-event-instances-sorting.enum";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SpinnerComponent } from "@root/shared/spinner/spinner.component";
import { formatDateToTodayDateString } from "@root/shared/utilities/date.utilities";
import { EventInstanceDetailsComponent } from "../event-instance-details/event-instance-details.component";
import { EventDetailsSignalStateService } from "../services/event-details-signal-state.service";
import { SingleEventInstanceWrapperComponent } from "../single-event-instance-wrapper/single-event-instance-wrapper.component";

@Component({
  selector: "est-series-events-instances-wrapper",
  standalone: true,
  imports: [
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelDescription,
    MatExpansionPanelHeader,
    MatIcon,
    SingleEventInstanceWrapperComponent,
    EventInstanceDetailsComponent,
    NgClass,
    SmallHeaderComponent,
    ButtonComponent,
    TranslateModule,
    SpinnerComponent,
    PaginationComponent,
    NgOptimizedImage,
    LoadingOverlayComponent,
  ],
  templateUrl: "./series-events-instances-wrapper.component.html",
  styleUrl: "./series-events-instances-wrapper.component.scss",
})
export class SeriesEventsInstancesWrapperComponent {
  readonly #eventDetailsSignalStateService = inject(EventDetailsSignalStateService);

  paginatedEventInstances = this.#eventDetailsSignalStateService.paginatedEventInstances;
  eventInstances = this.paginatedEventInstances.results;
  filterBy = this.#eventDetailsSignalStateService.filterBy;
  sortBy = this.#eventDetailsSignalStateService.sortBy;
  sortDescending = this.#eventDetailsSignalStateService.sortDescending;
  isInstanceLoading = this.#eventDetailsSignalStateService.isInstanceLoading;
  filterButtons = [
    {
      label: "EVENTS.DETAILS.SERIES_EVENT.ALL",
      getButtonStyleType: () => this.getButtonStyleType(EventInstanceFilter.All),
      callBackFn: () => this.setFilterBy(EventInstanceFilter.All),
    },
    {
      label: "EVENTS.DETAILS.SERIES_EVENT.UPCOMING",
      getButtonStyleType: () => this.getButtonStyleType(EventInstanceFilter.Upcoming),
      callBackFn: () => this.setFilterBy(EventInstanceFilter.Upcoming),
    },
    {
      label: "EVENTS.DETAILS.SERIES_EVENT.COMPLETED",
      getButtonStyleType: () => this.getButtonStyleType(EventInstanceFilter.Completed),
      callBackFn: () => this.setFilterBy(EventInstanceFilter.Completed),
    },
  ];

  sortingKeys = [
    {
      label: "EVENTS.DETAILS.SERIES_EVENT.SORTING.DATE_OF_EVENT",
      callbackFn: () => this.setSorting(EventInstanceSortBy.EventDate),
    },
    {
      label: "EVENTS.DETAILS.SERIES_EVENT.SORTING.BOOKING",
      callbackFn: () => this.setSorting(EventInstanceSortBy.Booking),
    },
    {
      label: "EVENTS.DETAILS.SERIES_EVENT.SORTING.ATTENDED",
      callbackFn: () => this.setSorting(EventInstanceSortBy.AttendedCustomers),
    },
    {
      label: "EVENTS.DETAILS.SERIES_EVENT.SORTING.CONVERSION",
      callbackFn: () => this.setSorting(EventInstanceSortBy.Conversion),
    },
    {
      label: "EVENTS.DETAILS.SERIES_EVENT.SORTING.RESPONSIBLE_AGENT",
      callbackFn: () => this.setSorting(EventInstanceSortBy.ResponsibleAgent),
    },
  ];

  #todayDate = formatDateToTodayDateString(new Date());
  currentOrUpcomingInstance!: IPropertyEventInstance | undefined;
  eventInstancesAreInThePast = false;

  #isFinalPage = computed(
    () => this.paginatedEventInstances.currentPage() === this.paginatedEventInstances.totalPages(),
  );

  openUpcomingInstanceAccordion$ = effect(
    () => {
      // Handling events in the past to open on the first page of instances
      if (this.#isFinalPage() && !this.currentOrUpcomingInstance && !this.eventInstancesAreInThePast) {
        this.eventInstancesAreInThePast = true;
        this.paginatedEventInstances.currentPage.set(1);
      }
      if (this.#shouldContinueSearchingForUpcomingEvents()) {
        this.paginatedEventInstances.currentPage.set(this.paginatedEventInstances.currentPage() + 1);
      }
    },
    { allowSignalWrites: true },
  );

  /**
   * This function to check whether I should call the endpoint for event instances with the next page till I find the current or upcoming instance date
   * @returns boolean
   */
  #shouldContinueSearchingForUpcomingEvents(): boolean {
    return (
      !this.isInstanceLoading() &&
      this.filterBy() === EventInstanceFilter.All &&
      !this.#isUpcomingInstanceExistsInCurrentPaginatedData() &&
      this.paginatedEventInstances.currentPage() < this.paginatedEventInstances.totalPages() &&
      !this.eventInstancesAreInThePast
    );
  }
  #isUpcomingInstanceExistsInCurrentPaginatedData(): boolean {
    this.currentOrUpcomingInstance = this.eventInstances().find(
      (instance) => formatDateToTodayDateString(new Date(instance.eventDate)) >= this.#todayDate,
    );
    return this.currentOrUpcomingInstance ? true : false;
  }

  isInstanceExpanded(instanceId: number): boolean {
    return instanceId === this.currentOrUpcomingInstance?.id;
  }
  getButtonStyleType(filterName: EventInstanceFilter): ButtonStyleType {
    return this.filterBy() === filterName ? "button--quaternary" : "button--tertiary";
  }
  setFilterBy(filterName: EventInstanceFilter): void {
    this.paginatedEventInstances.currentPage.set(1);
    this.paginatedEventInstances.totalPages.set(0);
    this.paginatedEventInstances.totalCount.set(0);
    this.filterBy.set(filterName);
  }
  setSorting(sortBy: EventInstanceSortBy): void {
    if (this.sortBy() === sortBy) {
      this.sortDescending.set(!this.sortDescending());
    } else {
      this.sortBy.set(sortBy);
    }
  }
}
