@if (withLabelHeader()) {
  <est-small-header [heading]="label()" [tooltip]="label()"></est-small-header>
}
<mat-form-field
  [attr.aria-labelledby]="ariaLabel() | translate"
  [attr.aria-required]="isRequired"
  [ngClass]="appliedClass()"
  class="w-[18.5rem]"
  appearance="outline"
  hideRequiredMarker
  id="input"
  subscriptSizing="dynamic"
>
  <mat-label>{{ label() | translate }} {{ addRequiredToLabel() | translate }}</mat-label>
  <div class="flex gab-2 items-center w-full">
    <input
      type="color"
      [formControl]="control()"
      class="!w-4 !h-3.5 bg-white cursor-pointer !rounded me-1.5"
      id="color-picker"
      (input)="updateColorFromText($event)"
    />
    <p class="text-black-300 me-1" [ngClass]="{ hidden: control().value.includes('#') }">#</p>
    <input class="focus:outline-none w-full" [formControl]="control()" (input)="updateColorFromText($event)" matInput />
  </div>
</mat-form-field>
@if (control().dirty && control().touched && control().hasError("invalidHexCode")) {
  <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
    <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
    {{ "VALIDATION.INVALID_HEX_CODE" | translate }}
  </mat-error>
}
