@if (isPageLoading()) {
  <est-loading-overlay></est-loading-overlay>
} @else {
  <est-small-header
    appliedClasses="!mb-2"
    [heading]="
      componentInputs().isEditPopup
        ? 'SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.EDIT.NAME'
        : 'SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.CREATE.NAME'
    "
    [subheading]="
      componentInputs().isEditPopup
        ? 'SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.EDIT.DESCRIPTION'
        : 'SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.CREATE.DESCRIPTION'
    "
  ></est-small-header>

  <!-- Internal Title -->
  <div class="flex gap-2 items-center mt-6 mb-2">
    <p class="text-sm">{{ "INTERNAL_TITLE" | translate }}</p>
    <est-tooltip tooltip="INTERNAL_TITLE"></est-tooltip>
  </div>
  <est-input label="INTERNAL_TITLE" [control]="formGroup().controls.internalTitle"></est-input>
  <div class="pt-6">
    <hr class="border-black-100 absolute start-0 end-0" />
  </div>

  <!-- Publish Question -->
  <est-small-header
    appliedClasses="mt-6"
    heading="SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.PUBLIC.NAME"
    subheading="SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.PUBLIC.DESCRIPTION"
  ></est-small-header>
  <mat-slide-toggle
    class="text-sm font-light mb-6 mt-4"
    [checked]="formGroup().controls.published.value"
    (change)="togglePublished()"
    >{{ "SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.PUBLIC.PUBLISHED" | translate }}
  </mat-slide-toggle>

  <!-- External Title -->
  @if (formGroup().controls.published.value) {
    <div class="flex flex-col gap-4 mb-6">
      @for (externalTitleControl of formGroup().controls.externalTitles.controls; track externalTitleControl) {
        <est-external-title-input
          [language]="getExternalTitleLanguage(externalTitleControl.controls['languageId'].value)"
          [isDefault]="isDefaultLanguage(externalTitleControl.controls['languageId'].value)"
          [formGroup]="externalTitleControl"
        ></est-external-title-input>
      }
    </div>
  }

  <!-- Default Question -->
  <est-small-header
    heading="SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.DEFAULT.NAME"
    subheading="SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.DEFAULT.DESCRIPTION"
  ></est-small-header>
  <mat-slide-toggle
    class="text-sm font-light mb-6 mt-4"
    [checked]="formGroup().controls.defaultQuestion.value"
    (change)="toggleDefault()"
  >
    {{ "SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.DEFAULT.TOGGLE_LABEL" | translate }}
  </mat-slide-toggle>

  <div class="pb-6">
    <hr class="border-black-100 absolute start-0 end-0" />
  </div>

  <!-- Answer Options -->
  <est-small-header
    heading="SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.ANSWER_OPTIONS.NAME"
    subheading="SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.ANSWER_OPTIONS.FIRST_STEP_DESCRIPTION"
  ></est-small-header>
  <mat-slide-toggle class="text-sm font-light my-4" [checked]="hasAnswerOptions()" (change)="toggleAnswerOptions()">
    {{ "SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.ANSWER_OPTIONS.TOGGLE_LABEL" | translate }}
  </mat-slide-toggle>

  <!-- Selection Type -->
  @if (showAnswerOptionType) {
    <est-small-header
      heading="SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.SELECTION_TYPE.NAME"
      subheading="SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.SELECTION_TYPE.DESCRIPTION"
    ></est-small-header>
    <mat-radio-group
      [required]="true"
      [formControl]="formGroup().controls.answerOptionType"
      class="mt-4 flex flex-col font-light text-sm"
    >
      <mat-radio-button [value]="AnswerOptionTypeEnum.Single">
        {{ AnswerOptionTypeMapper.get(AnswerOptionTypeEnum.Single)! | translate }}
      </mat-radio-button>
      <mat-radio-button [value]="AnswerOptionTypeEnum.Multi">
        {{ AnswerOptionTypeMapper.get(AnswerOptionTypeEnum.Multi)! | translate }}
      </mat-radio-button>
    </mat-radio-group>
  }

  <div class="pt-6 pb-[0.875rem]">
    <hr class="border-black-100 absolute start-0 end-0" />
  </div>

  <!-- Activate -->
  <est-small-header
    heading="SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.ACTIVATED.NAME"
    subheading="SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.ACTIVATED.DESCRIPTION"
  ></est-small-header>
  <mat-slide-toggle
    class="text-sm font-light my-4"
    [checked]="formGroup().controls.isInheritanceActive.value"
    (change)="toggleActivated()"
  >
    {{ "SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.ACTIVATED.IS_ACTIVE" | translate }}
  </mat-slide-toggle>
}
