import { Component, inject, OnInit } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { OrganizationService } from "@root/data/market/organisation/services/organisation.service";
import { InputComponent } from "@root/shared/input/input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { LoadingOverlayComponent } from "../../../../../../shared/loading-overlay/loading-overlay.component";
import { getControlInFormGroup, updateNestedControlsPathAndValue } from "@root/shared/utilities/form.utilities";
import { OrganisationCustomerPageOptionsFormControls } from "./options.form-controls";
import { maximumNumberOfFavouritesOptions } from "./favourite-options.utility";

@Component({
  selector: "est-options",
  standalone: true,
  imports: [
    SmallHeaderComponent,
    MatSlideToggle,
    TranslateModule,
    SelectFieldComponent,
    InputComponent,
    LoadingOverlayComponent,
    ReactiveFormsModule,
  ],
  templateUrl: "./options.component.html",
  styleUrl: "./options.component.scss",
  providers: [OrganisationCustomerPageOptionsFormControls],
})
export class OptionsComponent implements OnInit {
  readonly #organisationService = inject(OrganizationService);
  customerPageFormControls = inject(OrganisationCustomerPageOptionsFormControls);

  maximumNumberOfFavouritesOptions = maximumNumberOfFavouritesOptions;

  isPageLoading = false;

  ngOnInit(): void {
    this.#getCustomerPageOptions();
  }

  #getCustomerPageOptions() {
    this.isPageLoading = true;
    this.#organisationService.getCustomerPageOptions().subscribe({
      next: (orgCustomerPageOptions) => {
        this.customerPageFormControls.customerPageOptionsForm.patchValue(orgCustomerPageOptions);
        this.isPageLoading = false;
      },
      error: () => {
        this.isPageLoading = false;
      },
    });
  }

  getControl(controlName: string): FormControl<any> {
    return getControlInFormGroup(this.customerPageFormControls.customerPageOptionsForm, controlName);
  }

  formPatched() {
    this.#sendPatchRequest();
  }

  #sendPatchRequest(): void {
    if (this.customerPageFormControls.customerPageOptionsForm.invalid) return;
    const controlPaths = updateNestedControlsPathAndValue(this.customerPageFormControls.customerPageOptionsForm);
    !!Object.keys(controlPaths).length && this.#organisationService.patchCustomerPageOptions(controlPaths).subscribe();
  }
}
