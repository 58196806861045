import { Component } from "@angular/core";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { RouterOutlet } from "@angular/router";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";

@Component({
  selector: "est-properties",
  standalone: true,
  imports: [NavigationTabsComponent, RouterOutlet],
  templateUrl: "./properties.component.html",
  styleUrl: "./properties.component.scss",
})
export class PropertiesComponent {
  protected tabs: INavigationTab[] = [
    {
      label: "OVERVIEW",
      routerLink: "overview",
    },
    {
      label: "PROPERTIES.NAME",
      routerLink: "properties",
    },
  ];
}
