<mat-sidenav-container fullscreen>
  <mat-sidenav
    #sideNav
    [mode]="screenWidth > windowBreakPoint ? 'side' : 'over'"
    [opened]="screenWidth > windowBreakPoint"
    class="md:!w-60"
    mode="side"
  >
    <div>
      <div class="w-100 border-b border-black-300 p-6 h-28">
        <img
          alt="estate-tool logo"
          class="mx-auto"
          height="60"
          ngSrc="../../../assets/images/estatetool-logo.svg"
          priority
          width="123.333"
        />
      </div>
      <mat-nav-list>
        <p class="font-bold ml-6 mb-3 text-sm text-black">{{ "GENERAL" | translate }}</p>
        @for (item of sidebarListItem; track item.title) {
          <mat-list-item
            *estAuthorizedRole="item.authorizedRole"
            routerLinkActive="!bg-black-300"
            [routerLink]="item.routeLink"
            class="flex"
          >
            <img
              [ngSrc]="item.imageSrc"
              priority
              [alt]="item.imageAlt | translate"
              [width]="item.imageWidth"
              [height]="item.imageHeight"
              class="mb-0.5"
            />
            <span class="ms-4">{{ item.title | translate }}</span>
          </mat-list-item>
        }
        <button [estDropdownMenu]="customerMenu" class="flex" mat-list-item>
          <img
            [alt]="'CUSTOMER_FUNNEL ' | translate"
            height="16"
            ngSrc="./assets/images/icons/funnel.svg"
            priority
            width="16"
          />
          <span class="ms-4">{{ "CUSTOMER_FUNNEL" | translate }}</span>
          <mat-icon class="absolute right-3"> keyboard_arrow_down</mat-icon>
        </button>
        <ul #customerMenu class="ms-8">
          <li routerLink="" class="flex gab-3 pt-1.5">
            <img ngSrc="./assets/images/icons/customer.svg" priority alt="customer icon" width="17" height="15" />
            <span class="ms-4">{{ "CUSTOMER" | translate }}</span>
          </li>
          @for (item of customerFunnel; track item.title) {
            <li [routerLink]="item.routeLink" class="flex gab-3 mt-4">
              <div matBadge="1" matBadgeSize="large">
                <img
                  [ngSrc]="item.imageSrc"
                  priority
                  [alt]="item.imageAlt | translate"
                  [width]="item.imageWidth"
                  [height]="item.imageHeight"
                />
              </div>
              <span class="ms-4">{{ item.title | translate }}</span>
            </li>
          }
        </ul>
      </mat-nav-list>
      <mat-divider></mat-divider>
      <div class="flex py-4 relative">
        <p class="font-bold ml-6 text-sm">{{ "RECENT_PROPERTIES" | translate }}</p>
        <mat-icon class="absolute right-3"> keyboard_arrow_down</mat-icon>
      </div>
      <mat-divider></mat-divider>
      <div class="flex py-4 relative">
        <p class="font-bold ml-6 text-sm">{{ "FAVORITE_PROPERTIES" | translate }}</p>
        <mat-icon class="absolute right-3"> keyboard_arrow_down</mat-icon>
      </div>
    </div>

    <div>
      @if (authService.getUserDivisionRoles()) {
        @switch (authService.getUserDivisionRoles()!.length) {
          @case (0) {
            <div class="bg-black-100 flex items-center py-3 relative ps-6 gap-5">
              <img
                [alt]="'DIVISIONS.DIVISION' | translate"
                ngSrc="assets/images/icons/heirarchy.svg"
                width="16"
                height="14"
                priority
              />
              <p class="font-medium text-sm text-black-500">{{ "DIVISIONS.NO_DIVISIONS" | translate }}</p>
            </div>
          }
          @case (1) {
            <div class="bg-black-100 flex items-center py-3 relative ps-6 gap-5">
              <img
                [alt]="'DIVISIONS.DIVISION' | translate"
                ngSrc="assets/images/icons/heirarchy.svg"
                width="16"
                height="14"
                priority
              />
              <p class="font-medium text-sm">{{ authService.currentUserDivisionRole!.divisionName }}</p>
            </div>
          }
          @default {
            <button
              #divisonListButton
              [estDropdownMenu]="divisionList"
              class="bg-black-100 flex items-center gap-5 py-3 relative w-full ps-6"
            >
              <img
                [alt]="'DIVISIONS.DIVISION' | translate"
                ngSrc="assets/images/icons/heirarchy.svg"
                width="16"
                height="14"
                priority
              />
              <p class="font-medium text-sm truncate">{{ authService.currentUserDivisionRole!.divisionName }}</p>
              <mat-icon class="absolute right-3">keyboard_arrow_down</mat-icon>
            </button>
            <ul #divisionList class="px-4 max-h-60 overflow-auto py-2">
              @for (userDivisionRole of authService.getUserDivisionRoles(); track userDivisionRole.divisionId) {
                <li>
                  <button
                    mat-button
                    class="w-full !justify-start !h-11"
                    (click)="setCurrentUserDivisionRole(userDivisionRole); divisonListButton.click()"
                  >
                    {{ userDivisionRole.divisionName }}
                  </button>
                </li>
              }
            </ul>
          }
        }
      }
      <div class="bg-black-100 flex items-center py-3 mt-2 relative">
        <mat-icon class="mx-5">supervised_user_circle</mat-icon>
        <p class="font-medium text-sm truncate w-40 cursor-default" [title]="authService.getFullName()">
          {{ authService.getFullName() }}
        </p>
      </div>
      <button
        (click)="logout()"
        (keypress.enter)="logout()"
        class="bg-black-100 flex items-center py-3 mt-2 relative cursor-pointer w-full"
      >
        <mat-icon class="mx-5">logout</mat-icon>
        <h3 class="font-medium text-sm">{{ "LOGOUT" | translate }}</h3>
      </button>
    </div>
  </mat-sidenav>
  <!--------------------------------toolbar------------------------------------->
  <mat-toolbar class="pb-30">
    <button
      (click)="sideNav.opened ? sideNav.close() : sideNav.open()"
      [ngStyle]="{ display: screenWidth > windowBreakPoint ? 'none' : 'block' }"
      mat-icon-button
    >
      <mat-icon>
        @if (sideNav.opened) {
          closed
        } @else {
          menu
        }
      </mat-icon>
    </button>

    <div class="flex items-center ps-12 pe-2">
      <div class="rounded w-24 h-24 py-4">
        <img alt="estate-tool logo" class="rounded w-full h-full" [src]="authService.getOrganizationLogo()" />
      </div>

      <div class="pl-4">
        <p class="uppercase font-bold">{{ userSignal()?.organisationName }}</p>
        <p class="capitalize text-black-500">{{ userSignal()?.organisationAddress }}</p>
        <p class="capitalize text-black-500">{{ userSignal()?.organisationCountry }}</p>
      </div>
    </div>
    <div class="pe-1.5">
      <p class="text-black">
        {{ "DIVISIONS.NAME" | translate }}:<span class="text-black-500"> {{ userSignal()?.divisionCount }} </span>
      </p>
      <p class="text-black">
        {{ "COMPANIES.NAME" | translate }}:<span class="text-black-500"> {{ userSignal()?.companyCount }} </span>
      </p>
    </div>
    <div class="pe-1.5">
      <p class="text-black">
        {{ "PROPERTIES.NAME" | translate }}:<span class="text-black-500"> {{ userSignal()?.propertyCount }} </span>
      </p>
      <p class="text-black">
        {{ "UNITS" | translate }}:<span class="text-black-500"> {{ userSignal()?.unitCount }} </span>
      </p>
    </div>
    <div class="pe-1.5">
      <p class="text-black">
        {{ "SETTINGS.USERS.ACTIVATED_USERS" | translate }}:<span class="text-black-500">
          {{ userSignal()?.activeUsersCount }}
        </span>
      </p>
    </div>
    <div class="flex items-center">
      <p class="text-black mr-1">{{ "OVERALL_STATUS" | translate }}:</p>
      <span class="text-xs bg-black-600 text-white py-1 px-2.5 rounded mr-1">{{ unitStatues.notActiveNo }}</span
      ><span class="text-xs bg-status-available text-white py-1 px-2.5 rounded mr-1">{{
        unitStatues.availableNo
      }}</span>
      <span class="text-xs bg-status-inProcess text-white py-1 px-2.5 rounded mr-1">{{ unitStatues.inProcessNo }}</span>
      <span class="text-xs bg-status-completed text-white py-1 px-2.5 rounded mr-1">{{ unitStatues.completedNo }}</span>
    </div>
  </mat-toolbar>
  <ng-content></ng-content>
</mat-sidenav-container>
