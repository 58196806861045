<est-small-header
  heading="SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.ANSWER_OPTIONS.NAME"
  subheading="SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.ANSWER_OPTIONS.SECOND_STEP_DESCRIPTION"
></est-small-header>

<p class="mt-4 mb-6">
  {{
    "SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.ANSWER_OPTIONS.SECOND_STEP_QUESTION_INTERNAL_TITLE"
      | translate: { questionInternalTitle: questionInternalTitle() }
  }}
</p>

@for (answerOptionFG of answerOptions().controls; track $index) {
  <div id="answerOption" class="mt-2">
    <mat-accordion>
      <mat-expansion-panel
        #mep="matExpansionPanel"
        hideToggle="true"
        (opened)="mep.expanded = true"
        (closed)="mep.expanded = false"
        [expanded]="true"
      >
        <mat-expansion-panel-header
          class="!h-11 !pl-6 !pr-2 !py-3 border-black-200 border-[0.063rem] bg-gray-100 hover:!bg-gray-100 focus:!bg-gray-100"
          [ngClass]="mep.expanded ? '!rounded-t-lg !rounded-b-none' : '!rounded-lg'"
        >
          <div class="flex justify-between">
            <div class="flex gap-12">
              <p class="font-medium">
                {{ "SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.ANSWER_OPTIONS.ANSWER" | translate }} #{{
                  $index + 1
                }}
              </p>
              <p>
                {{ answerOptionFG.controls.internalTitle.value }}
              </p>
            </div>
            <div class="flex items-center gap-4">
              @if ($index > 0) {
                <button
                  (click)="removeAnswerOption($index); $event.stopPropagation()"
                  type="button"
                  class="bg-white p-1.5 rounded border border-black-400"
                >
                  <img
                    [alt]="'UPLOAD_FILE.DELETE_FILE' | translate"
                    ngSrc="assets/images/icons/trash-icon.svg"
                    width="12"
                    height="12"
                    priority
                  />
                </button>
              }

              <img
                [ngSrc]="mep.expanded ? 'assets/images/icons/chevron-up.svg' : 'assets/images/icons/chevron-down.svg'"
                width="16"
                height="8"
                [alt]="(mep.expanded ? 'EXPAND_CARD' : 'COLLAPSE_CARD') | translate"
                priority="true"
              />
            </div>
          </div>
        </mat-expansion-panel-header>
        <div class="mt-6">
          <est-small-header
            appliedClasses="text-sm !mb-4"
            heading="INTERNAL_TITLE"
            tooltip="INTERNAL_TITLE"
          ></est-small-header>
          <est-input label="INTERNAL_TITLE" [control]="answerOptionFG.controls.internalTitle"></est-input>

          <div class="flex flex-col gap-4 my-6">
            @for (externalTitleControl of answerOptionFG.controls.externalTitles.controls; track externalTitleControl) {
              <est-external-title-input
                smallHeaderAppliedClasses="!mb-6"
                [language]="getExternalTitleLanguage(externalTitleControl.controls['languageId'].value)"
                [isDefault]="isDefaultLanguage(externalTitleControl.controls['languageId'].value)"
                [formGroup]="externalTitleControl"
              ></est-external-title-input>
            }
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
}

<est-button
  appliedClasses="mt-6"
  imageSrc="assets/images/icons/plus-black.svg"
  imagePosition="before"
  imageAlt="+"
  [label]="'SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.ANSWER_OPTIONS.ADD_ANSWER_OPTION' | translate"
  [buttonStyleType]="'button--secondary'"
  buttonType="button"
  (click)="addAnswerOption.emit()"
></est-button>
