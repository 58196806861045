import { Component, effect, input, output } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { AnswerOptionTypeMapper } from "@root/data/market/demographic-questions/mappers/answer-options-type.mapper";
import { ISystemLanguageConfiguration } from "@root/data/market/system-configurations/language/models/system-language-configuration.model";
import { ButtonComponent } from "@root/shared/button/button.component";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { AnswerOptionType } from "@root/shared/enums/answer-option-type.enum";
import { ExternalTitleInputComponent } from "@root/shared/external-title-input/external-title-input.component";
import { InputComponent } from "@root/shared/input/input.component";
import { IDemographicQuestionFormGroup } from "@root/shared/interfaces/demographic-questions-interface";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { IDemographicQuestionPopupInputs } from "../demographic-question-popup-inputs.interface";

@Component({
  selector: "est-demographic-questions-first-step",
  standalone: true,
  imports: [
    DialogHeaderComponent,
    SmallHeaderComponent,
    InputComponent,
    MatSlideToggle,
    TranslateModule,
    TooltipComponent,
    ButtonComponent,
    ExternalTitleInputComponent,
    LoadingOverlayComponent,
    MatRadioGroup,
    MatRadioButton,
    ReactiveFormsModule,
  ],
  templateUrl: "./demographic-questions-first-step.component.html",
  styleUrl: "./demographic-questions-first-step.component.scss",
})
export class DemographicQuestionsFirstStepComponent {
  protected readonly AnswerOptionTypeEnum = AnswerOptionType;
  protected readonly AnswerOptionTypeMapper = AnswerOptionTypeMapper;

  readonly componentInputs = input.required<IDemographicQuestionPopupInputs>();

  languages = input.required<ILanguage[]>();
  formGroup = input.required<FormGroup<IDemographicQuestionFormGroup>>();
  isPageLoading = input.required<boolean>();
  hasAnswerOptions = input.required<boolean>();
  languageConfiguration = input.required<ISystemLanguageConfiguration>();

  showSecondStep = output<boolean>();
  updateExternalTitlesDisabled = output();

  isFormSubmitting: boolean = false;
  showAnswerOptionType = false;

  #answerOptionTypesEffect = effect(() => {
    this.showAnswerOptionType = this.hasAnswerOptions();
  });

  getExternalTitleLanguage(languageId: string): ILanguage {
    return this.languages().find((language) => language.languageId === languageId)!;
  }

  togglePublished() {
    this.formGroup().controls.published.patchValue(!this.formGroup().controls.published.value);
    this.updateExternalTitlesDisabled.emit();
  }

  toggleDefault() {
    this.formGroup().controls.defaultQuestion.patchValue(!this.formGroup().controls.defaultQuestion.value);
  }

  toggleAnswerOptions() {
    this.showAnswerOptionType = !this.showAnswerOptionType;
    this.showSecondStep.emit(this.showAnswerOptionType);
  }

  toggleActivated() {
    this.formGroup().controls.isInheritanceActive.patchValue(!this.formGroup().controls.isInheritanceActive.value);
  }

  isDefaultLanguage(languageId: string): boolean {
    return this.languageConfiguration().defaultEndUserLanguage.languageId === languageId;
  }
}
