import { Component, effect, inject, input, OnDestroy, OnInit, signal } from "@angular/core";
import { IPropertyEventInstance } from "@root/data/market/properties/models/property-event-instance.model";
import { constructInitialSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { EventMainDetailsComponent } from "./event-main-details/event-main-details.component";
import { SeriesEventsInstancesWrapperComponent } from "./series-events-instances-wrapper/series-events-instances-wrapper.component";
import { EventDetailsSignalStateService } from "./services/event-details-signal-state.service";
import { SingleEventInstanceWrapperComponent } from "./single-event-instance-wrapper/single-event-instance-wrapper.component";

@Component({
  selector: "est-event-details",
  standalone: true,
  imports: [EventMainDetailsComponent, SingleEventInstanceWrapperComponent, SeriesEventsInstancesWrapperComponent],
  templateUrl: "./event-details.component.html",
  styleUrl: "./event-details.component.scss",
})
export class EventDetailsComponent implements OnInit, OnDestroy {
  propertyId = input.required<number>();
  eventId = input.required<number>();

  readonly #eventDetailsSignalStateService = inject(EventDetailsSignalStateService);

  eventDetails = this.#eventDetailsSignalStateService.eventDetails;
  isMainDetailsLoading = this.#eventDetailsSignalStateService.isEventDetailsLoading;
  isInstanceLoading = this.#eventDetailsSignalStateService.isInstanceLoading;

  loadPageDetails = signal(false);

  loadPageDetailsEffect = effect(
    () => {
      if (!this.isMainDetailsLoading() && !this.isInstanceLoading()) {
        this.loadPageDetails.set(!this.isMainDetailsLoading() && !this.isInstanceLoading());
      }
    },
    { allowSignalWrites: true },
  );

  ngOnInit(): void {
    this.#eventDetailsSignalStateService.propertyId.set(this.propertyId());
    this.#eventDetailsSignalStateService.eventId.set(this.eventId());
  }
  ngOnDestroy(): void {
    this.destroyProperties();
  }

  destroyProperties(): void {
    this.#eventDetailsSignalStateService.paginatedEventInstances =
      constructInitialSignalPaginatedResponse<IPropertyEventInstance>();

    this.#eventDetailsSignalStateService.propertyId.set(undefined);
    this.#eventDetailsSignalStateService.eventId.set(undefined);
  }
}
