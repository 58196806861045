import { NgClass } from "@angular/common";
import { Component, inject, OnDestroy, OnInit, signal } from "@angular/core";
import { FormArray, FormGroup, FormsModule, NonNullableFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatDivider } from "@angular/material/divider";
import { MatProgressBar } from "@angular/material/progress-bar";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { MatTooltip, TooltipComponent } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { IDemographicQuestion } from "@root/data/market/demographic-questions/models/demographic-question.model";
import { DemographicQuestionsService } from "@root/data/market/demographic-questions/services/demographic-questions.service";
import { ISystemLanguageConfiguration } from "@root/data/market/system-configurations/language/models/system-language-configuration.model";
import { SystemConfigurationsService } from "@root/data/market/system-configurations/services/system-configurations.service";
import { UsersService } from "@root/data/market/users/services/users.service";
import { BaseStepperComponent } from "@root/shared/abstracts/stepper/base-stepper.component";
import { AddressComponent } from "@root/shared/address/address.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { CompanySelectFieldComponent } from "@root/shared/company-select-field/company-select-field.component";
import { CreationSuccessMessageComponent } from "@root/shared/creation-success-message/creation-success-message.component";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { AnswerOptionType } from "@root/shared/enums/answer-option-type.enum";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { GeographicalLocationComponent } from "@root/shared/geographical-location/geographical-location.component";
import { InputComponent } from "@root/shared/input/input.component";
import { IAnswerOptions } from "@root/shared/interfaces/answer-options.model";
import {
  IAnswerOptionsFormControls,
  IDemographicQuestionFormGroup,
  IExternalTitleFormControls,
} from "@root/shared/interfaces/demographic-questions-interface";
import { IExternalTitle } from "@root/shared/interfaces/external-title.model";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { ListSelectTeamsComponent } from "@root/shared/list-select-teams/list-select-teams.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";
import { concatMap, forkJoin, Observable, Subscription, tap } from "rxjs";
import { IDemographicQuestionPopupInputs } from "./demographic-question-popup-inputs.interface";
import { DemographicQuestionsFirstStepComponent } from "./demographic-questions-first-step/demographic-questions-first-step.component";
import { DemographicQuestionsSecondStepComponent } from "./demographic-questions-second-step/demographic-questions-second-step.component";
import { DemographicQuestionsStepperActionButtonsComponent } from "../dq-stepper-action-buttons/dq-stepper-action-buttons.component";

@Component({
  selector: "est-demographic-question-popup",
  standalone: true,
  imports: [
    DialogHeaderComponent,
    MatProgressBar,
    FormsModule,
    AddressComponent,
    CompanySelectFieldComponent,
    CreationSuccessMessageComponent,
    GeographicalLocationComponent,
    InputComponent,
    ListSelectTeamsComponent,
    MatDivider,
    MatSlideToggle,
    MatStep,
    MatStepper,
    NgClass,
    TranslateModule,
    SelectFieldComponent,
    SearchInputComponent,
    ButtonComponent,
    LoadingOverlayComponent,
    MatTooltip,
    TooltipComponent,
    DemographicQuestionsStepperActionButtonsComponent,
    DemographicQuestionsSecondStepComponent,
    DemographicQuestionsFirstStepComponent,
  ],
  templateUrl: "./demographic-question-popup.component.html",
  styleUrl: "./demographic-question-popup.component.scss",
})
export class DemographicQuestionPopupComponent extends BaseStepperComponent implements OnInit, OnDestroy {
  readonly #systemConfigurationService = inject(SystemConfigurationsService);
  readonly #userService = inject(UsersService);

  readonly #fb = inject(NonNullableFormBuilder);
  readonly dialogRef: MatDialogRef<DemographicQuestionPopupComponent> = inject(MatDialogRef);
  readonly componentInputs: IDemographicQuestionPopupInputs = inject(MAT_DIALOG_DATA);
  readonly #demographicQuestionsService = inject(DemographicQuestionsService);

  protected languages: ILanguage[] = [];
  protected languageConfiguration!: ISystemLanguageConfiguration;
  isLoading = false;
  isPageLoading = false;
  #loadDemographicQuestionSubscription: Subscription = new Subscription();

  hasAnswerOptions = signal(false);
  stepWithConfirmButton: number = 0;
  override totalTabsNo: number = 2;

  title = this.#fb.control<string>("", Validators.required);
  enabled = this.#fb.control<boolean>(false, Validators.required);
  languageId = this.#fb.control<number>(0, Validators.required);
  firstStepFG = this.#fb.group<IDemographicQuestionFormGroup>({
    id: this.#fb.control(0),
    internalTitle: this.#fb.control("", Validators.required),
    published: this.#fb.control(false, Validators.required),
    isInheritanceActive: this.#fb.control(false, Validators.required),
    externalTitles: this.#fb.array<FormGroup<IExternalTitleFormControls>>([]),
    defaultQuestion: this.#fb.control(false, Validators.required),
    answerOptionType: this.#fb.control<AnswerOptionType>(AnswerOptionType.Single),
  });
  answerOptions = this.#fb.array<FormGroup<IAnswerOptionsFormControls>>([]);

  ngOnInit(): void {
    this.isPageLoading = true;
    this.#removeAnswerOptionsStep();
    if (this.componentInputs.isEditPopup) {
      this.#initializeEditPopup();
    } else {
      this.#initializeCreatePopup();
    }
  }

  #initializeEditPopup() {
    this.#loadDemographicQuestionSubscription = forkJoin([this.#getAllLanguages(), this.#getLanguageConfiguration()])
      .pipe(concatMap(() => this.#getDemographicQuestion()))
      .subscribe({
        complete: () => {
          this.isPageLoading = false;
          this.updateExternalTitlesDisabled(this.firstStepFG.controls.externalTitles);
        },
      });
  }

  #initializeCreatePopup() {
    this.#loadDemographicQuestionSubscription = forkJoin([
      this.#getAllLanguages(),
      this.#getLanguageConfiguration(),
    ]).subscribe({
      next: () => {
        this.#addRequiredExternalTitles(this.firstStepFG.controls.externalTitles);
      },
      complete: () => {
        this.isPageLoading = false;
        this.updateExternalTitlesDisabled(this.firstStepFG.controls.externalTitles);
      },
    });
  }

  showOrRemoveSecondStep(showSecondStep: boolean) {
    showSecondStep ? this.#showAnswerOptionsStep() : this.#removeAnswerOptionsStep();
  }

  #showAnswerOptionsStep() {
    this.addAnswerOption();
    this.totalTabsNo = 2;
    this.stepWithConfirmButton = this.totalTabsNo - 1;
    this.firstStepFG.updateValueAndValidity();
  }

  #removeAnswerOptionsStep() {
    this.totalTabsNo = 1;
    this.stepWithConfirmButton = this.totalTabsNo - 1;
    this.answerOptions.reset();
    this.answerOptions = this.#fb.array<FormGroup<IAnswerOptionsFormControls>>([]);
    this.firstStepFG.updateValueAndValidity();
  }

  addAnswerOption(answerOption?: IAnswerOptions) {
    this.answerOptions.push(
      this.#fb.group<IAnswerOptionsFormControls>({
        internalTitle: this.#fb.control(answerOption?.internalTitle || "", Validators.required),
        externalTitles: this.#fb.array<FormGroup<IExternalTitleFormControls>>([]),
      }),
    );
    const lastAnswerFG = this.answerOptions.controls[this.answerOptions.controls.length - 1];

    this.#addRequiredExternalTitles(lastAnswerFG.controls.externalTitles, answerOption?.externalTitles);
  }

  #getAllLanguages(): Observable<ILanguage[]> {
    return this.#userService.getAllLanguages().pipe(tap((languages: ILanguage[]) => (this.languages = languages)));
  }

  #getLanguageConfiguration(): Observable<ISystemLanguageConfiguration> {
    return this.#systemConfigurationService
      .getLanguageSystemConfiguration()
      .pipe(tap((languageConfiguration) => (this.languageConfiguration = languageConfiguration)));
  }

  #addRequiredExternalTitles(
    externalTitlesFA: FormArray<FormGroup<IExternalTitleFormControls>>,
    existingExternalTitles?: IExternalTitle[],
  ) {
    this.#addExternalTitle(externalTitlesFA);
    externalTitlesFA.at(0).patchValue({
      title: existingExternalTitles?.at(0)?.title,
      languageId: this.languageConfiguration.defaultEndUserLanguage?.languageId,
      enabled: true,
    });
    for (let i = 0; i < this.languageConfiguration.allowedLanguages.length - 1; i++) {
      this.#addExternalTitle(externalTitlesFA);
      externalTitlesFA.at(i + 1).patchValue({
        title: existingExternalTitles?.at(i + 1)?.title,
        languageId: this.languageConfiguration.allowedLanguages[i + 1].language.languageId,
        enabled: existingExternalTitles ? existingExternalTitles?.at(i + 1)?.enabled : false,
      });
    }
  }

  #addExternalTitle(externalTitlesFA: FormArray<FormGroup<IExternalTitleFormControls>>) {
    externalTitlesFA.push(
      this.#fb.group({
        languageId: this.#fb.control(""),
        title: this.#fb.control("", Validators.required),
        enabled: this.#fb.control(false),
      }),
    );
  }

  updateExternalTitlesDisabled(externalTitlesFA: FormArray<FormGroup<IExternalTitleFormControls>>) {
    externalTitlesFA.controls.forEach((control, index) => {
      if (this.firstStepFG.controls.published.value && externalTitlesFA.at(index).controls["enabled"].value) {
        externalTitlesFA.at(index).enable();
      } else {
        externalTitlesFA.at(index).disable();
      }
    });
  }

  #getDemographicQuestion(): Observable<IDemographicQuestion> {
    return this.#demographicQuestionsService
      .getDemographicQuestionById(SourceLevel.Organisation, this.componentInputs.demographicQuestionId!)
      .pipe(
        tap((demographicQuestion) => {
          this.firstStepFG.patchValue(demographicQuestion);

          this.#addRequiredExternalTitles(this.firstStepFG.controls.externalTitles, demographicQuestion.externalTitles);

          if (demographicQuestion.answerOptions.length > 0) this.#patchAnswerOptions(demographicQuestion);
        }),
      );
  }

  #patchAnswerOptions(demographicQuestion: IDemographicQuestion) {
    this.hasAnswerOptions.set(true);
    this.#showAnswerOptionsStep();
    for (let i = 0; i < demographicQuestion.answerOptions.length; i++) {
      const answerOption = demographicQuestion.answerOptions[i];
      i === 0 ? this.answerOptions.at(0).patchValue(answerOption) : this.addAnswerOption(answerOption);
    }
  }

  override submitForm(): void {
    const demographicQuestionWithAnswersForm = this.#fb.group({
      ...this.firstStepFG.controls,
      answerOptions: this.answerOptions,
    });

    const demographicQuestion: any = demographicQuestionWithAnswersForm.value;

    if (demographicQuestionWithAnswersForm.invalid) {
      markAllControlsAsTouchedAndDirty(demographicQuestionWithAnswersForm);
      return;
    }
    this.isLoading = true;
    if (this.componentInputs.isEditPopup) {
      this.#demographicQuestionsService
        .updateDemographicQuestion(SourceLevel.Organisation, demographicQuestion)
        .subscribe({
          next: () => this.dialogRef.close(true),
          complete: () => (this.isLoading = false),
        });
    } else {
      this.#demographicQuestionsService
        .createDemographicQuestion(SourceLevel.Organisation, demographicQuestion)
        .subscribe({
          next: () => this.dialogRef.close(true),
          complete: () => (this.isLoading = false),
        });
    }
  }

  ngOnDestroy() {
    this.#loadDemographicQuestionSubscription.unsubscribe();
  }
}
