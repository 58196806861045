<button
  (click)="expandCard()"
  [ngClass]="[getStyleBasedOnDate(), isExpanded ? 'h-[33.813rem]' : 'h-[16.438rem]']"
  class="p-4 border-2 w-[23.125rem] rounded-lg relative"
  id="event-card"
>
  <div class="flex justify-between items-start">
    <div class="flex gap-2">
      <p
        [ngClass]="[eventDetails().eventType === EventType.PublicEvent ? 'bg-event-public-50 ' : 'bg-event-private-50']"
        class="py-1 px-6 rounded-md text-xs font-medium"
      >
        {{ eventTypeTranslationMapper.get(eventDetails().eventType!)! | translate }}
      </p>
      @if (eventDetails().repetitionDetails) {
        <img
          ngSrc="assets/images/icons/event-type-with-time.svg"
          [alt]="'ICON' | translate"
          priority
          width="24"
          height="21"
        />
      }
      @if (eventDetails().startDay === todayDate && !eventDetails().isDraft && !eventDetails().isArchived) {
        <p class="py-1 px-4 bg-success-50 rounded-md text-xs font-medium uppercase">
          {{ "EVENTS.HAPPENING_TODAY" | translate }}
        </p>
      }
      @if (eventDetails().isDraft) {
        <p class="py-1 px-10 bg-event-draft rounded-md text-xs font-medium uppercase">
          {{ "EVENTS.DRAFT" | translate }}
        </p>
      } @else if (eventDetails().isArchived) {
        <p class="py-1 px-10 bg-event-draft-50 rounded-md text-xs font-medium uppercase">
          {{ "EVENTS.ARCHIVED" | translate }}
        </p>
      }
    </div>

    <div>
      <button
        (click)="$event.stopPropagation()"
        [attr.aria-label]="'DROPDOWN_MENU' | translate"
        [matMenuTriggerFor]="actionsMenu"
        class="cursor-pointer flex items-center bg-white justify-center rounded border border-black-300"
        type="button"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionsMenu="matMenu">
        @if (!isExpanded) {
          <button mat-menu-item type="button" (click)="expandCard()">
            {{ "EVENTS.EXPAND_CARD" | translate }}
          </button>
        } @else {
          <button mat-menu-item type="button" (click)="expandCard()">
            {{ "EVENTS.COLLAPSE_CARD" | translate }}
          </button>
        }
        @if (eventDetails().repetitionDetails && eventDetails().repetitionDetails.occurrences > 1) {
          <button mat-menu-item type="button" (click)="openStopRepetition(eventDetails().id)">
            {{ "EVENTS.STOP_REPETITION_EVENT" | translate }}
          </button>
        }
        <button (click)="openEventDetails()" mat-menu-item type="button">
          {{ "EVENTS.GO_TO_EVENT_DETAILS" | translate }}
        </button>
        @if (eventDetails().repetitionDetails && eventDetails().repetitionDetails.occurrences !== 0) {
          <button mat-menu-item type="button" (click)="editEventDetails(eventDetails().id)">
            {{ "EVENTS.EDIT_THIS_EVENT" | translate }}
          </button>
          <button mat-menu-item type="button">
            {{ "EVENTS.DELETE_THIS_EVENT" | translate }}
          </button>
          <button mat-menu-item type="button">
            {{ "EVENTS.EDIT_SERIES_OF_EVENTS" | translate }}
          </button>
          <button mat-menu-item type="button">
            {{ "EVENTS.ARCHIVE_SERIES_OF_EVENTS" | translate }}
          </button>
        } @else {
          <button mat-menu-item type="button" (click)="editEventDetails(eventDetails().id)">
            {{ "EVENTS.EDIT_EVENT" | translate }}
          </button>
          <button mat-menu-item type="button">
            {{ "EVENTS.DELETE_EVENT" | translate }}
          </button>
        }
      </mat-menu>
    </div>
  </div>
  <div class="mt-4 text-start">
    @if (isExpanded) {
      <p class="font-medium text-xs mb-2">{{ "EVENTS.DATE_AND_TIME" | translate }}</p>
    }
    <div class="flex gap-2 mb-4">
      <img [alt]="'ICON' | translate" height="16" ngSrc="assets/images/icons/date-details.svg" priority width="14" />
      <p class="font-light text-xs">{{ formatDateToGetWithDay(eventDetails().startDay!) }}</p>
    </div>
    <div class="flex gap-2 mb-4">
      <img [alt]="'ICON' | translate" height="16" ngSrc="assets/images/icons/time.svg" priority width="16" />
      <p class="font-light text-xs">
        {{ eventDetails().startTime! }} -
        {{ eventDetails().endTime! }}
      </p>
    </div>
    @if (isExpanded) {
      <div class="flex gap-2 mb-4">
        <img ngSrc="assets/images/icons/arrows-repeat.svg" [alt]="'ICON' | translate" priority width="16" height="16" />
        @if (eventDetails().repetitionDetails) {
          <p class="font-light text-xs">
            {{ "EVENTS.REPEATS_EVERY" | translate }}
            {{ eventDetails().repetitionDetails.repetitionCycle }}
          </p>
        } @else {
          <p class="font-light text-xs">-</p>
        }
      </div>

      <div class="flex gap-2 mb-6">
        <img ngSrc="assets/images/icons/date-details.svg" [alt]="'ICON' | translate" priority width="14" height="16" />
        @if (eventDetails().repetitionDetails) {
          <p class="font-light text-xs">
            {{ formatDateToGetWithDay(eventDetails().repetitionDetails.endDate) }}
          </p>
        } @else {
          <p class="font-light text-xs">-</p>
        }
      </div>
    }
    @if (isExpanded) {
      <p class="font-medium text-xs mb-2">{{ "EVENTS.DETAILS_ABOUT_THE_EVENT" | translate }}</p>
    }

    <div class="flex gap-2 mb-4">
      <img [alt]="'ICON' | translate" height="16" ngSrc="assets/images/icons/event-details.svg" priority width="16" />
      <p class="font-light text-xs pe-2.5">{{ eventDetails().internalTitle }}</p>
    </div>
    @if (isExpanded) {
      <div class="flex gap-2 mb-4">
        <img ngSrc="assets/images/icons/rectangle-ad.svg" [alt]="'ICON' | translate" priority width="16" height="16" />
        <p class="font-light text-xs">{{ eventDetails().externalTitle }}</p>
      </div>
    }
    <div class="flex gap-2 mb-4">
      <img [alt]="'ICON' | translate" height="16" ngSrc="assets/images/icons/location.svg" priority width="12" />
      <p class="font-light text-xs pe-2.5">{{ eventDetails().address }}</p>
    </div>
    @if (isExpanded) {
      <div class="flex gap-2 mb-6">
        <img ngSrc="assets/images/icons/user.svg" [alt]="'ICON' | translate" priority width="16" height="16" />
        <p class="font-light text-xs">{{ eventDetails().responsibleAgent }}</p>
      </div>
    }
    @if (isExpanded) {
      <p class="font-medium text-xs mb-2">{{ "EVENTS.BOOKING_DETAILS" | translate }}</p>
    }
    <div class="flex gap-2 mb-4">
      <img [alt]="'ICON' | translate" height="16" ngSrc="assets/images/icons/users.svg" priority width="12" />
      <p class="font-light text-xs pe-2.5">
        {{
          eventDetails().bookingDetails
            ? ("EVENTS.EVENT_WITH_BOOKING" | translate)
            : ("EVENTS.EVENT_WITHOUT_BOOKING" | translate)
        }}
      </p>
    </div>
    @if (isExpanded) {
      <div class="flex gap-2 mb-4">
        <img ngSrc="assets/images/icons/user-police.svg" [alt]="'ICON' | translate" priority width="16" height="16" />
        <p class="font-light text-xs">
          {{ getEventBookingType(eventDetails().bookingDetails?.type) }}
        </p>
      </div>
      <div class="flex gap-2 mb-4">
        <img
          ngSrc="assets/images/icons/person-to-door.svg"
          [alt]="'ICON' | translate"
          priority
          width="16"
          height="16"
        />
        <p class="font-light text-xs">
          {{
            eventDetails().bookingDetails?.maximumAttendants
              ? ("EVENTS.TOTAL_SPOTS" | translate) + eventDetails().bookingDetails?.maximumAttendants
              : "-"
          }}
        </p>
      </div>
    }
    <div class="flex gap-2 items-center">
      <div
        [ngClass]="
          !eventDetails().bookingDetails || eventDetails().bookingDetails?.isAvailable
            ? 'bg-success-dark'
            : 'bg-red-900'
        "
        class="rounded-full w-4 h-4"
      ></div>
      <p class="font-light text-xs pe-2.5">
        {{ getEventStatus(!eventDetails().bookingDetails || eventDetails().bookingDetails?.isAvailable) | translate }}
      </p>
    </div>
  </div>
  <img
    [alt]="'ICON' | translate"
    [ngClass]="isExpanded ? 'rotate-180' : 'rotate-0'"
    class="absolute right-6 bottom-6"
    height="10"
    ngSrc="../../../assets/images/icons/arrow-down-event-card.svg"
    priority
    width="10"
  />
</button>
