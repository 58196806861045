<est-event-title [eventTitle]="title" [totalEvents]="allEvents.length " />

<est-event-filter
  [isDisabled]="isLoading"
  [agentsSignal]="agentsSignal"
  [endDateSignal]="endDateSignal"
  [eventTypeSignal]="eventTypeSignal"
  [searchSignal]="searchSignal"
  [startDateSignal]="startDateSignal"
  [isStartDateIncluded]="isStartDateIncluded"
  [isEndDateIncluded]="isEndDateIncluded"
  [propertyId]="propertyId()"
></est-event-filter>

@if(isLoading){
<est-loading-overlay appliedClasses="relative h-80"></est-loading-overlay>
}@else {
<div class="mt-6 relative">
  <div id="main-box" [ngClass]="{'grid':allEvents.length>0}">
    @for (event of allEvents; track $index) {
    <est-event-card
      [eventDetails]="event"
      [propertyId]="propertyId()"
      (isStoppedRepetition)="loadDataAfterStopRepetition($event)"
      (openCard)="cardIsExpanded($event ,$index)"
    ></est-event-card>
    } @empty {
    <div class="bg-black-50 flex items-center justify-center py-8 rounded border border-black-300">
      <p [attr.aria-label]="'NO_RESULTS' | translate" class="text-xs font-light">{{ "NO_RESULTS" | translate }}</p>
    </div>
    }
  </div>
</div>
}
