import { Injectable, inject } from "@angular/core";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { IAddressFormGroup } from "@root/shared/interfaces/address-form-group.interface";
import { endDateBeforeStartDateValidator } from "@root/shared/validators/date-validators";

@Injectable()
export class UnitMainDetailsFormControls {
  readonly #formBuilder = inject(NonNullableFormBuilder);
  id = this.#formBuilder.control({ value: "", disabled: true });
  customUnitId1 = this.#formBuilder.control("", Validators.required);
  customUnitId2 = this.#formBuilder.control("");
  customUnitId3 = this.#formBuilder.control("");
  caseId = this.#formBuilder.control({ value: "", disabled: true });
  mindworkingId = this.#formBuilder.control({ value: "", disabled: true });
  vacancyDate = this.#formBuilder.control("");
  renovationStartDate = this.#formBuilder.control("", Validators.required);
  renovationEndDate = this.#formBuilder.control("", Validators.required);
  type = this.#formBuilder.control("", Validators.required);
  listingType = this.#formBuilder.control("", Validators.required);
  housingType = this.#formBuilder.control("", Validators.required);
  stage = this.#formBuilder.control("");
  floorPlanType = this.#formBuilder.control("");
  constructionYear = this.#formBuilder.control<number>(0);
  longitude = this.#formBuilder.control<string>("", Validators.required);
  latitude = this.#formBuilder.control<string>("", Validators.required);
  plotNumber = this.#formBuilder.control("");
  administrationStartDate = this.#formBuilder.control("", Validators.required);
  administrationEndDate = this.#formBuilder.control("", Validators.required);
  isUnderRenovation = this.#formBuilder.control<boolean>(false);
  sameYearAsProperty = this.#formBuilder.control<boolean>({ value: false, disabled: true });
  useDatesFromProperty = this.#formBuilder.control<boolean>({ value: false, disabled: true });
  useGeoFromProperty = this.#formBuilder.control<boolean>({ value: false, disabled: true });

  addressInformation = this.#formBuilder.group<IAddressFormGroup>({
    countryId: this.#formBuilder.control({ value: "", disabled: true }, Validators.required),
    streetName: this.#formBuilder.control("", Validators.required),
    houseNumber: this.#formBuilder.control<string>("", Validators.required),
    floor: this.#formBuilder.control<number>(0),
    side: this.#formBuilder.control(""),
    door: this.#formBuilder.control(""),
    zipCode: this.#formBuilder.control("", Validators.required),
    city: this.#formBuilder.control("", Validators.required),
  });

  mainDetailsForm = this.#formBuilder.group({
    unitIdentification: this.#formBuilder.group({
      id: this.id,
      customUnitId1: this.customUnitId1,
      customUnitId2: this.customUnitId2,
      customUnitId3: this.customUnitId3,
      caseId: this.caseId,
      mindworkingId: this.mindworkingId,
    }),
    unitAvailability: this.#formBuilder.group(
      {
        isUnderRenovation: this.isUnderRenovation,
        vacancyDate: this.vacancyDate,
        renovationStartDate: this.renovationStartDate,
        renovationEndDate: this.renovationEndDate,
      },
      { validators: endDateBeforeStartDateValidator("renovationStartDate", "renovationEndDate") },
    ),
    unitSpecification: this.#formBuilder.group({
      type: this.type,
      housingType: this.housingType,
      listingType: this.listingType,
      stage: this.stage,
      floorPlanType: this.floorPlanType,
      constructionYear: this.constructionYear,
    }),
    unitAddress: this.#formBuilder.group({
      addressInformation: this.addressInformation,
      longitude: this.longitude,
      latitude: this.latitude,
      plotNumber: this.plotNumber,
    }),
    unitPropertyManagement: this.#formBuilder.group(
      {
        administrationStartDate: this.administrationStartDate,
        administrationEndDate: this.administrationEndDate,
      },
      { validators: endDateBeforeStartDateValidator("administrationStartDate", "administrationEndDate") },
    ),
  });
}
