<div class="bg-black-50 border border-black-300 p-6 rounded-lg relative w-[22.813rem]">
  <div class="text-xs font-light h-36 bg-white border border-black-300 rounded-lg p-2 overflow-hidden">
    <p class="leading-7" [innerHTML]="sanitizer.bypassSecurityTrustHtml(textPackage().defaultText)"></p>
  </div>
  <hr class="absolute start-0 end-0 border-black-100 my-6" />
  <div class="mt-12 mb-4">
    <p class="font-medium text-sm">{{ "PACKAGES.INTERNAL_TITLE" | translate }}</p>
    <p class="font-light text-sm">{{ textPackage().internalTitle }}</p>
  </div>
  <div class="mb-4">
    <p class="font-medium text-sm">{{ "PROPERTY_TEXTS.PLACEMENT_ID" | translate }}</p>
    <p class="font-light text-sm">
      #{{ textPackage().placementId }}
      <span class="text-xs text-black-500"> {{ "(" + textPackage().placementTitle + ")" }}</span>
    </p>
  </div>
  <div class="mb-4">
    <p class="font-medium text-sm">{{ "PROPERTY_TEXTS.FORMATTING" | translate }}</p>
    <p class="font-light text-sm">
      {{ textPackage().format }}
    </p>
  </div>
  <div class="mb-4">
    <p class="font-medium text-sm">{{ "PROPERTY_TEXTS.LANGUAGES" | translate }}</p>
    <p class="font-light text-sm">
      @for (language of textPackage().languages; track language; let last = $last) {
        {{ language + (last ? "" : ",") }}
      }
    </p>
  </div>
  @if (isPackage()) {
    <div class="mb-4">
      <p class="font-medium text-sm">{{ "PACKAGES.LINKED" | translate }}</p>
      @if (textPackage().numberOfLinkedUnits > 0) {
        <button
          class="flex gap-1 outline-none"
          type="button"
          [attr.aria-label]="'PACKAGES.LINKED' | translate"
          (click)="openLinkedPopup()"
        >
          <img ngSrc="assets/images/icons/linked-icon.svg" [alt]="'ICON' | translate" priority width="16" height="16" />
          <span class="font-light text-sm"> {{ "PACKAGES.LINKED" | translate }}</span>
        </button>
      } @else {
        <p class="text-sm font-light text-black-500">{{ "PACKAGES.NO_LINK_CREATED" | translate }}</p>
      }
    </div>
  }
  <div class="mb-4">
    <p class="font-medium text-sm">{{ "STATUS" | translate }}</p>
    @if (textPackage().isActive) {
      <p class="font-light text-sm">{{ "ACTIVE" | translate }}</p>
    } @else {
      <p class="font-light text-sm">{{ "NOT_ACTIVE" | translate }}</p>
    }
  </div>

  <div>
    <p class="font-medium text-sm">{{ "PACKAGES.UPDATED" | translate }}</p>
    @if (textPackage().updatedAt && textPackage().updatedBy) {
      <p class="font-light text-sm">
        {{ textPackage().updatedAt | customDate: true }}
        <span class="text-black-500"> {{ "(" + textPackage().updatedBy + ")" }} </span>
      </p>
    } @else {
      <p class="font-light text-sm">{{ "NOT_PROVIDED" | translate }}</p>
    }
  </div>

  <div class="mt-6 flex justify-between items-end">
    <div>
      <p class="text-xs font-light text-black-500">
        {{ "PACKAGES.CREATED_BY" | translate }} {{ textPackage().createdBy }}
      </p>
      <p class="text-xs font-light text-black-500">{{ textPackage().createdAt | customDate }}</p>
    </div>
    <div>
      <button
        type="button"
        [matMenuTriggerFor]="actionsMenu"
        [attr.aria-label]="'DROPDOWN_MENU' | translate"
        class="cursor-pointer flex items-center bg-white justify-center rounded border border-black-300"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionsMenu="matMenu">
        @for (action of actions(); track action.label) {
          @if (!action.isVisible || action.isVisible.call(this, textPackage().id)) {
            <button
              mat-menu-item
              type="button"
              [attr.aria-label]="action.label | translate"
              (keypress.enter)="action.callbackFn(textPackage().id, textPackage())"
              (click)="action.callbackFn(textPackage().id, textPackage())"
            >
              {{ action.label | translate }}
            </button>
          }
        }
      </mat-menu>
    </div>
  </div>
</div>
