<form>
  <div class="sticky top-0 z-10">
    <est-dialog-header
      [label]="
        componentInputs.isEditPopup
          ? 'SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.EDIT.NAME'
          : 'SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.CREATE.TITLE'
      "
      [tooltipLabel]="
        componentInputs.isEditPopup
          ? 'SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.EDIT.NAME'
          : 'SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.CREATE.TITLE'
      "
    ></est-dialog-header>
  </div>
  <mat-progress-bar [value]="getProgressbarValue()"></mat-progress-bar>

  <div class="relative mt-6">
    <div class="max-h-[calc(100dvh-14rem)] overflow-y-auto pb-8 relative">
      <mat-stepper class="mx-6" #stepper animationDuration="0">
        <!-- Step 1 Question Details -->
        <mat-step [stepControl]="firstStepFG">
          <est-demographic-questions-first-step
            [componentInputs]="componentInputs"
            [formGroup]="firstStepFG"
            [languages]="languages"
            [hasAnswerOptions]="hasAnswerOptions()"
            [languageConfiguration]="languageConfiguration"
            (updateExternalTitlesDisabled)="updateExternalTitlesDisabled(firstStepFG.controls.externalTitles)"
            [isPageLoading]="isPageLoading"
            (showSecondStep)="showOrRemoveSecondStep($event)"
          ></est-demographic-questions-first-step>
        </mat-step>

        <!-- Step 2 Answers -->
        @if (totalTabsNo === 2) {
          <mat-step [stepControl]="answerOptions">
            <est-demographic-questions-second-step
              [componentInputs]="componentInputs"
              [answerOptions]="answerOptions"
              [languages]="languages"
              [languageConfiguration]="languageConfiguration"
              [questionInternalTitle]="firstStepFG.controls.internalTitle.value"
              (addAnswerOption)="addAnswerOption()"
            ></est-demographic-questions-second-step>
          </mat-step>
        }
      </mat-stepper>
    </div>
  </div>

  <est-dq-stepper-action-buttons
    [stepper]="stepper"
    [isLoading]="isLoading"
    [submitLabel]="componentInputs.isEditPopup ? 'UPDATE' : 'CREATE'"
    [stepWithConfirmButton]="stepWithConfirmButton"
    (onSubmit)="submitForm()"
    (onNext)="moveToNextStepIfValid()"
  ></est-dq-stepper-action-buttons>
</form>
