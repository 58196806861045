@if (!isPageLoading) {
  <form [formGroup]="customerPageFormControls.customerPageOptionsForm" (change)="formPatched()">
    <est-small-header
      appliedClasses="!text-xl"
      heading="SETTINGS.COMMUNICATION.OPTIONS.SETTINGS_ON_CUSTOMER_PAGE"
      subheading="SETTINGS.COMMUNICATION.OPTIONS.SETTINGS_ON_COMMUNICATION.DESCRIPTION"
      tooltip="SETTINGS.COMMUNICATION.OPTIONS.SETTINGS_ON_CUSTOMER_PAGE"
    ></est-small-header>

    <est-small-header
      appliedClasses="!mt-12"
      heading="SETTINGS.COMMUNICATION.OPTIONS.ALLOW_SETTING_CHANGES.NAME"
      subheading="SETTINGS.COMMUNICATION.OPTIONS.ALLOW_SETTING_CHANGES.DESCRIPTION"
      tooltip="SETTINGS.COMMUNICATION.OPTIONS.ALLOW_SETTING_CHANGES.NAME"
    ></est-small-header>
    <mat-slide-toggle
      (change)="formPatched()"
      class="text-sm font-light mt-4 mb-6"
      [formControl]="getControl('settingChangesOnAllLevelsEnabled')"
      >{{ "SETTINGS.COMMUNICATION.OPTIONS.ALLOW_SETTING_CHANGES.TOGGLE" | translate }}
    </mat-slide-toggle>

    <hr class="absolute start-0 end-0 border-black-100" />

    <est-small-header
      appliedClasses="!mt-6"
      heading="SETTINGS.COMMUNICATION.OPTIONS.FAVOURITE_UNITS.NAME"
      subheading="SETTINGS.COMMUNICATION.OPTIONS.FAVOURITE_UNITS.DESCRIPTION"
      subheadingAppliedClasses="!w-[32.75rem]"
      tooltip="SETTINGS.COMMUNICATION.OPTIONS.FAVOURITE_UNITS.NAME"
    ></est-small-header>
    <P class="text-sm font-light text-black-500 mt-2 mb-4">{{
      "STYLING.SETTING_IS_INHERITED_TO_LEVELS_BELOW" | translate
    }}</P>
    <div>
      <mat-slide-toggle
        class="text-sm font-light mb-4"
        (change)="formPatched()"
        [formControl]="getControl('favouriteUnitsEnabled')"
        >{{ "SETTINGS.COMMUNICATION.OPTIONS.FAVOURITE_UNITS.ACTIVATE_FEATURE" | translate }}
      </mat-slide-toggle>
    </div>
    <mat-slide-toggle
      class="text-sm font-light mb-4"
      (change)="formPatched()"
      [formControl]="getControl('severalFeaturesEnabled')"
      >{{ "SETTINGS.COMMUNICATION.OPTIONS.FAVOURITE_UNITS.ALLOW_SEVERAL_FAVOURITES" | translate }}
    </mat-slide-toggle>
    <est-select-field
      (changeSelectValue)="formPatched()"
      [control]="customerPageFormControls.maxNumberOfFavourites"
      [dropDownOptions]="maximumNumberOfFavouritesOptions"
      [selectMultipleOptions]="false"
      appliedClass="!w-[9.375rem] !mb-6 mt-1"
      dropDownLabel="SETTINGS.COMMUNICATION.OPTIONS.FAVOURITE_UNITS.MAX_NO_OF_FAVOURITES"
    />
    <hr class="absolute start-0 end-0 border-black-100" />
    <est-small-header
      appliedClasses="!mt-10"
      heading="SETTINGS.COMMUNICATION.OPTIONS.LOG_REASONS.NAME"
      subheading="SETTINGS.COMMUNICATION.OPTIONS.LOG_REASONS.DESCRIPTION"
      subheadingAppliedClasses="!w-[32.75rem]"
      tooltip="SETTINGS.COMMUNICATION.OPTIONS.LOG_REASONS.NAME"
    ></est-small-header>
    <P class="text-sm font-light text-black-500 mt-2 mb-4">{{
      "STYLING.SETTING_IS_INHERITED_TO_LEVELS_BELOW" | translate
    }}</P>
    <mat-slide-toggle
      class="text-sm font-light mb-6"
      (change)="formPatched()"
      [formControl]="getControl('logReasonsEnabled')"
      >{{ "SETTINGS.COMMUNICATION.OPTIONS.FAVOURITE_UNITS.ACTIVATE_FEATURE" | translate }}
    </mat-slide-toggle>
    <hr class="absolute start-0 end-0 border-black-100" />
    <est-small-header
      appliedClasses="!mt-6"
      heading="SETTINGS.COMMUNICATION.OPTIONS.PRIVACY_PERMISSION.NAME"
      subheading="SETTINGS.COMMUNICATION.OPTIONS.PRIVACY_PERMISSION.DESCRIPTION"
      tooltip="SETTINGS.COMMUNICATION.OPTIONS.PRIVACY_PERMISSION.NAME"
    ></est-small-header>
    <P class="text-sm font-light text-black-500 mt-2 mb-4">{{
      "STYLING.SETTING_IS_INHERITED_TO_LEVELS_BELOW" | translate
    }}</P>
    <est-input
      [control]="getControl('privacyPermissionUrl')"
      appliedClass="!w-[37.5rem] !mb-6"
      label="SETTINGS.COMMUNICATION.OPTIONS.INSERT_URL_PRIVACY_PERMISSION_PLACEHOLDER"
    ></est-input>
    <hr class="absolute start-0 end-0 border-black-100" />
    <est-small-header
      appliedClasses="!mt-6"
      heading="SETTINGS.COMMUNICATION.OPTIONS.MARKETING_PERMISSION.NAME"
      subheading="SETTINGS.COMMUNICATION.OPTIONS.MARKETING_PERMISSION.DESCRIPTION"
      tooltip="SETTINGS.COMMUNICATION.OPTIONS.MARKETING_PERMISSION.NAME"
    ></est-small-header>
    <P class="text-sm font-light text-black-500 mt-2">{{
      "STYLING.SETTING_IS_INHERITED_TO_LEVELS_BELOW" | translate
    }}</P>
    <div>
      <mat-slide-toggle
        (change)="formPatched()"
        class="text-sm font-light mt-2"
        [formControl]="getControl('marketingPermissionEnabled')"
        >{{ "SETTINGS.COMMUNICATION.OPTIONS.Activate marketing permission" | translate }}
      </mat-slide-toggle>
    </div>
    <est-input
      [control]="getControl('marketingPermissionUrl')"
      appliedClass="!w-[37.5rem] !mt-6"
      label="SETTINGS.COMMUNICATION.OPTIONS.INSERT_URL_MARKETING_PERMISSION_PLACEHOLDER"
    ></est-input>
  </form>
} @else {
  <div class="py-32 relative">
    <est-loading-overlay></est-loading-overlay>
  </div>
}
