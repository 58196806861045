<div class="flex justify-between mt-6 flex-wrap">
  <est-search-input [isDisabled]="isDisabled()" [searchSignal]="searchSignal()"></est-search-input>
  <div class="flex gap-4 flex-wrap">
    <est-select-field
      [isDisabled]="isDisabled()"
      [control]="eventTypeControl"
      [dropDownOptions]="eventTypeOptions"
      [selectMultipleOptions]="false"
      [selectSignal]="eventTypeSignal()"
      appliedClass="w-[9.375rem]"
      dropDownLabel="EVENTS.EVENT_TYPE_FILTER_LABEL"
    >
    </est-select-field>
    <est-select-field
      [isDisabled]="isDisabled()"
      [control]="agentControl"
      [dropDownOptions]="agentsOptions"
      [selectMultipleOptions]="false"
      [selectSignal]="agentsSignal()"
      appliedClass="w-[9.375rem]"
      dropDownLabel="EVENTS.AGENT_FILTER_LABEL"
    >
    </est-select-field>
    <est-datepicker-range-input
      [isDisabled]="isDisabled()"
      [startDateSignal]="startDateSignal()"
      [endDateSignal]="endDateSignal()"
      [isStartDateIncluded]="isStartDateIncluded()"
      [isEndDateIncluded]="isEndDateIncluded()"
    ></est-datepicker-range-input>
    <button
      [matMenuTriggerFor]="menu"
      class="button--primary uppercase flex items-center gap-x-2 !h-11 min-w-[8.438rem]"
      type="button"
    >
      <img [ngSrc]="'/assets/images/icons/plus.svg'" alt="plus icon" height="12" priority width="12" />
      <span class="text-sm"> {{ "EVENTS.CREATE_EVENT" | translate }}</span>
      <mat-menu #menu="matMenu" class="mt-1">
        <button mat-menu-item routerLink="../create" [queryParams]="{ isIncludedUnits: false }">
          {{ "EVENTS.EVENT_ON_THE_PROPERTY" | translate }}
        </button>
        <button mat-menu-item routerLink="../create" [queryParams]="{ isIncludedUnits: true }">
          {{ "EVENTS.EVENT_ON_ONE_OR_MORE_UNITS" | translate }}
        </button>
      </mat-menu>
    </button>
  </div>
</div>
