import { Component, inject, OnInit } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { finalize, interval } from "rxjs";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { GeneralFormControls } from "@root/views/main/organization/organization-settings/components/system-configurations/components/general/general.form-controls";
import { ReactiveFormsModule } from "@angular/forms";
import { updateNestedControlsPathAndValue } from "@root/shared/utilities/form.utilities";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { TranslateModule } from "@ngx-translate/core";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { GeneralSystemSettingsService } from "@root/data/market/generalSystemSettings/services/generalSystemSettings.service";
import { ITimezoneDropdownOption } from "@root/views/main/organization/organization-settings/components/system-configurations/components/general/timezone-dropdown-option.interface";
import { DateTime } from "luxon";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { TimezoneSearchSelectFieldComponent } from "@root/shared/timezone-search-select-field/timezone-search-select-field.component";

@Component({
  selector: "est-general",
  standalone: true,
  imports: [
    CommonModule,
    SelectFieldComponent,
    SmallHeaderComponent,
    ReactiveFormsModule,
    LoadingOverlayComponent,
    TranslateModule,
    DatePipe,
    TimezoneSearchSelectFieldComponent,
  ],
  templateUrl: "./general.component.html",
  styleUrls: ["./general.component.scss"],
  providers: [GeneralFormControls],
})
export class GeneralComponent implements OnInit {
  readonly generalSettingsFormControls = inject(GeneralFormControls);
  isLoading = false;
  timeZoneDropdownOptions: ITimezoneDropdownOption[] = [];
  currentTime = DateTime.now();
  readonly #generalSystemSettingsService = inject(GeneralSystemSettingsService);
  readonly #authService = inject(AuthService);

  readonly #updateCurrentTime = interval(1000)
    .pipe(takeUntilDestroyed())
    .subscribe(() => {
      this.currentTime = this.currentTime.plus({ seconds: 1 });
    });

  ngOnInit(): void {
    this.#loadData();
    this.#patchOnFormChange$();
  }

  updateGeneralSettings() {
    const selectedTimezone = this.timeZoneDropdownOptions.find(
      (tz) => tz.id === this.generalSettingsFormControls.timezoneControl.value,
    );

    if (selectedTimezone) {
      this.#authService.updateUserTimeZone(selectedTimezone);
    }
    const controlPaths = updateNestedControlsPathAndValue(
      this.generalSettingsFormControls.generalSettingsFormGroup,
      true,
    );

    if (Object.keys(controlPaths).length > 0) {
      this.#generalSystemSettingsService.patchSystemDefaultSettingsConfiguration(controlPaths).subscribe();
    }
  }
  #loadData() {
    this.isLoading = true;
    this.#generalSystemSettingsService
      .getGeneralSystemDefaultSettings()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((settings) => {
        this.generalSettingsFormControls.patchValue({
          timezoneId: settings.timezoneId,
        });
      });
  }

  #patchOnFormChange$() {
    this.generalSettingsFormControls.generalSettingsFormGroup.valueChanges.pipe().subscribe(() => {
      this.updateGeneralSettings();
    });
  }
}
