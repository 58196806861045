import { NgFor } from "@angular/common";
import { Component, DestroyRef, effect, inject, input, OnInit, output, untracked } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormArray, FormControl, FormGroup, NonNullableFormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { PropertyMediaType } from "@root/data/market/properties/enums/property-media-type.enum";
import { IUploadedPropertyMediaPackageDetails } from "@root/data/market/properties/models/uploaded-property-media-package-details.model";
import { FileType } from "@root/shared/enums/file-type.enum";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { MediaCardComponent } from "@root/shared/media-card/media-card.component";
import { getFileSize, getImageFileDimensions, getVideoFileDimensions } from "@root/shared/utilities/files.utilities";
import { getCompressedImageURLFromBlobUrl, getFileType } from "@root/shared/utilities/media.utilities";
import { IMediaDetailsInputs } from "@root/views/main/property/property-marketing/components/media-details/media-details-inputs.interface";
import { MediaDetailsComponent } from "@root/views/main/property/property-marketing/components/media-details/media-details.component";
import { IMediaFormGroup } from "@root/views/main/property/property-marketing/components/media-form-group.interface";
import { SortablejsModule } from "nxt-sortablejs";
import { filter } from "rxjs/internal/operators/filter";
import { first } from "rxjs/internal/operators/first";
import { Options } from "sortablejs";
import { MediaGridCommunicationService } from "./media-grid-communication.service";

@Component({
  selector: "est-media-sortable-grid",
  standalone: true,
  imports: [SortablejsModule, MediaCardComponent, NgFor, LoadingOverlayComponent],
  templateUrl: "./media-sortable-grid.component.html",
  styleUrl: "./media-sortable-grid.component.scss",
})
export class MediaSortableGridComponent implements OnInit {
  readonly authService = inject(AuthService);
  fileUploadControl = input.required<FormControl>();
  formArray = input.required<FormArray<FormGroup<IMediaFormGroup>>>();
  medias = input<IUploadedPropertyMediaPackageDetails[]>([]);
  changeControl = output<{ id: number; control: any }>();
  options: Options = {
    swapThreshold: 2,
    animation: 150,
    handle: ".handle",
    bubbleScroll: true,
    dragClass: "!opacity-70",
    forceFallback: true,
    onEnd: () => {
      this.formArray().updateValueAndValidity();
    },
  };
  readonly #destroyRef = inject(DestroyRef);
  readonly #matDialog = inject(MatDialog);
  readonly #fb = inject(NonNullableFormBuilder);
  mediaGridCommunicationService = inject(MediaGridCommunicationService);

  isLoadingCards = false;

  mediaLengthSignal = this.mediaGridCommunicationService.mediaLengthNumber;
  effectasd = effect(() => {
    if (this.mediaLengthSignal()) {
      this.isLoadingCards = true;
      const previousValuesLength = untracked(() => this.formArray().value.length);
      untracked(() =>
        this.formArray()
          .valueChanges.pipe(
            filter((formArrayValue) => formArrayValue.length >= this.mediaLengthSignal()!),
            first(),
            takeUntilDestroyed(this.#destroyRef),
          )
          .subscribe(() => this.#optimizeMediaUrlsInFormArray(previousValuesLength)),
      );
    }
  });

  ngOnInit() {
    this.#subscribeToAddFile();
  }

  async #optimizeMediaUrlsInFormArray(previousValuesLength: number) {
    const maxWidth = 315;
    const qualityFactor = 0.7;

    const promises = this.formArray()
      .controls.slice(previousValuesLength)
      .map(async (mediaFormGroup) => {
        const url = mediaFormGroup.controls.filePath.value || URL.createObjectURL(mediaFormGroup.controls.file.value!);
        const compressedUrl = await getCompressedImageURLFromBlobUrl(url, maxWidth, qualityFactor);
        mediaFormGroup.controls.filePath.setValue(compressedUrl);
      });
    await Promise.all(promises);
    this.isLoadingCards = false;
    this.mediaLengthSignal.set(0);
  }

  getFileName(index: number): string {
    const file = this.formArray().at(index).controls.file.value;
    if (file) return file.name;
    else return this.medias()[index]?.fileName || "";
  }

  async constructFormGroup(file: File): Promise<FormGroup<IMediaFormGroup>> {
    const fileType = getFileType(file.name);
    const fileTypeControl = this.#fb.control<FileType>(fileType);
    const dimensions =
      fileType === FileType.Video ? await getVideoFileDimensions(file!) : await getImageFileDimensions(file!);
    return this.#fb.group({
      id: this.#fb.control<number | undefined>(undefined),
      file: this.#fb.control<File | undefined>(file),
      mediaType: this.#fb.control<PropertyMediaType | undefined>(undefined, Validators.required),
      fileType: fileTypeControl,
      alternateText: this.#fb.control<string | undefined>(undefined),
      title: this.#fb.control<string | undefined>(undefined),
      mediaText: this.#fb.control<string | undefined>(undefined),
      description: this.#fb.control<string | undefined>(undefined),
      filePath: this.#fb.control<string | undefined>(undefined),
      width: this.#fb.control<number | undefined>(dimensions.width),
      height: this.#fb.control<number | undefined>(dimensions.height),
    });
  }

  async addFile(file: File) {
    this.formArray().push(await this.constructFormGroup(file));
  }

  deleteMedia(index: number) {
    this.formArray().removeAt(index);
  }

  async openMediaDetails(index: number) {
    this.#matDialog
      .open<MediaDetailsComponent, IMediaDetailsInputs>(MediaDetailsComponent, {
        data: await this.#constructMediaDetailsInputs(index),
        maxHeight: "95dvh",
      })
      .afterClosed()
      .subscribe((change: { id: number; control: any }) => {
        change && this.changeControl.emit(change);
      });
  }

  async #constructMediaDetailsInputs(index: number): Promise<IMediaDetailsInputs> {
    const file = this.formArray().at(index).controls.file.value;
    const mediaFormGroup = this.formArray().at(index);
    if (file) {
      return await this.#constructMediaDetailsFromFile(file, mediaFormGroup);
    } else {
      const media = this.medias()[index];
      return this.#constructMediaDetailsFromMedia(media, mediaFormGroup);
    }
  }

  async #constructMediaDetailsFromFile(
    file: File,
    mediaFormGroup: FormGroup<IMediaFormGroup>,
  ): Promise<IMediaDetailsInputs> {
    return {
      mediaFormGroup,
      fileName: file!.name,
      fileSizeAndUnit: getFileSize(file!),
      fileType: getFileType(file!.name),
      uploadedAt: new Date().toISOString(),
      uploadedBy: this.authService.getFullName()!,
    };
  }

  #constructMediaDetailsFromMedia(
    media: IUploadedPropertyMediaPackageDetails,
    mediaFormGroup: FormGroup<IMediaFormGroup>,
  ): IMediaDetailsInputs {
    return {
      mediaFormGroup,
      fileName: media.fileName,
      fileSizeAndUnit: `${media.fileSize} ${media.fileSizeUnit}`,
      fileType: media.fileType,
      uploadedAt: media.createdOn ?? media.createdAt,
      uploadedBy: media.createdBy,
      filePath: media.fileUrl ?? media.filePath,
    };
  }

  #subscribeToAddFile() {
    this.fileUploadControl()
      .valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((files: FileList) => {
        // Reset file upload control on successful addition to formArray
        this.fileUploadControl().setValue([], {
          emitEvent: false,
        });
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          this.addFile(file);
        }
      });
  }
}
