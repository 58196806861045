import { Component } from "@angular/core";

@Component({
  selector: "est-welcome-page",
  standalone: true,
  imports: [],
  templateUrl: "./welcome-page.component.html",
  styleUrl: "./welcome-page.component.scss",
})
export class WelcomePageComponent {}
