import { HttpErrorResponse } from "@angular/common/http";
import { Component, DestroyRef, effect, inject, input, signal, WritableSignal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PropertyType } from "@root/data/market/properties/enums/property-type.enum";
import { IFavoritePropertyResponse } from "@root/data/market/properties/models/favorite-property-response.model";
import { PropertiesService } from "@root/data/market/properties/services/properties.service";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { PropertiesCommunicationService } from "@root/shared/abstracts/base-properties-table/properties-communication.service";
import {
  portfolioPredefinedOptions,
  propertyTypeOptions,
  propertyTypePreDefinedOptions,
} from "@root/shared/dropdown-options/properties.dropdown-options";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { ILargeListTableInput } from "@root/shared/interfaces/large-list-table-input.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { ITableAction } from "@root/shared/interfaces/table-action.interface";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { propertiesHeaders } from "@root/shared/table-headers/properties.headers";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { constructInitialSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";

@Component({
  template: "",
})
export abstract class BasePropertiesTableComponent extends BasePaginatedTableWithSearchComponent {
  override paginatedData: ISignalPaginatedResponse<ILargeListTableInput> = constructInitialSignalPaginatedResponse();
  companyId = input<number>();
  dialog = inject(MatDialog);
  protected readonly propertyTypeOptions = propertyTypeOptions;
  protected readonly propertyTypePreDefinedOptions = propertyTypePreDefinedOptions;
  protected portfolioPredefinedOptions = portfolioPredefinedOptions;
  protected cachedTotalProperties?: number;
  readonly #propertiesCommunicationService = inject(PropertiesCommunicationService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly propertiesService = inject(PropertiesService);
  private readonly snackbarService = inject(SnackbarService);
  readonly #router = inject(Router);
  headers = propertiesHeaders.map((header) => {
    if (header.key === "name") {
      return {
        ...header,
        routerLink: (id: number) => this.routToProperty(id),
      };
    }
    return header;
  });

  routToProperty(id: number) {
    this.#router.navigate([`properties/${id}/details/main-details`]);
  }
  sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "name",
    sortDescending: false,
  });
  propertyTypeSignal = signal<PropertyType>(PropertyType.All);
  isFavouriteSignal = signal(false);
  readonly loadDataEffect$ = effect(() => {
    this.loadDataWithCorrectParams();
  });
  override actions: ITableAction[] = [
    {
      labelFn: () => "PROPERTIES.GO_TO_PROPERTY",
      callbackFn: (row: ILargeListTableInput) => {
        this.#router.navigate(["/properties", row.id]);
      },
    },
    {
      labelFn: () => "PROPERTIES.EDIT_PROPERTY",
      callbackFn: () => {},
    },
    {
      labelFn: () => "PROPERTIES.DELETE_PROPERTY.BUTTON",
      callbackFn: (row: ILargeListTableInput) => this.openDeletePropertyDialog(row),
    },
  ];
  readonly #dialogService = inject(DialogService);
  readonly #isDeletingProperty = signal(false);
  readonly #propertiesReloadSubscription$ = this.#propertiesCommunicationService.reloadPropertiesPage$
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(() => {
      this.loadDataWithCorrectParams();
    });

  abstract openCreatePropertyModal(): void;

  abstract override loadData(params: IPaginationSortPayload): void;

  openDeletePropertyDialog(row: ISmallListTableInput): void {
    this.#dialogService.open(
      {
        title: "PROPERTIES.DELETE_PROPERTY.TITLE",
        tooltipLabel: "PROPERTIES.DELETE_PROPERTY.TOOLTIP",
        callBack: () => this.#deleteProperty(row.id),
        submitLabel: "DELETE",
        isInputIncluded: false,
        descriptions: ["PROPERTIES.DELETE_PROPERTY.QUESTION", "PROPERTIES.DELETE_PROPERTY.DELETE_CONFIRMATION"],
        isSubmitLoading: this.#isDeletingProperty,
        cancelLabel: "CANCEL",
      },
      "confirmation",
    );
  }

  favoriteProperty(propertyId: number): void {
    this.propertiesService.favoriteProperty(propertyId, this.companyId()).subscribe({
      next: (res: IFavoritePropertyResponse) => {
        this.updateFavoritePropertyUi(res.id, res.isFavourite);
        this.updatePortfolioPredefinedOptions(this.cachedTotalProperties!, res.totalFavourites);
      },
      error: (err: HttpErrorResponse) => {
        err.status === 0 && this.snackbarService.open(SnackbarType.Error, "ERRORS.OFFLINE");
        this.undoFavoriteActionOnProperty(propertyId);
      },
    });
  }

  abstract loadDataWithCorrectParams(): void;

  protected updatePortfolioPredefinedOptions(allNo: number, favoritesNo: number): void {
    this.portfolioPredefinedOptions = [
      {
        label: `FAVOURITES (${favoritesNo})`,
        value: true,
        translateKey: "FAVOURITES",
      },
      {
        label: `PROPERTIES.ALL_PROPERTIES (${allNo})`,
        value: false,
        translateKey: "PROPERTIES.ALL_PROPERTIES",
      },
    ];
  }

  #deleteProperty(id: number): void {
    this.#isDeletingProperty.set(true);
    this.propertiesService.deleteProperty(id).subscribe({
      next: () => this.loadDataWithCorrectParams(),
      complete: () => {
        this.#isDeletingProperty.set(false);
        this.#dialogService.close();
      },
    });
  }

  private updateFavoritePropertyUi(propertyId: number, isFavourite: boolean): void {
    // This is just a double check that the UI matches the actual data in the db
    const property = this.getPropertyById(propertyId);
    if (property) property.isFavourite = isFavourite;
  }

  private getPropertyById(propertyId: number): ILargeListTableInput | undefined {
    return this.paginatedData.results().find((property) => property.id === propertyId);
  }

  private undoFavoriteActionOnProperty(propertyId: number): void {
    const property = this.getPropertyById(propertyId);
    if (!property) return;
    property.isFavourite = !property.isFavourite;
  }
}
