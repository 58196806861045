import { Component, inject, OnInit } from "@angular/core";
import { BasePetTypeFormComponent } from "@root/shared/abstracts/base-pet-type-form/base-pet-type-form/base-pet-type-form.component";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { TranslateModule } from "@ngx-translate/core";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { PostfixTextInputComponent } from "@root/shared/postfix-text-input/postfix-text-input.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { PropertiesService } from "@root/data/market/properties/services/properties.service";
import { BasePetTypeFormControls } from "@root/shared/abstracts/base-pet-type-form/base-pet-type-form/base-pet-type.form-controls";
import { PropertiesCommunicationService } from "@root/shared/abstracts/base-properties-table/properties-communication.service";
import { Observable } from "rxjs/internal/Observable";
import { finalize } from "rxjs/internal/operators/finalize";

@Component({
  selector: "est-add-property-pet-type",
  standalone: true,
  templateUrl:
    "../../../../../../shared/abstracts/base-pet-type-form/base-pet-type-form/base-pet-type-form.component.html",
  imports: [
    DialogHeaderComponent,
    TranslateModule,
    SelectFieldComponent,
    TooltipComponent,
    MatSlideToggle,
    PostfixTextInputComponent,
    ButtonComponent,
    ReactiveFormsModule,
    SelectSearchFieldComponent,
    FormsModule,
  ],
  providers: [BasePetTypeFormControls],
})
export class AddPropertyPetTypeComponent extends BasePetTypeFormComponent implements OnInit {
  isEdit = false;
  readonly #propertiesService = inject(PropertiesService);
  readonly #propertiesCommunicationService = inject(PropertiesCommunicationService);

  readonly #updateNotAllowedSpeciesOptions$ = this.formControls.petTypeId.valueChanges
    .pipe(takeUntilDestroyed())
    .subscribe((petTypeId) => {
      if (!petTypeId) return;
      this.formControls.notAllowedSpeciesIds.setValue([]);
      this.loadPetSpeciesOptions(petTypeId);
    });

  override ngOnInit() {
    super.ngOnInit();
    this.#loadPetTypes();
    this.#loadAssignedPetTypesIds();
  }

  getSubmitFormServiceCall(): Observable<void> {
    return this.#propertiesService
      .addPropertyPetTypePolicy(
        this.petTypeFormControls.formGroup.value,
        this.componentInputs.propertyId,
        this.componentInputs.petPolicyId!,
      )
      .pipe(
        finalize(() => {
          this.#propertiesCommunicationService.reloadTermsAndConditionsPage$.next();
        }),
      );
  }

  #loadPetTypes(): void {
    this.petsService.getAllPetTypes().subscribe((petTypes) => {
      this.allowedPetTypesOptions = petTypes;
    });
  }

  #loadAssignedPetTypesIds(): void {
    this.#propertiesService
      .getPropertyPetTypesAssignedIds(this.componentInputs.propertyId, this.componentInputs.petPolicyId!)
      .subscribe((ids) => {
        this.assignedPetTypesIds = ids;
      });
  }
}
