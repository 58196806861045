import { map, OperatorFunction } from "rxjs";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { ITimezone } from "@root/data/market/timezones/models/timezone.model";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ITimezoneDropdownOption } from "@root/views/main/organization/organization-settings/components/system-configurations/components/general/timezone-dropdown-option.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { signal } from "@angular/core";

export const mapPaginatedSystemTimezonesToDropdownOption = (): OperatorFunction<
  IPaginatedResponse<ITimezone>,
  ISignalPaginatedResponse<ITimezoneDropdownOption>
> => {
  return map((paginatedResponse) => ({
    ...mapToSignalPaginatedResponse(paginatedResponse),
    results: signal(mapSystemTimezonesToDropdownOption(paginatedResponse.results)),
  }));
};

export const mapSystemTimezonesToDropdownOption = (timezones: ITimezone[]): ITimezoneDropdownOption[] => {
  return timezones.map((timezone) => ({
    label: timezone.displayName,
    value: timezone.id,
    id: timezone.id,
    displayName: timezone.displayName,
    utcOffset: timezone.utcOffset,
  }));
};
