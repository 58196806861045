<div class="w-[38.5rem] max-lg:w-[32rem]">
  <est-input
    [control]="getControlFromAddressFormGroup(formGroup(), 'countryId')"
    label="COUNTRY"
    appliedClass="w-[18.75rem]  mb-4"
  ></est-input>
</div>
<div class="flex gap-4 flex-wrap mb-4">
  <est-input
    [control]="getControlFromAddressFormGroup(formGroup(), 'streetName')"
    appliedClass="w-[18.75rem]"
    label="ADDRESS.STREET"
  ></est-input>
  <est-input
    [control]="getControlFromAddressFormGroup(formGroup(), 'houseNumber')"
    appliedClass="w-[9.375rem]"
    label="NUMBER"
  ></est-input>
  @if (showFloor()) {
    <est-select-field
      [control]="getControlFromAddressFormGroup(formGroup(), 'floor')"
      [dropDownOptions]="lookups().floors"
      [selectMultipleOptions]="false"
      (changeSelectValue)="selectedValueChange.emit({ name: 'floor', value: $event })"
      appliedClass="w-[9.375rem]"
      dropDownLabel="ADDRESS.FLOOR"
    ></est-select-field>
  }

  @if (showSide()) {
    <est-select-field
      [control]="getControlFromAddressFormGroup(formGroup(), 'side')"
      dropDownLabel="ADDRESS.SIDE"
      [dropDownOptions]="sideOptions"
      appliedClass="w-[9.375rem]"
      [selectMultipleOptions]="false"
      (changeSelectValue)="selectedValueChange.emit({ name: 'side', value: $event })"
    ></est-select-field>
  }
  <est-select-field
    [control]="getControlFromAddressFormGroup(formGroup(), 'door')"
    appliedClass="w-[9.375rem]"
    dropDownLabel="ADDRESS.DOOR"
    [selectMultipleOptions]="false"
    [dropDownOptions]="doorOptions"
    (changeSelectValue)="selectedValueChange.emit({ name: 'door', value: $event })"
  ></est-select-field>
</div>
<div class="flex gap-4 mb-4">
  <est-input
    [control]="getControlFromAddressFormGroup(formGroup(), 'zipCode')"
    appliedClass="w-[9.375rem]"
    label="ADDRESS.ZIP_CODE"
    type="number"
  ></est-input>
  <est-input
    [control]="getControlFromAddressFormGroup(formGroup(), 'city')"
    appliedClass="w-[18.75rem]"
    label="ADDRESS.CITY"
  ></est-input>
</div>
