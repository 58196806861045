import { Component, inject, input, output } from "@angular/core";
import { MatStepper } from "@angular/material/stepper";
import { ButtonComponent } from "@root/shared/button/button.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "est-dq-stepper-action-buttons",
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: "./dq-stepper-action-buttons.component.html",
  styleUrl: "./dq-stepper-action-buttons.component.scss",
})
export class DemographicQuestionsStepperActionButtonsComponent {
  stepper = input.required<MatStepper>();
  isLoading = input.required<boolean>();
  submitLabel = input.required<string>();
  stepWithConfirmButton = input<number>();

  onSubmit = output();
  onNext = output();

  readonly matDialog = inject(MatDialog);

  showNextButton(): boolean {
    return (
      this.stepper().selectedIndex !== this.stepWithConfirmButton() ||
      this.stepper().selectedIndex < this.stepper().steps.length - 1
    );
  }
}
