import { inject, Injectable } from "@angular/core";
import { UnitFacilitiesRepository } from "@root/data/market/units/repositories/unit-facilities.repository";
import { Observable } from "rxjs";
import {
  mapUnitAssignedFacilitiesToSignalSmallListInputOperator,
  mapUnitFacilityToSignalSmallListInputOperator,
  mapUnitFacilityToSignalUnitFacilityLookupOperator,
  mapUnitPropertyFacilitiesToSmallListInputOperator,
} from "@root/data/market/units/utilities/unit-facility.utilities";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { IUnitFacilityLookups } from "@root/shared/interfaces/unit-facility-lookups.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { IUnitOtherFacilities } from "@root/data/market/units/models/unit-other-facilities.model";
import { IUnitFacilityLookup } from "@root/data/market/units/models/unit-facility-lookup.model";
import { IPaginationPayload } from "@root/shared/interfaces/pagination-payload.interface";
import { IUnitAssignedFacility } from "@root/data/market/units/models/unit-assigned-facility.model";
import { ISignalPaginatedSelectedResponse } from "@root/shared/interfaces/signal-paginated-selected-response.interface";

@Injectable({
  providedIn: "root",
})
export class UnitFacilitiesService {
  readonly #unitFacilityRepository = inject(UnitFacilitiesRepository);

  getUnitAssignedFacility(unitId: string, facilityId: number): Observable<IUnitAssignedFacility> {
    return this.#unitFacilityRepository.getUnitAssignedFacility(unitId, facilityId);
  }

  getUnitFacilityList(
    params: IUnitFacilityLookups,
  ): Observable<ISignalPaginatedSelectedResponse<ISmallListTableInput>> {
    return this.#unitFacilityRepository.getUnitFacilities(params).pipe(mapUnitFacilityToSignalSmallListInputOperator());
  }

  getUnitFacilitiesLookups(params: IPaginationPayload): Observable<ISignalPaginatedResponse<IUnitFacilityLookup>> {
    return this.#unitFacilityRepository
      .getUnitFacilities(params)
      .pipe(mapUnitFacilityToSignalUnitFacilityLookupOperator());
  }

  getUnitAssignedFacilities(
    unitId: string,
    params: Omit<IPaginationSortPayload, "search">,
  ): Observable<ISignalPaginatedResponse<ISmallListTableInput>> {
    return this.#unitFacilityRepository
      .getUnitAssignedFacilities(unitId, params)
      .pipe(mapUnitAssignedFacilitiesToSignalSmallListInputOperator());
  }

  getUnitAssignedFacilitiesIds(unitId: string): Observable<number[]> {
    return this.#unitFacilityRepository.getUnitAssignedFacilitiesIds(unitId);
  }

  assignUnitFacility(unitId: string, unitFacilitiesIds: number[]): Observable<void> {
    return this.#unitFacilityRepository.assignUnitFacility(unitId, unitFacilitiesIds);
  }

  toggleUnitFacility(unitId: string, facilityId: number): Observable<{ isActive: boolean }> {
    return this.#unitFacilityRepository.toggleUnitFacility(unitId, facilityId);
  }

  toggleUnitPropertyFacility(unitId: string, facilityId: number): Observable<{ isActive: boolean }> {
    return this.#unitFacilityRepository.toggleUnitPropertyFacility(unitId, facilityId);
  }

  getUnitPropertyAssignedFacilities(
    unitId: string,
    params: Omit<IPaginationSortPayload, "search">,
  ): Observable<ISignalPaginatedResponse<ISmallListTableInput>> {
    return this.#unitFacilityRepository
      .getUnitPropertyAssignedFacilities(unitId, params)
      .pipe(mapUnitPropertyFacilitiesToSmallListInputOperator());
  }

  getUnitOtherFacilities(unitId: string): Observable<IUnitOtherFacilities> {
    return this.#unitFacilityRepository.getUnitOtherFacilities(unitId);
  }

  updateUnitOtherFacilities(body: { [key: string]: string }, unitId: string, facilityId: number): Observable<void> {
    return this.#unitFacilityRepository.updateUnitOtherFacilities(body, unitId, facilityId);
  }

  updateUnitAssignedFacility(body: { [key: string]: any }, unitId: string, facilityId: number): Observable<void> {
    return this.#unitFacilityRepository.updateUnitAssignedFacility(body, unitId, facilityId);
  }

  deleteUnitAssignedFacility(unitId: string, facilityId: number): Observable<void> {
    return this.#unitFacilityRepository.deleteUnitAssignedFacility(unitId, facilityId);
  }
}
