import { Component, effect, ElementRef, signal, ViewChild } from "@angular/core";
import { BaseTableComponent } from "@root/shared/abstracts/base-table/base-table.component";
import {
  isElementScrolledToTheTop,
  isElementScrolledToTheBottom,
} from "@root/shared/utilities/element-poistion.utilities";
/*
  @description Provides Functionality for client-side infinite scrolling
  @notes Provde #scrollableDiv selector on the div wrapping the table in your template
*/
@Component({
  standalone: true,
  template: "",
})
export abstract class BaseInfniniteScrollingTableComponent extends BaseTableComponent {
  @ViewChild("scrollableDiv") scrollableDiv!: ElementRef<HTMLDivElement>;
  slicedData: { [key: string]: string }[] = [];
  topIndex!: number;
  bottomIndex!: number;
  readonly scrollSlack: number = 15;
  readonly bufferSize = 35;
  readonly isSlicing = signal(false);

  #setSlicedDataEffect = effect(() => {
    this.topIndex = 0;
    this.bottomIndex = this.bufferSize;
    this.slicedData = this.data().slice(this.topIndex, this.bottomIndex + 1);
  });

  sliceItems() {
    if (isElementScrolledToTheTop(this.scrollableDiv.nativeElement, 150)) {
      this.sliceItemsTop();
    } else if (isElementScrolledToTheBottom(this.scrollableDiv.nativeElement, 150)) {
      this.sliceItemsBottom();
    }
  }

  sliceItemsTop() {
    const newT = Math.max(0, this.topIndex - this.bufferSize);
    const newB = Math.max(this.bufferSize, this.bottomIndex - this.bufferSize);
    if (newT === this.topIndex && newB === this.bottomIndex) return;
    this.isSlicing.set(true);
    setTimeout(() => {
      this.topIndex = newT;
      this.bottomIndex = newB;
      this.slicedData = this.data().slice(this.topIndex, this.bottomIndex + 1);
      this.isSlicing.set(false);
    });
  }

  sliceItemsBottom() {
    const newT = Math.min(this.data().length - this.bufferSize, this.bottomIndex - this.scrollSlack);
    const newB = Math.min(this.data().length, this.bottomIndex + this.bufferSize);
    if (newT === this.topIndex && newB === this.bottomIndex) return;
    this.isSlicing.set(true);
    setTimeout(() => {
      this.topIndex = newT;
      this.bottomIndex = newB;
      this.slicedData = this.data().slice(this.topIndex, this.bottomIndex + 1);
      this.isSlicing.set(false);
    });
  }
}
