import { Component, computed, effect, inject, input } from "@angular/core";
import { FormArray, FormGroup, NonNullableFormBuilder, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { FormatTimePipe } from "@root/core/pipes/format-time.pipe";
import { PostfixTextInputComponent } from "@root/shared/postfix-text-input/postfix-text-input.component";
import { ITimeslotFormGroup } from "@root/shared/timeslots/timeslot-form-group.interface";
import { ITimeslot } from "@root/shared/timeslots/timeslot.interface";
import { DateTime } from "luxon";

/*
  @Component
  @Description Lists maximum number of timeslots available based on provided data
  @Remarks This component relies on `existingTimeslots` when filling Data and not the formArray. This can be used when editing to pre-populate existing data.
  Filling the formArray is done inside the component and not from outside
 */
@Component({
  selector: "est-timeslots",
  standalone: true,
  imports: [TranslateModule, PostfixTextInputComponent, FormatTimePipe],
  templateUrl: "./timeslots.component.html",
  styleUrl: "./timeslots.component.scss",
})
export class TimeslotsComponent {
  readonly intervalInMinutes = input.required<number | null>();
  readonly breakInMinutes = input<number>(0);
  readonly startDateIso = input.required<string | null>();
  readonly endDateIso = input.required<string | null>();

  existingTimeslots = input<ITimeslot[] | null>();
  readonly formArray = input<FormArray<FormGroup<ITimeslotFormGroup>>>(
    new FormArray<FormGroup<ITimeslotFormGroup>>([]),
  );

  // Checks if the required input data is present before showing timeslots
  readonly showTimeslots = computed<boolean>(
    () => !!this.intervalInMinutes() && !!this.startDateIso() && !!this.endDateIso(),
  );

  readonly #fb = inject(NonNullableFormBuilder);
  readonly #buildFormArray$ = effect(() => {
    this.#callInputSignals(); // Register the required signals
    if (!this.showTimeslots()) return;
    const numberOfSlots = this.#calculateMaximumNumberOfTimeslots();
    let currentStartTime = DateTime.fromISO(this.startDateIso()!);
    this.formArray().clear();
    for (let i = 0; i < numberOfSlots; i++) {
      this.formArray().push(this.#constructTimeslotFormGroup(currentStartTime, i));
      currentStartTime = currentStartTime.plus({
        minute: this.intervalInMinutes()! + this.breakInMinutes()!,
      });
    }
  });

  get attendeesTotalCount(): number {
    let totalCount = 0;
    this.formArray().value.forEach((group) => {
      if (group.attendants) totalCount += +group.attendants;
    });
    return totalCount;
  }

  #callInputSignals() {
    this.intervalInMinutes();
    this.breakInMinutes();
    this.startDateIso();
    this.endDateIso();
  }

  #calculateMaximumNumberOfTimeslots() {
    if (!this.showTimeslots()) return 0;
    const startDateTime = DateTime.fromISO(this.startDateIso()!);
    const endDateTime = DateTime.fromISO(this.endDateIso()!);
    const differenceInMinutes = endDateTime.diff(startDateTime, ["minute"]).minutes;
    // Equation for calculating number of timeslots: (Minutes + Break) / (Interval + Break)
    return Math.floor(
      (differenceInMinutes + this.breakInMinutes()) / (this.intervalInMinutes()! + this.breakInMinutes()),
    );
  }

  #constructTimeslotFormGroup(startTime: DateTime, currentIndex: number): FormGroup<ITimeslotFormGroup> {
    return this.#fb.group<ITimeslotFormGroup>({
      startTime: this.#fb.control(startTime.toISO()!),
      attendants: this.#fb.control(this.existingTimeslots()?.[currentIndex]?.attendants ?? 0, [Validators.max(10000)]),
      endTime: this.#fb.control(
        startTime
          .plus({
            minute: this.intervalInMinutes()!,
          })
          .toISO()!,
      ),
    });
  }
}
