<div class="sticky top-0 z-10">
  <est-dialog-header
    [label]="
      componentInputs.isEditPopup
        ? 'SETTINGS.SYSTEM_CONFIGURATIONS.NOTE_CATEGORIES.EDIT.TITLE'
        : 'SETTINGS.SYSTEM_CONFIGURATIONS.NOTE_CATEGORIES.CREATE.TITLE'
    "
    [tooltipLabel]="
      componentInputs.isEditPopup
        ? 'SETTINGS.SYSTEM_CONFIGURATIONS.NOTE_CATEGORIES.EDIT.TITLE'
        : 'SETTINGS.SYSTEM_CONFIGURATIONS.NOTE_CATEGORIES.CREATE.TITLE'
    "
  ></est-dialog-header>
</div>
<div class="p-6">
  @if (isPageLoading) {
    <est-loading-overlay></est-loading-overlay>
  } @else {
    <est-small-header
      [heading]="
        componentInputs.isEditPopup
          ? 'SETTINGS.SYSTEM_CONFIGURATIONS.NOTE_CATEGORIES.EDIT.NAME'
          : 'SETTINGS.SYSTEM_CONFIGURATIONS.NOTE_CATEGORIES.CREATE.NAME'
      "
      [subheading]="
        componentInputs.isEditPopup
          ? 'SETTINGS.SYSTEM_CONFIGURATIONS.NOTE_CATEGORIES.EDIT.DESCRIPTION'
          : 'SETTINGS.SYSTEM_CONFIGURATIONS.NOTE_CATEGORIES.CREATE.DESCRIPTION'
      "
    ></est-small-header>
    <div class="flex flex-col gap-4">
      <div class="mt-6 flex flex-col gap-4">
        <est-small-header
          heading="SETTINGS.NAME"
          subheading="SETTINGS.SYSTEM_CONFIGURATIONS.NOTE_CATEGORIES.SETTINGS.GDPR.DESCRIPTION"
        ></est-small-header>
        <mat-slide-toggle
          [checked]="formGroup.controls.gdprProtected.value"
          (change)="formGroup.controls.gdprProtected.setValue($event.checked)"
          class="text-sm font-light"
          >{{ "SETTINGS.SYSTEM_CONFIGURATIONS.NOTE_CATEGORIES.SETTINGS.GDPR.NAME" | translate }}</mat-slide-toggle
        >
      </div>
      <div>
        <p class="text-sm font-light mb-4">
          {{ "SETTINGS.SYSTEM_CONFIGURATIONS.NOTE_CATEGORIES.SETTINGS.ROLES" | translate }}
        </p>
        <div class="flex flex-col gap-4">
          @for (userRole of userRoleOptions; track userRole.value) {
            <div class="flex gap-0.5 items-center text-sm font-light">
              <mat-checkbox
                [disabled]="userRole.label === 'SETTINGS.USERS.SYSTEM_ADMIN'"
                id="inline-checkbox"
                [checked]="formGroup.controls.roleIds.value.includes(userRole.value)"
                (change)="toggleRole(userRole.value, $event)"
              ></mat-checkbox>
              <span>{{ userRole.label | translate }}</span>
            </div>
          }
        </div>
      </div>
    </div>
    <div class="py-6">
      <hr class="border-black-100 absolute start-0 end-0" />
    </div>
    <div>
      <est-small-header heading="COLOUR" tooltip="COLOUR"></est-small-header>
      <est-color-picker-input
        [withLabelHeader]="false"
        label="SETTINGS.SYSTEM_CONFIGURATIONS.NOTE_CATEGORIES.COLOUR"
        [control]="formGroup.controls.colour"
      ></est-color-picker-input>
    </div>
    <div class="py-6">
      <hr class="border-black-100 absolute start-0 end-0" />
    </div>
    <div class="flex flex-col gap-4">
      @for (categoryNameControl of formGroup.controls.noteCategoryNames.controls; track categoryNameControl) {
        <est-external-title-input
          [isDefault]="isDefaultLanguage(categoryNameControl.controls['languageId'].value)"
          label="SETTINGS.SYSTEM_CONFIGURATIONS.NOTE_CATEGORIES.CATEGORY_NAME"
          valueName="name"
          [formGroup]="categoryNameControl"
          [language]="getLanguageFromId(categoryNameControl.controls['languageId'].value)"
        ></est-external-title-input>
      }
    </div>
  }
</div>
<div class="py-3 bg-black-50 flex border border-black-100 px-6 sticky bottom-0 z-10 justify-between">
  <est-button
    [appliedClasses]="'!py-3 !px-6'"
    (click)="closeDialog(true)"
    label="CANCEL"
    buttonStyleType="button--tertiary"
    buttonType="button"
  />
  <est-button
    [appliedClasses]="'!py-3 !px-6'"
    [label]="componentInputs.isEditPopup ? 'SAVE' : 'CREATE'"
    buttonStyleType="button--primary"
    buttonType="button"
    [isLoading]="isFormSubmitting"
    (click)="submitForm()"
  />
</div>
