<div class="py-3 justify-between bg-black-50 flex border border-black-100 px-6 sticky bottom-0 z-10">
  @if (stepper().selectedIndex === 0) {
    <est-button
      label="CANCEL"
      appliedClasses="!py-3 !px-6"
      (click)="matDialog.closeAll()"
      buttonType="button"
      buttonStyleType="button--tertiary"
    >
    </est-button>
  } @else if (stepper().selectedIndex > 0) {
    <est-button
      label="BACK"
      appliedClasses="!py-3 !px-6"
      (click)="stepper().previous()"
      buttonStyleType="button--tertiary"
      buttonType="button"
      [disabled]="isLoading()"
    >
    </est-button>
  }
  <!-- submit or next btns -->
  @if (showNextButton()) {
    <est-button
      label="NEXT"
      appliedClasses="!py-3 !px-6"
      buttonStyleType="button--primary"
      buttonType="button"
      [isLoading]="isLoading()"
      (click)="onNext.emit()"
    >
    </est-button>
  } @else {
    <est-button
      [label]="submitLabel()"
      appliedClasses="!py-3 !px-6"
      buttonStyleType="button--primary"
      buttonType="submit"
      [isLoading]="isLoading()"
      (click)="onSubmit.emit()"
    >
    </est-button>
  }
</div>
