import { Component, inject, OnInit } from "@angular/core";
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { LookupsService } from "@root/data/market/lookups/services/lookups.service";
import { OrganizationService } from "@root/data/market/organisation/services/organisation.service";
import { AddressComponent } from "@root/shared/address/address.component";
import { REGEX_PATTERNS } from "@root/shared/constants/regex-patterns.constants";
import { InputComponent } from "@root/shared/input/input.component";
import { IAddressLookUp } from "@root/shared/interfaces/address-look-up.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { IPhoneNumber } from "@root/shared/interfaces/phone-number.interface";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { constructInitialAddressLookups } from "@root/shared/utilities/address.utilities";
import {
  arePhoneControlsDirty,
  constructAddressFormGroup,
  getControlInFormGroup,
  markAllControlsAsTouchedAndDirty,
  markControlAsTouchedAndDirty,
  updateNestedControlsPathAndValue,
} from "@root/shared/utilities/form.utilities";
import { constructInitialPhoneNumberValue } from "@root/shared/utilities/phone-number.utilities";
import { finalize } from "rxjs/internal/operators/finalize";

@Component({
  selector: "est-communication",
  standalone: true,
  imports: [
    MatSlideToggle,
    TranslateModule,
    ReactiveFormsModule,
    AddressComponent,
    InputComponent,
    PhoneInputComponent,
    LoadingOverlayComponent,
  ],
  templateUrl: "./communication.component.html",
  styleUrl: "./communication.component.scss",
})
export class CommunicationComponent implements OnInit {
  readonly #fb = inject(NonNullableFormBuilder);
  readonly #lookUpsService = inject(LookupsService);
  readonly #organisationService = inject(OrganizationService);

  isPageLoading = false;
  addressLookups: IAddressLookUp<IDropdownOption> = constructInitialAddressLookups();

  createdAtControl = this.#fb.control("");
  updatedAtControl = this.#fb.control("");
  createdByUserControl = this.#fb.control("");
  updatedByUserControl = this.#fb.control("");
  useDivisionDetailsControl = this.#fb.control<boolean>(false);
  contactPersonNameControl = this.#fb.control("");
  contactPersonEmailControl = this.#fb.control("", Validators.pattern(REGEX_PATTERNS.email));
  contactPersonIsdCodeControl = this.#fb.control("");
  contactPersonPhoneNumberControl = this.#fb.control("");
  addressFormGroup = constructAddressFormGroup(this.#fb, true, false);
  phoneControl = this.#fb.control<IPhoneNumber>(constructInitialPhoneNumberValue(), Validators.required);

  communicationContactDetailsFG = this.#fb.group({
    useDivisionDetails: this.useDivisionDetailsControl,
    addressInformation: this.addressFormGroup,
    contactPersonName: this.contactPersonNameControl,
    contactPersonEmail: this.contactPersonEmailControl,
    contactPersonPhoneNo: this.contactPersonPhoneNumberControl,
    contactPersonIsdCode: this.contactPersonIsdCodeControl,
    createdByUser: this.createdByUserControl,
    updatedByUser: this.updatedByUserControl,
    createdAt: this.createdAtControl,
    updatedAt: this.updatedAtControl,
  });

  ngOnInit(): void {
    this.#getAddressLookUps();
    this.#getCommunicationContactDetails();
  }

  patchCommunicationContactDetails() {
    if (arePhoneControlsDirty(this.phoneControl, this.communicationContactDetailsFG) && this.phoneControl.valid) {
      this.#mapPhoneControlToDetails();
      this.#markPhoneControlsInParentAsTouchedAndDirty();
    }
    const controlPaths = updateNestedControlsPathAndValue(this.communicationContactDetailsFG);

    if (Object.keys(controlPaths).length) {
      this.#organisationService.patchOrganisationCommunication(controlPaths).subscribe();
    }
  }

  patchUseDivisionDetails(useDivisionDetails: boolean) {
    this.communicationContactDetailsFG.controls.useDivisionDetails.setValue(useDivisionDetails);
    this.patchCommunicationContactDetails();
  }

  patchAddressFields(changedField: { name: string; value: number | string }): void {
    if (changedField.name === "vat") {
      markAllControlsAsTouchedAndDirty(this.addressFormGroup);
      this.patchCommunicationContactDetails();
      return;
    }
    markControlAsTouchedAndDirty(getControlInFormGroup(this.addressFormGroup, changedField.name));
    this.patchCommunicationContactDetails();
  }

  #getCommunicationContactDetails() {
    this.isPageLoading = true;
    this.#organisationService
      .getOrganisationCommunication()
      .pipe(finalize(() => (this.isPageLoading = false)))
      .subscribe({
        next: (response) => {
          this.communicationContactDetailsFG.patchValue(response);
          this.phoneControl.patchValue({
            internationalNumber: response.contactPersonPhoneNo,
            number: response.contactPersonPhoneNo,
            countryCode: response.contactPersonIsdCode,
          });
        },
      });
  }
  #getAddressLookUps() {
    this.#lookUpsService.getAddressLookupOptions().subscribe((lookUps) => (this.addressLookups = lookUps));
  }
  #markPhoneControlsInParentAsTouchedAndDirty(): void {
    markControlAsTouchedAndDirty(this.contactPersonPhoneNumberControl);
    markControlAsTouchedAndDirty(this.contactPersonIsdCodeControl);
  }
  #mapPhoneControlToDetails(): void {
    this.communicationContactDetailsFG.patchValue({
      contactPersonPhoneNo: this.phoneControl.value?.number,
      contactPersonIsdCode: this.phoneControl.value?.countryCode,
    });
  }
}
