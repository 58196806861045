<div class="bg-white rounded-[0.625rem] p-6 r-screen-[25rem] relative">
  <est-subheader
    [date]="'Mon Feb 19 2024 14:20:06 GMT+0200'"
    name="Jesper Thorkildsen"
    label="PROPERTIES.MAIN_DETAILS.TITLE"
    tooltip="PROPERTIES.MAIN_DETAILS.TITLE"
  ></est-subheader>
  @if (isLoading) {
    <est-loading-overlay appliedClasses="!bg-white/60 "></est-loading-overlay>
  } @else {
    <form [formGroup]="mainDetailsFormControls.mainDetailsForm" (change)="updateUnitDetails()">
      <div class="pt-10 pb-6">
        <div class="flex gap-2 items-center mb-4">
          <p class="font-semibold">
            {{ "PROPERTIES.MAIN_DETAILS.UNIT_IDENTIFICATION" | translate }}
          </p>
          <est-tooltip tooltip="PROPERTIES.MAIN_DETAILS.UNIT_IDENTIFICATION"></est-tooltip>
        </div>
        <est-input
          appliedClass="mr-4 w-[18.75rem] "
          [control]="mainDetailsFormControls.id"
          label="PROPERTIES.MAIN_DETAILS.ESTATETOOL_SYSTEM_ID"
          aria-disabled="true"
        ></est-input>
        <div class="flex flex-wrap my-4">
          <est-input
            appliedClass="mr-4 w-[18.75rem] max-breakpoint-small-desktop-screen:mb-4"
            [control]="mainDetailsFormControls.customUnitId1"
            [label]="getUnitIdNumberTranslation('PROPERTIES.MAIN_DETAILS.UNIT_ID', 1)"
          ></est-input>
          @if (!hiddenFields.includes(hiddenFieldsEnum.UnitId2)) {
            <est-input
              appliedClass="mr-4 w-[18.75rem] max-breakpoint-small-desktop-screen:mb-4 "
              [control]="mainDetailsFormControls.customUnitId2"
              [label]="getUnitIdNumberTranslation('PROPERTIES.MAIN_DETAILS.UNIT_ID', 2)"
            ></est-input>
          }

          @if (!hiddenFields.includes(hiddenFieldsEnum.UnitId3)) {
            <est-input
              appliedClass="mr-4 w-[18.75rem] "
              [control]="mainDetailsFormControls.customUnitId3"
              [label]="getUnitIdNumberTranslation('PROPERTIES.MAIN_DETAILS.UNIT_ID', 3)"
            ></est-input>
          }
        </div>

        <div class="flex flex-wrap">
          <est-input
            appliedClass="mr-4 w-[18.75rem] max-breakpoint-small-desktop-screen:mb-4 "
            [control]="mainDetailsFormControls.caseId"
            label="PROPERTIES.MAIN_DETAILS.CASE_ID"
          ></est-input>
          <est-input
            appliedClass="mr-4 w-[18.75rem] "
            [control]="mainDetailsFormControls.mindworkingId"
            label="PROPERTIES.MAIN_DETAILS.MINDWORKING_ID"
          ></est-input>
        </div>
      </div>
      <hr class="border-black-100 absolute start-0 end-0" />

      <!-- Availability -->
      <div class="py-6">
        <div class="flex gap-2 items-center mb-4">
          <p class="font-semibold">
            {{ "PROPERTIES.MAIN_DETAILS.AVAILABILITY" | translate }}
          </p>
          <est-tooltip tooltip="PROPERTIES.MAIN_DETAILS.AVAILABILITY"></est-tooltip>
        </div>
        <div class="flex flex-col">
          @if (!hiddenFields.includes(hiddenFieldsEnum.DateOfAvailability)) {
            <est-datepicker
              label="PROPERTIES.MAIN_DETAILS.DATE_OF_AVAILABILITY"
              [control]="mainDetailsFormControls.vacancyDate"
              appliedClasses="w-[18.75rem]"
              (valueChange)="updateControlAndPatchNewValue($event, 'vacancyDate')"
            >
            </est-datepicker>
          }
          <mat-slide-toggle
            [formControl]="mainDetailsFormControls.isUnderRenovation"
            class="mb-6 mt-4"
            (change)="checkedUnitUnderRenovation($event.checked)"
            >{{ "PROPERTIES.MAIN_DETAILS.UNIT_UNDER_RENOVATION" | translate }}
          </mat-slide-toggle>
        </div>
        <est-datepicker
          label="PROPERTIES.MAIN_DETAILS.RENOVATION_START_DATE"
          [control]="mainDetailsFormControls.renovationStartDate"
          appliedClasses="w-[18.75rem] mr-4"
          (valueChange)="updateControlAndPatchNewValue($event, 'renovationStartDate')"
        >
        </est-datepicker>
        @if (!hiddenFields.includes(hiddenFieldsEnum.RenovationEndDate)) {
          <est-datepicker
            (valueChange)="updateControlAndPatchNewValue($event, 'renovationEndDate')"
            label="PROPERTIES.MAIN_DETAILS.RENOVATION_END_DATE"
            [control]="mainDetailsFormControls.renovationEndDate"
            appliedClasses="w-[18.75rem]"
          >
          </est-datepicker>
        }
        @if (mainDetailsFormControls.mainDetailsForm.controls.unitAvailability.hasError("endDateBeforeStartDate")) {
          <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
            <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
            {{ "VALIDATION.START_END_DATE_VALIDATION" | translate }}
          </mat-error>
        }
      </div>

      <hr class="border-black-100 absolute start-0 end-0" />

      <!-- UNITY SPECIFICATION -->
      <div class="py-6">
        <div class="flex gap-2 items-center mb-4">
          <p class="font-semibold">
            {{ "PROPERTIES.MAIN_DETAILS.UNIT_SPECIFICATION" | translate }}
          </p>
          <est-tooltip tooltip="PROPERTIES.MAIN_DETAILS.UNIT_SPECIFICATION"></est-tooltip>
        </div>
        <div class="flex flex-wrap my-4">
          <est-select-field
            [control]="mainDetailsFormControls.type"
            dropDownLabel="PROPERTIES.MAIN_DETAILS.UNIT_TYPE"
            [dropDownOptions]="unitTypesLookupOptions"
            [selectMultipleOptions]="false"
            appliedClass="mr-4 !w-[18.75rem] max-breakpoint-small-desktop-screen:mb-4"
            (changeSelectValue)="updateControlAndPatchNewValue($event, 'type')"
          >
          </est-select-field>
          <est-select-field
            [control]="mainDetailsFormControls.housingType"
            dropDownLabel="PROPERTIES.MAIN_DETAILS.HOUSING_TYPE"
            [dropDownOptions]="housingTypeLookupOptions"
            [selectMultipleOptions]="false"
            appliedClass="mr-4 !w-[18.75rem]"
            (changeSelectValue)="updateControlAndPatchNewValue($event, 'housingType')"
          >
          </est-select-field>
          <est-select-field
            [control]="mainDetailsFormControls.listingType"
            dropDownLabel="PROPERTIES.MAIN_DETAILS.SALES_TYPE"
            [dropDownOptions]="unitSalesTypesLookupOptions"
            [selectMultipleOptions]="false"
            appliedClass="mr-4 !w-[18.75rem]"
            (changeSelectValue)="updateControlAndPatchNewValue($event, 'listingType')"
          >
          </est-select-field>
        </div>
        <div class="flex flex-wrap">
          <est-select-field
            [control]="mainDetailsFormControls.stage"
            dropDownLabel="PROPERTIES.MAIN_DETAILS.STAGE"
            [dropDownOptions]="[]"
            [selectMultipleOptions]="false"
            appliedClass="mr-4 !w-[18.75rem] max-breakpoint-small-desktop-screen:mb-4"
            (changeSelectValue)="updateControlAndPatchNewValue($event, 'stage')"
          >
          </est-select-field>
          <est-select-field
            [control]="mainDetailsFormControls.floorPlanType"
            dropDownLabel="PROPERTIES.MAIN_DETAILS.FLOOR_PLAN"
            [dropDownOptions]="[]"
            [selectMultipleOptions]="false"
            appliedClass="mr-4 !w-[18.75rem]"
            (changeSelectValue)="updateControlAndPatchNewValue($event, 'floorPlanType')"
          >
          </est-select-field>
          <est-input
            appliedClass="mr-4 w-[9.38rem]"
            [control]="mainDetailsFormControls.constructionYear"
            label="PROPERTIES.MAIN_DETAILS.CONSTRUCTION_YEAR"
          ></est-input>
          <mat-slide-toggle [formControl]="mainDetailsFormControls.sameYearAsProperty" class="mb-6 mt-1.5"
            >{{ "PROPERTIES.MAIN_DETAILS.SAME_YEAR_AS_PROPERTY" | translate }}
          </mat-slide-toggle>
        </div>
      </div>
      <hr class="border-black-100 absolute start-0 end-0" />

      <!-- UNIT ADDRESS -->
      <div class="py-6">
        <div class="flex gap-2 items-center mb-4">
          <p class="font-semibold">
            {{ "PROPERTIES.MAIN_DETAILS.UNIT_ADDRESS" | translate }}
          </p>
          <est-tooltip tooltip="PROPERTIES.MAIN_DETAILS.UNIT_ADDRESS"></est-tooltip>
        </div>

        <est-static-address
          [lookups]="addressLookupsOptions"
          [formGroup]="mainDetailsFormControls.addressInformation"
          (selectedValueChange)="addressSelectChange($event)"
          [showFloor]="showFloor()"
          [showSide]="showSide()"
        ></est-static-address>

        <div class="flex flex-col">
          <p class="text-sm font-semibold mb-4">{{ "PROPERTIES.MAIN_DETAILS.GEOGRAPHIC_LOCATION" | translate }}</p>
          <mat-slide-toggle [formControl]="mainDetailsFormControls.useGeoFromProperty" class="mb-6"
            >{{ "PROPERTIES.MAIN_DETAILS.USE_GEOGRAPHIC_LOCATION" | translate }}
          </mat-slide-toggle>
          <est-geographical-location
            [formattedAddressSignal]="formattedAddressSignal"
            [isLoadingSignal]="isGeographicalLocationLoadingSignal"
            [latitudeControl]="mainDetailsFormControls.latitude"
            [longitudeControl]="mainDetailsFormControls.longitude"
            (isGeoLocationUpdated)="updateDetailsOnUpdateGeoLocation($event)"
          ></est-geographical-location>
        </div>
        @if (!hiddenFields.includes(hiddenFieldsEnum.PlotNumber)) {
          <div>
            <p class="text-sm font-semibold mt-4 mb-2">
              {{ "PROPERTIES.MAIN_DETAILS.PLOT_NUMBER" | translate }}
            </p>
            <est-input
              appliedClass="mr-4 w-[18.75rem] "
              [control]="mainDetailsFormControls.plotNumber"
              label="PROPERTIES.MAIN_DETAILS.PLOT_NUMBER"
            ></est-input>
          </div>
        }
      </div>
      <hr class="border-black-100 absolute start-0 end-0" />

      <!-- PROPERTY MANAGEMENT -->
      @if (
        !hiddenFields.includes(hiddenFieldsEnum.AdministrationStartDate) &&
        !hiddenFields.includes(hiddenFieldsEnum.AdministrationEndDate)
      ) {
        <div class="py-6">
          <div class="flex gap-2 items-center mb-4">
            <p class="font-semibold">
              {{ "PROPERTIES.MAIN_DETAILS.PROPERTY_MANAGEMENT" | translate }}
            </p>
            <est-tooltip tooltip="PROPERTIES.MAIN_DETAILS.PROPERTY_MANAGEMENT"></est-tooltip>
          </div>
          <div class="flex flex-wrap">
            <div>
              <est-datepicker
                (valueChange)="updateControlAndPatchNewValue($event, 'administrationStartDate')"
                label="PROPERTIES.MAIN_DETAILS.ADMIN_START_DATE"
                [control]="mainDetailsFormControls.administrationStartDate"
                appliedClasses="w-[18.75rem] mr-4"
              >
              </est-datepicker>
              <est-datepicker
                (valueChange)="updateControlAndPatchNewValue($event, 'administrationEndDate')"
                label="PROPERTIES.MAIN_DETAILS.ADMIN_END_DATE"
                [control]="mainDetailsFormControls.administrationEndDate"
                appliedClasses="w-[18.75rem] mr-4"
              >
              </est-datepicker>
              @if (
                mainDetailsFormControls.mainDetailsForm.controls.unitPropertyManagement.hasError(
                  "endDateBeforeStartDate"
                )
              ) {
                <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
                  <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
                  {{ "VALIDATION.START_END_DATE_VALIDATION" | translate }}
                </mat-error>
              }
            </div>

            <mat-slide-toggle [formControl]="mainDetailsFormControls.useDatesFromProperty" class="mb-6 mt-1.5"
              >{{ "PROPERTIES.MAIN_DETAILS.USE_DATES_FROM_PROPERTY" | translate }}
            </mat-slide-toggle>
          </div>
        </div>
      }
    </form>
  }
</div>
