import { Component, DestroyRef, inject, input, OnInit, signal } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { ICardAction } from "@root/shared/interfaces/card-Action.interface";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";

import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatDialog } from "@angular/material/dialog";
import { IPropertyMediaPackageOverview } from "@root/data/market/properties/models/property-media-package-overview.interface";
import { IPropertyMediaPackage } from "@root/data/market/properties/models/property-media-package.model";
import { PropertiesMediaPackagesService } from "@root/data/market/properties/services/properties-media-packages.service";
import { CreateLinkDialogComponent } from "@root/shared/create-link-dialog/create-link-dialog.component";
import { EditLinkDialogComponent } from "@root/shared/edit-link-dialog/edit-link-dialog.component";
import { LinkingSource } from "@root/shared/enums/linking-source.enum";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { IDuplicatePackageInput } from "@root/shared/interfaces/duplicate-package-input.interface";
import { MediaPackagesCardComponent } from "@root/shared/media-packages-card/media-packages-card.component";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";
import { compressAndUpdateMediaPackages } from "@root/shared/utilities/media.utilities";
import { DuplicatePropertyMediaPackageComponent } from "@root/views/main/property/property-marketing/components/duplicate-property-media-package/duplicate-property-media-package.component";
import { finalize } from "rxjs/internal/operators/finalize";
import { UpdateMediaPackageListCommunicationService } from "./update-media-package-list-communication.service";

@Component({
  selector: "est-property-media-packages",
  standalone: true,
  imports: [
    ButtonComponent,
    RouterLink,
    MediaPackagesCardComponent,
    TooltipComponent,
    TranslateModule,
    SmallHeaderComponent,
    LoadingOverlayComponent,
  ],
  templateUrl: "./property-media-packages.component.html",
  styleUrl: "./property-media-packages.component.scss",
})
export class PropertyMediaPackagesComponent implements OnInit {
  mediaPackages: IPropertyMediaPackageOverview[] = [];
  isPageLoading: boolean = false;
  linkingSource: LinkingSource = LinkingSource.MEDIA_PACKAGE;
  propertyId = input.required<number>();
  actions: ICardAction[] = [
    {
      label: "PACKAGES.EDIT_PACKAGE",
      callbackFn: (id: number) => this.#routeToEditPackage(id),
    },
    {
      label: "PACKAGES.CHANGE_LINK",
      callbackFn: (id: number) => this.#openChangeLinkDialog(id),
      isVisible: (id: number) => this.#isLinkedCreated(id),
    },
    {
      label: "PACKAGES.CREATE_LINK",
      callbackFn: (id: number) => this.#openCreateLinkDialog(id),
      isVisible: (id: number) => !this.#isLinkedCreated(id),
    },
    {
      label: "PACKAGES.DUPLICATE_PACKAGE.NAME",
      callbackFn: (_, propertyMediaPackage: IPropertyMediaPackageOverview) =>
        this.#openDuplicatePackageModal(propertyMediaPackage),
    },
    {
      label: "PACKAGES.DELETE_PACKAGE",
      callbackFn: (id: number, mediaPackage: IPropertyMediaPackage) =>
        this.#openDeletePropertyMediaPackageDialog(id, mediaPackage.internalTitle, mediaPackage.numberOfLinkedUnits),
    },
  ];
  readonly #router = inject(Router);
  readonly #route = inject(ActivatedRoute);
  readonly #dialogService = inject(DialogService);
  readonly #matDialog = inject(MatDialog);
  readonly #translate = inject(TranslateService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #propertiesMediaPackagesService = inject(PropertiesMediaPackagesService);
  readonly #updateMediaPackageListCommunicationService = inject(UpdateMediaPackageListCommunicationService);
  readonly #isDeletingPropertyMediaPackage = signal(false);

  ngOnInit() {
    this.#getPropertyMediaPackageList();
    this.#subscribeToUpdateList();
  }

  #getPropertyMediaPackageList() {
    this.isPageLoading = true;
    this.#propertiesMediaPackagesService.getPropertyMediaPackages(this.propertyId()).subscribe({
      next: async (data) => {
        this.mediaPackages = await compressAndUpdateMediaPackages(data);
        this.isPageLoading = false;
      },
      error: () => {
        this.isPageLoading = false;
      },
    });
  }

  #isLinkedCreated(id: number) {
    const media = this.mediaPackages.find((media) => media.id === id);
    if (media) return media.numberOfLinkedUnits > 0;
    return false;
  }

  #routeToEditPackage(id: number) {
    this.#router.navigate(["edit", id], { relativeTo: this.#route });
  }

  #openDeletePropertyMediaPackageDialog(id: number, packageName?: string, numberOfLinkedUnits?: number): void {
    this.#dialogService.open(
      {
        title: "PACKAGES.DELETE_PACKAGE",
        callBack: () => this.#deletePropertyMediaPackage(id),
        submitLabel: "IM_SURE",
        isInputIncluded: false,
        descriptions: [
          "PACKAGES.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_PACKAGE",
          this.#getDeleteTextWithPackageName(packageName),
          this.#getDeleteTextWithNumberOfLinkedUnits(numberOfLinkedUnits),
          "PACKAGES.THEREFORE_ONLY_DELETE",
        ],
        isSubmitLoading: this.#isDeletingPropertyMediaPackage,
        additionalData: { id: id, packageName: packageName },
      },
      "confirmation",
    );
  }

  #deletePropertyMediaPackage(mediaPckgId: number): void {
    this.#isDeletingPropertyMediaPackage.set(true);
    this.#propertiesMediaPackagesService
      .deletePropertyMediaPackage(this.propertyId(), mediaPckgId)
      .pipe(
        finalize(() => {
          this.#isDeletingPropertyMediaPackage.set(false);
          this.#matDialog.closeAll();
        }),
      )
      .subscribe({
        next: () => this.#getPropertyMediaPackageList(),
      });
  }

  #getDeleteTextWithPackageName(packageName?: string) {
    return this.#translate.instant("PACKAGES.PACKAGE_NAME", {
      packageName: packageName,
    });
  }

  #getDeleteTextWithNumberOfLinkedUnits(numberOfLinkedUnits?: number) {
    return this.#translate.instant("PACKAGES.MEDIA_PACKAGES.IS_LINKED", {
      numberOfLinkedUnits: numberOfLinkedUnits,
    });
  }

  #openCreateLinkDialog(id: number) {
    this.#matDialog.open(CreateLinkDialogComponent, {
      width: "77dvw",
      data: {
        propertyId: this.propertyId(),
        packageId: id,
        stepName: SourceLevel.Unit,
        linkingSource: this.linkingSource,
      },
    });
  }

  #openChangeLinkDialog(id: number) {
    this.#matDialog.open(EditLinkDialogComponent, {
      width: "77dvw",
      data: {
        propertyId: this.propertyId(),
        packageId: id,
        stepName: SourceLevel.Unit,
        linkingSource: this.linkingSource,
      },
    });
  }

  #subscribeToUpdateList() {
    this.#updateMediaPackageListCommunicationService.reloadMediaPackageListPage$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.#getPropertyMediaPackageList();
      });
  }

  #openDuplicatePackageModal(propertyMediaPackage: IPropertyMediaPackageOverview) {
    this.#matDialog.open<DuplicatePropertyMediaPackageComponent, IDuplicatePackageInput>(
      DuplicatePropertyMediaPackageComponent,
      {
        height: "90dvh",
        width: "75rem",
        data: {
          propertyId: this.propertyId(),
          originalName: propertyMediaPackage.internalTitle,
          originalPackageId: propertyMediaPackage.id,
        },
      },
    );
  }
}
