import { Component, inject } from "@angular/core";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { MatDialog } from "@angular/material/dialog";
import { NoteCategoryPopupComponent } from "@root/views/main/organization/organization-settings/components/system-configurations/components/notes/components/note-category-popup/note-category-popup.component";
import { INoteCategoryPopupInputs } from "@root/views/main/organization/organization-settings/components/system-configurations/components/notes/components/note-category-popup/note-category-popup-inputs.interface";

@Component({
  selector: "est-notes",
  standalone: true,
  imports: [SmallHeaderComponent, ButtonComponent],
  templateUrl: "./notes.component.html",
  styleUrl: "./notes.component.scss",
})
export class NotesComponent {
  readonly #matDialog = inject(MatDialog);

  openCreatePopup() {
    this.#matDialog.open<NoteCategoryPopupComponent, INoteCategoryPopupInputs>(NoteCategoryPopupComponent, {
      maxHeight: "95dvh",
      width: "75rem",
      data: {
        isEditPopup: false,
      },
    });
  }
}
