import { PropertyType } from "@root/data/market/properties/enums/property-type.enum";
import { PropertyTypeTranslationMapper } from "@root/data/market/properties/mappers/property-type-translation.mapper";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";

export const propertyTypeOptions: IDropdownOption[] = [
  {
    label: PropertyTypeTranslationMapper.get(PropertyType.Leasing)!,
    value: PropertyType.Leasing,
  },
  {
    label: PropertyTypeTranslationMapper.get(PropertyType.Sales)!,
    value: PropertyType.Sales,
  },
];
export const propertyTypePreDefinedOptions: IDropdownOption[] = [
  {
    label: "ALL_TYPES",
    value: "",
  },
];
export const portfolioPredefinedOptions: IDropdownOption[] = [
  {
    label: "FAVOURITES",
    value: true,
  },
  {
    label: "PROPERTIES.ALL_PROPERTIES",
    value: false,
  },
];
