import { inject, Injectable } from "@angular/core";
import { NonNullableFormBuilder, Validators } from "@angular/forms";

@Injectable()
export class OrganisationCustomerPageOptionsFormControls {
  readonly #formBuilder = inject(NonNullableFormBuilder);
  settingChangesOnAllLevelsEnabled = this.#formBuilder.control(false);
  favouriteUnitsEnabled = this.#formBuilder.control(false);
  severalFeaturesEnabled = this.#formBuilder.control(false);
  maxNumberOfFavourites = this.#formBuilder.control(1);
  logReasonsEnabled = this.#formBuilder.control(false);
  privacyPermissionUrl = this.#formBuilder.control("", Validators.required);
  marketingPermissionEnabled = this.#formBuilder.control(false);
  marketingPermissionUrl = this.#formBuilder.control("", Validators.required);

  customerPageOptionsForm = this.#formBuilder.group(
    {
      settingChangesOnAllLevelsEnabled: this.settingChangesOnAllLevelsEnabled,
      favouriteUnitsEnabled: this.favouriteUnitsEnabled,
      severalFeaturesEnabled: this.severalFeaturesEnabled,
      maxNumberOfFavourites: this.maxNumberOfFavourites,
      logReasonsEnabled: this.logReasonsEnabled,
      privacyPermissionUrl: this.privacyPermissionUrl,
      marketingPermissionEnabled: this.marketingPermissionEnabled,
      marketingPermissionUrl: this.marketingPermissionUrl,
    },
    {
      updateOn: "change",
    },
  );
}
