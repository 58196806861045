import { Component } from "@angular/core";

@Component({
  selector: "est-confirmation-page",
  standalone: true,
  imports: [],
  templateUrl: "./confirmation-page.component.html",
  styleUrl: "./confirmation-page.component.scss",
})
export class ConfirmationPageComponent {}
