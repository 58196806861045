import { NgClass, NgOptimizedImage } from "@angular/common";
import { Component, input, output } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { MatAccordion, MatExpansionModule } from "@angular/material/expansion";
import { MatIcon } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { ISystemLanguageConfiguration } from "@root/data/market/system-configurations/language/models/system-language-configuration.model";
import { ButtonComponent } from "@root/shared/button/button.component";
import { ExternalTitleInputComponent } from "@root/shared/external-title-input/external-title-input.component";
import { InputComponent } from "@root/shared/input/input.component";
import { IAnswerOptionsFormControls } from "@root/shared/interfaces/demographic-questions-interface";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { IDemographicQuestionPopupInputs } from "../demographic-question-popup-inputs.interface";

@Component({
  selector: "est-demographic-questions-second-step",
  standalone: true,
  imports: [
    InputComponent,
    ExternalTitleInputComponent,
    ButtonComponent,
    TranslateModule,
    MatAccordion,
    MatExpansionModule,
    MatIcon,
    TooltipComponent,
    SmallHeaderComponent,
    NgClass,
    NgOptimizedImage,
  ],
  templateUrl: "./demographic-questions-second-step.component.html",
  styleUrl: "./demographic-questions-second-step.component.scss",
})
export class DemographicQuestionsSecondStepComponent {
  answerOptions = input.required<FormArray<FormGroup<IAnswerOptionsFormControls>>>();
  addAnswerOption = output();

  readonly componentInputs = input.required<IDemographicQuestionPopupInputs>();

  languages = input.required<ILanguage[]>();
  languageConfiguration = input.required<ISystemLanguageConfiguration>();

  questionInternalTitle = input.required<string>();

  getExternalTitleLanguage(languageId: string): ILanguage {
    return this.languages().find((language) => language.languageId === languageId)!;
  }

  isDefaultLanguage(languageId: string): boolean {
    return this.languageConfiguration().defaultEndUserLanguage.languageId === languageId;
  }

  removeAnswerOption(index: number) {
    this.answerOptions().removeAt(index);
  }
}
