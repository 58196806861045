<div class="flex justify-between items-center">
  <est-small-header
    appliedClasses="!mb-2"
    heading="SETTINGS.SYSTEM_CONFIGURATIONS.NOTE_CATEGORIES.NAME"
    subheading="SETTINGS.SYSTEM_CONFIGURATIONS.NOTE_CATEGORIES.DESCRIPTION"
    tooltip="SETTINGS.SYSTEM_CONFIGURATIONS.NOTE_CATEGORIES.NAME"
  ></est-small-header>
  <est-button
    imageSrc="assets/images/icons/plus.svg"
    imagePosition="before"
    imageAlt="+"
    buttonStyleType="button--primary"
    buttonType="button"
    label="SETTINGS.SYSTEM_CONFIGURATIONS.CUSTOMER_CATEGORY.CREATE.BUTTON"
    (click)="openCreatePopup()"
  ></est-button>
</div>
