import { FileExtension } from "@root/data/market/documents/enums/file-extension.enum";
import { IPropertyMediaPackageOverview } from "@root/data/market/properties/models/property-media-package-overview.interface";
import {
  SUPPORTED_EXCEL_EXTENSIONS,
  SUPPORTED_IMAGE_EXTENSIONS,
  SUPPORTED_VIDEO_EXTENSIONS,
} from "@root/shared/constants/supported-extensions.constants";
import { FileType } from "@root/shared/enums/file-type.enum";

export const getFileType = (fileName: string): FileType => {
  const fileNameSplits = fileName.split(".");
  const extensionName = fileNameSplits[fileNameSplits.length - 1].toLowerCase() as FileExtension;
  if (SUPPORTED_VIDEO_EXTENSIONS.includes(extensionName)) return FileType.Video;
  if (SUPPORTED_IMAGE_EXTENSIONS.includes(extensionName)) return FileType.Image;
  if (SUPPORTED_EXCEL_EXTENSIONS.includes(extensionName)) return FileType.Excel;
  return FileType.Other;
};

const createCanvas = (maxWidth: number, image: HTMLImageElement): HTMLCanvasElement => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

  const scaleFactor = maxWidth / image.width;
  const newWidth = maxWidth;
  const newHeight = image.height * scaleFactor;

  canvas.width = newWidth;
  canvas.height = newHeight;

  ctx.drawImage(image, 0, 0, newWidth, newHeight);

  return canvas;
};

const imageOnLoad = (
  image: HTMLImageElement,
  maxWidth: number,
  resolve: (value: string | PromiseLike<string>) => void,
  objectUrl: string,
  quality: number,
) => {
  image.onload = () => {
    const canvas = createCanvas(maxWidth, image);

    canvas.toBlob(
      (compressedBlob) => {
        if (compressedBlob) {
          const compressedUrl = URL.createObjectURL(compressedBlob);
          resolve(compressedUrl);
          URL.revokeObjectURL(objectUrl); // Clean up the original object URL
        } else {
          resolve(objectUrl);
        }
      },
      "image/webp",
      quality,
    );
  };
};

const imageOnError = (
  image: HTMLImageElement,
  objectUrl: string,
  resolve: (value: string | PromiseLike<string>) => void,
): void => {
  image.onerror = () => {
    resolve(objectUrl);
    URL.revokeObjectURL(objectUrl); // Clean up on error
  };
};

export const getCompressedImageURLFromBlobUrl = (
  blobUrl: string,
  maxWidth: number,
  quality: number,
): Promise<string> => {
  return new Promise<string>((resolve) => {
    fetch(blobUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const image = new Image();
        const objectUrl = URL.createObjectURL(blob);

        imageOnLoad(image, maxWidth, resolve, objectUrl, quality);
        imageOnError(image, objectUrl, resolve);
        image.src = objectUrl;
      })
      .catch(() => resolve(blobUrl));
  });
};

export const compressAndUpdateMediaPackages = async (
  data: IPropertyMediaPackageOverview[],
): Promise<IPropertyMediaPackageOverview[]> => {
  const updatedMediaPackages = await Promise.all(
    data.map(async (mediaPackage) => {
      const compressedMediaUrls = await Promise.all(
        mediaPackage.mediaUrls.map(async (mediaURL) => {
          try {
            const compressedUrl = await getCompressedImageURLFromBlobUrl(mediaURL, 200, 0.7);
            return compressedUrl;
          } catch (error) {
            console.error("Error compressing image:", error);
            return mediaURL;
          }
        }),
      );

      // Update the mediaPackage with the compressed URLs
      mediaPackage.mediaUrls = compressedMediaUrls;
      return mediaPackage;
    }),
  );

  return updatedMediaPackages;
};
