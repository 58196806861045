import { Component, inject, input, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, Router } from "@angular/router";
import { FileExtension } from "@root/data/market/documents/enums/file-extension.enum";
import { PropertyMediaPackageSortOrder } from "@root/data/market/properties/enums/property-media-package-sort-order.enum";
import { IUploadedPropertyMediaPackageDetails } from "@root/data/market/properties/models/uploaded-property-media-package-details.model";
import { PropertiesMediaPackagesService } from "@root/data/market/properties/services/properties-media-packages.service";
import { PropertyMediaPackageFormControls } from "@root/shared/abstracts/base-property-media-package/property-media-package.form-controls";
import { ISortChangeConfirmationComponentInputs } from "@root/shared/abstracts/base-property-media-package/sort-change-confirmation/sort-change-confirmation-component-inputs.interface";
import { SortChangeConfirmationComponent } from "@root/shared/abstracts/base-property-media-package/sort-change-confirmation/sort-change-confirmation.component";
import { FileUploadDialogComponent } from "@root/shared/file-upload-dialog/file-upload-dialog.component";
import { IFileUploadInputs } from "@root/shared/file-upload-dialog/file-upload-inputs.interface";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";
import { PropertySortMediaOrderTranslationMapper } from "@root/views/main/property/property-marketing/mappers/property-sort-media-order-translation.mapper";

/**
 * Responsible for creation / editing of Property media packages
 * This component has predefined template ./base-property-media-package.component.html
 */
@Component({
  template: "",
})
export abstract class BasePropertyMediaPackageComponent {
  @ViewChild("stepper", { static: true }) stepper!: MatStepper;
  readonly propertyId = input.required<number>();
  stepperIndex = 0;
  buttonLabels = ["SETTINGS.NAME", "ADD_MEDIA"];
  fileUploadControl = new FormControl();
  readonly createPropertyMediaPackageFormControls = inject(PropertyMediaPackageFormControls);
  isSubmittingForm = false;
  isPageLoading = false;
  packageMedias: IUploadedPropertyMediaPackageDetails[] = [];
  linkedUnitsCount = 0;
  abstract isEdit: boolean;
  protected readonly PropertyMediaPackageSortOrder = PropertyMediaPackageSortOrder;
  protected readonly PropertySortMediaOrderTranslationMapper = PropertySortMediaOrderTranslationMapper;
  protected readonly matDialog = inject(MatDialog);
  protected readonly router = inject(Router);
  protected readonly route = inject(ActivatedRoute);
  protected readonly propertiesMediaPackagesService = inject(PropertiesMediaPackagesService);
  protected readonly snackBarService = inject(SnackbarService);

  get formControls() {
    return this.createPropertyMediaPackageFormControls.formGroup.controls;
  }

  abstract submitForm(): void;

  goToNextPage() {
    this.stepper.selected!.stepControl.updateValueAndValidity();
    markAllControlsAsTouchedAndDirty(this.stepper.selected!.stepControl as FormControl);
    if (this.stepper.selected?.stepControl.valid) this.stepperIndex++;
  }

  goToPreviousPage() {
    this.stepperIndex--;
  }

  openFileUploadDialog() {
    this.matDialog.open<FileUploadDialogComponent, IFileUploadInputs>(FileUploadDialogComponent, {
      width: "40rem",
      data: {
        control: this.fileUploadControl,
        supportedTypes: [FileExtension.PNG, FileExtension.JPEG, FileExtension.JPG, FileExtension.MP4],
        isMultiple: true,
        maxSizeInMb: 100,
      },
    });
  }

  openSortChangeConfirmationDialog() {
    if (this.linkedUnitsCount === 0) return;
    this.matDialog.open<SortChangeConfirmationComponent, ISortChangeConfirmationComponentInputs>(
      SortChangeConfirmationComponent,
      {
        data: {
          linkedUnits: this.linkedUnitsCount,
          control: this.formControls.sortMediaOrder,
        },
        width: "28rem",
      },
    );
  }
}
