import { UNITS_COLUMN_NAMES } from "@root/views/main/toolbox/import-data/constants/units-fields-names.constants";

export const UNITS_FIELDS_REQUIRED_HASHMAP = new Map<string, boolean>([
  [UNITS_COLUMN_NAMES.unitId1, true],
  [UNITS_COLUMN_NAMES.unitId2, false],
  [UNITS_COLUMN_NAMES.unitId3, false],
  [UNITS_COLUMN_NAMES.housingType, true],
  [UNITS_COLUMN_NAMES.listingType, false],
  [UNITS_COLUMN_NAMES.constructionYear, false],
  [UNITS_COLUMN_NAMES.plotNumber, false],
  [UNITS_COLUMN_NAMES.administrationStartDate, false],
  [UNITS_COLUMN_NAMES.administrationEndDate, false],
  [UNITS_COLUMN_NAMES.vacancyDate, false],
  [UNITS_COLUMN_NAMES.longitude, true],
  [UNITS_COLUMN_NAMES.latitude, true],
  [UNITS_COLUMN_NAMES.isUnderRenovation, false],
  [UNITS_COLUMN_NAMES.renovationStartDate, false],
  [UNITS_COLUMN_NAMES.renovationEndDate, false],
  [UNITS_COLUMN_NAMES.seoTitle, false],
  [UNITS_COLUMN_NAMES.metaDescription, false],
  [UNITS_COLUMN_NAMES.externalLink1, false],
  [UNITS_COLUMN_NAMES.externalLink2, false],
  [UNITS_COLUMN_NAMES.externalLink3, false],
  [UNITS_COLUMN_NAMES.energyLabel, false],
  [UNITS_COLUMN_NAMES.floor, false],
  [UNITS_COLUMN_NAMES.houseNumber, true],
  [UNITS_COLUMN_NAMES.street, true],
  [UNITS_COLUMN_NAMES.door, false],
  [UNITS_COLUMN_NAMES.side, false],
  [UNITS_COLUMN_NAMES.zipCode, true],
  [UNITS_COLUMN_NAMES.city, true],
  [UNITS_COLUMN_NAMES.country, true],
  [UNITS_COLUMN_NAMES.paymentFrequency, true],
  [UNITS_COLUMN_NAMES.salePrice, false],
  [UNITS_COLUMN_NAMES.rentPrice, false],
  [UNITS_COLUMN_NAMES.acontoHeat, false],
  [UNITS_COLUMN_NAMES.acontoWater, false],
  [UNITS_COLUMN_NAMES.acontoElectricity, false],
  [UNITS_COLUMN_NAMES.acontoOperatingCosts, false],
  [UNITS_COLUMN_NAMES.acontoResidentsSociety, false],
  [UNITS_COLUMN_NAMES.internetFee, false],
  [UNITS_COLUMN_NAMES.antennaFee, false],
  [UNITS_COLUMN_NAMES.depositMonths, true],
  [UNITS_COLUMN_NAMES.prepaidMonths, true],
  [UNITS_COLUMN_NAMES.nonCancellationPeriodMonths, true],
  [UNITS_COLUMN_NAMES.rentalCondition, false],
  [UNITS_COLUMN_NAMES.rentalPeriodMonths, false],
  [UNITS_COLUMN_NAMES.interiorMaintenance, false],
  [UNITS_COLUMN_NAMES.exteriorMaintenance, false],
  [UNITS_COLUMN_NAMES.usageType, true],
  [UNITS_COLUMN_NAMES.usageTypeVisible, false],
  [UNITS_COLUMN_NAMES.noticePeriodDays, true],
  [UNITS_COLUMN_NAMES.roomQuantity, true],
  [UNITS_COLUMN_NAMES.area, true],
  [UNITS_COLUMN_NAMES.registeredArea, false],
  [UNITS_COLUMN_NAMES.plotArea, false],
  [UNITS_COLUMN_NAMES.unitOrientation, false],
  [UNITS_COLUMN_NAMES.commonArea, false],
  [UNITS_COLUMN_NAMES.internalArea, false],
  [UNITS_COLUMN_NAMES.bedroomQuantity, false],
  [UNITS_COLUMN_NAMES.balconyQuantity, false],
  [UNITS_COLUMN_NAMES.terraceQuantity, false],
  [UNITS_COLUMN_NAMES.gardenQuantity, false],
  [UNITS_COLUMN_NAMES.restroomQuantity, false],
  [UNITS_COLUMN_NAMES.livingRoomQuantity, false],
  [UNITS_COLUMN_NAMES.internalStorageRoomQuantity, false],
  [UNITS_COLUMN_NAMES.additionalRoomQuantity, false],
  [UNITS_COLUMN_NAMES.studentFriendly, false],
  [UNITS_COLUMN_NAMES.seniorFriendly, false],
  [UNITS_COLUMN_NAMES.shareFriendly, false],
  [UNITS_COLUMN_NAMES.stepFreeEntrance, false],
  [UNITS_COLUMN_NAMES.stepFreeEntrancePath, false],
  [UNITS_COLUMN_NAMES.wideEntrance, false],
  [UNITS_COLUMN_NAMES.parkingAccessible, false],
  [UNITS_COLUMN_NAMES.stepFreeBedroomEntrance, false],
  [UNITS_COLUMN_NAMES.wideBedroomEntrance, false],
  [UNITS_COLUMN_NAMES.stepFreeBathroomEntrance, false],
  [UNITS_COLUMN_NAMES.wideBathroomEntrance, false],
  [UNITS_COLUMN_NAMES.bathroomGrabBar, false],
  [UNITS_COLUMN_NAMES.restroomGrabBar, false],
  [UNITS_COLUMN_NAMES.stepFreeShower, false],
  [UNITS_COLUMN_NAMES.showerChair, false],
]);
