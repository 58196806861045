import { Component, input, OnInit } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { mapUnitsToSmallListInput } from "@root/data/market/linked/utilities/linked-package.utilities";
import { ICreatePropertyEventPayload } from "@root/data/market/properties/models/create-property-event-payload.model";
import { IEditPropertyEventPayload } from "@root/data/market/properties/models/edit-property-event-payload.model";
import { BaseEventForm } from "@root/shared/abstracts/base-event-form/base-event.form";
import { AddressComponent } from "@root/shared/address/address.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { DatepickerComponent } from "@root/shared/datepicker/datepicker.component";
import { RepetitionCycle } from "@root/shared/enums/repitition-cycle";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { GeographicalLocationComponent } from "@root/shared/geographical-location/geographical-location.component";
import { InputComponent } from "@root/shared/input/input.component";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { ListUsersForEventComponent } from "@root/shared/list-users-for-event/list-users-for-event.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { ICreatePropertyEventConfirmationInputs } from "@root/shared/property-event-confirmation-popup/property-event-confirmation-inputs.interface";
import { PropertyEventConfirmationPopupComponent } from "@root/shared/property-event-confirmation-popup/property-event-confirmation-popup.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { TimePickerComponent } from "@root/shared/time-picker/time-picker.component";
import { ITimeslot } from "@root/shared/timeslots/timeslot.interface";
import { TimeslotsComponent } from "@root/shared/timeslots/timeslots.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";
import { CreatePropertyEventFormControls } from "@root/views/main/property/property-events/create-property-event/create-property-event.form-controls";
import { SelectedUnitsComponent } from "@root/views/main/property/property-events/create-property-event/selected-units/selected-units.component";
import { DateTime } from "luxon";
import { finalize } from "rxjs/internal/operators/finalize";

@Component({
  selector: "est-edit-property-event",
  standalone: true,
  imports: [
    AddressComponent,
    ButtonComponent,
    DatepickerComponent,
    GeographicalLocationComponent,
    InputComponent,
    ListUsersForEventComponent,
    MatSlideToggle,
    SelectFieldComponent,
    SelectedUnitsComponent,
    TimePickerComponent,
    TimeslotsComponent,
    TooltipComponent,
    TranslateModule,
    ReactiveFormsModule,
    LoadingOverlayComponent,
  ],
  templateUrl: "./edit-property-event.component.html",
  styleUrl: "./edit-property-event.component.scss",
  providers: [CreatePropertyEventFormControls],
})
export class EditPropertyEventComponent extends BaseEventForm implements OnInit {
  readonly propertyId = input.required<number>();
  readonly eventId = input.required<number>();

  isDraft = false;
  isPageLoading = true;
  selectedUnitsIds: string[] = [];
  assignUnits!: ISmallListTableInput<string>[];
  existingTimeslots!: ITimeslot[] | null;

  protected readonly RepetitionCycle = RepetitionCycle;
  protected readonly DateTime = DateTime;

  ngOnInit() {
    this.getAddressLookupsOptions();
    this.getResponsibleAgents(this.propertyId());
    this.getEventDetails();
    this.propertiesEventsService.getUnitsAssignedToEvents(this.eventId()).subscribe((data) => {
      this.assignUnits = mapUnitsToSmallListInput(data);
    });
  }
  getEventDetails() {
    this.propertiesEventsService
      .getPropertyEventById(this.propertyId(), this.eventId())
      .pipe(finalize(() => (this.isPageLoading = false)))
      .subscribe((data) => {
        this.selectedUnitsIds = data.selectedUnitsIds;
        this.isDraft = data.isDraft;
        this.componentFormControls.form.patchValue(data);
        this.componentFormControls.form.controls.date.patchValue(data.startTime);
        if (data.repetition) {
          this.componentFormControls.form.controls.repetition.enable();
          if (data.repetition.occurrences) {
            this.componentFormControls.form.controls.repetition.controls.endDate.disable();
          } else {
            this.componentFormControls.form.controls.repetition.controls.occurrences.disable();
          }
        }
        if (this.bookingFormControls.type.value) {
          this.componentFormControls.bookingToggleControl.setValue(true);
          this.componentFormControls.bookingToggleControl.enable();
          this.componentFormControls.toggleBookingFormGroup(true);
          this.existingTimeslots = data.booking?.timeSlots || null;
        }
      });
  }

  goToEventsList() {
    this.router.navigate([`properties/${this.propertyId()}/events/all`]);
  }

  saveAsDraft() {
    this.componentFormControls.form.controls.isDraft.setValue(true);
    this.openEditEventConfirmationModal();
  }

  editAndPublishEvent() {
    this.componentFormControls.form.controls.isDraft.setValue(false);
    this.openEditEventConfirmationModal();
  }

  openEditEventConfirmationModal() {
    if (this.componentFormControls.form.invalid) {
      markAllControlsAsTouchedAndDirty(this.componentFormControls.form);
      return;
    }
    this.matDialog.open<PropertyEventConfirmationPopupComponent, ICreatePropertyEventConfirmationInputs>(
      PropertyEventConfirmationPopupComponent,
      {
        width: "36rem",
        data: {
          formValue: this.componentFormControls.form.value as ICreatePropertyEventPayload,
          callbackFn: this.editEvent.bind(this),
          loadingSignal: this.isSendingRequest,
          namesHashmap: this.agentNamesHashmap,
          title: "EVENTS.EDIT_EVENT",
          tooltip: "EVENTS.EDIT_EVENT",
          description: "EVENTS.EDIT_EVENT_CONFIRMATION_DESCRIPTION",
        },
      },
    );
  }

  editEvent() {
    this.isSendingRequest.set(true);
    this.propertiesEventsService
      .editPropertyEventById(
        this.propertyId(),
        this.eventId(),
        this.componentFormControls.form.value as IEditPropertyEventPayload,
      )
      .subscribe({
        next: () => {
          this.goToEventsList();
          this.snackbarService.open(SnackbarType.Success);
          this.matDialog.closeAll();
        },
        error: () => this.isSendingRequest.set(false),
      });
  }
}
