<div class="flex flex-col gap-4">
  <p class="text-md font-medium">
    {{ "ORGANISATION.ORGANISATION_SYSTEM_MODULES.CUSTOMER_PAGE.COMMUNICATION.CONTACT_INFORMATION_TITLE" | translate }}
  </p>
  <p
    class="text-sm font-light"
    [innerHTML]="
      'ORGANISATION.ORGANISATION_SYSTEM_MODULES.CUSTOMER_PAGE.COMMUNICATION.CONTACT_INFORMATION_BODY' | translate
    "
  ></p>
</div>

@if (isPageLoading) {
  <est-loading-overlay appliedClasses="relative mt-20"></est-loading-overlay>
} @else {
  <form [formGroup]="communicationContactDetailsFG">
    <mat-slide-toggle
      class="text-sm font-light my-6"
      [formControl]="useDivisionDetailsControl"
      (change)="patchUseDivisionDetails($event.checked)"
    >
      {{ "ORGANISATION.ORGANISATION_SYSTEM_MODULES.CUSTOMER_PAGE.COMMUNICATION.USE_DIVISION_DETAILS" | translate }}
    </mat-slide-toggle>

    @if (!useDivisionDetailsControl.value) {
      <div class="flex flex-col">
        <p class="text-sm mb-4">
          {{ "ORGANISATION.ORGANISATION_SYSTEM_MODULES.CUSTOMER_PAGE.COMMUNICATION.CONTACT_DETAILS" | translate }}
        </p>

        <est-address
          [formGroup]="addressFormGroup"
          [lookups]="addressLookups"
          (selectedValueChange)="patchAddressFields($event)"
        ></est-address>

        <div class="flex flex-wrap mb-4" (focusout)="patchCommunicationContactDetails()">
          <est-input
            appliedClass="mr-4 w-[18.75rem] max-breakpoint-small-desktop-screen:mb-4"
            [control]="contactPersonNameControl"
            [label]="'CONTACT_PERSON'"
          ></est-input>
          <est-input
            appliedClass="mr-4 w-[18.75rem] "
            [control]="contactPersonEmailControl"
            label="CONTACT_EMAIL"
            [type]="'email'"
          ></est-input>
          <est-phone-input [control]="phoneControl"></est-phone-input>
        </div>
      </div>
    }
  </form>
}
