import { inject, Injectable } from "@angular/core";
import { TimezonesRepository } from "@root/data/market/timezones/repositories/timezones.repository";
import { map, Observable } from "rxjs";
import { ITimezoneDropdownOption } from "@root/views/main/organization/organization-settings/components/system-configurations/components/general/timezone-dropdown-option.interface";
import { IPaginationPayload } from "@root/shared/interfaces/pagination-payload.interface";
import { mapPaginatedSystemTimezonesToDropdownOption } from "@root/views/main/organization/organization-settings/components/system-configurations/components/general/general.utilities";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";

@Injectable({
  providedIn: "root",
})
export class TimezonesService {
  readonly #timezonesRepository = inject(TimezonesRepository);

  getSystemTimeZones() {
    return this.#timezonesRepository.getSystemTimeZones();
  }

  getPaginatedSystemTimeZones(
    payload: IPaginationPayload & { isInitial?: boolean },
  ): Observable<ISignalPaginatedResponse<ITimezoneDropdownOption>> {
    return this.#timezonesRepository
      .getPaginatedSystemTimezones(payload)
      .pipe(mapPaginatedSystemTimezonesToDropdownOption());
  }

  getSystemTimeZonesDropdownOptions(): Observable<ITimezoneDropdownOption[]> {
    return this.#timezonesRepository.getSystemTimeZones().pipe(
      map((timezones) =>
        timezones.map((timezone) => ({
          label: timezone.displayName,
          value: timezone.id,
          id: timezone.id,
          displayName: timezone.displayName,
          utcOffset: timezone.utcOffset,
        })),
      ),
    );
  }
}
