<div class="bg-white rounded-[0.625rem] p-6 r-screen-[25rem] relative">
  <est-subheader
    date="Mon Feb 19 2024 14:20:06 GMT+0200"
    label="PROPERTIES.NAME"
    name="Jesper Thorkildsen"
    tooltip="PROPERTIES.NAME"
  ></est-subheader>
  <div class="flex flex-row justify-between mt-12 mb-12">
    <est-small-header
      appliedClasses="!mb-2"
      subheadingAppliedClasses="!max-w-[36.1875rem]"
      heading="PROPERTIES.PROPERTIES_OVERVIEW.TITLE"
      subheading="PROPERTIES.PROPERTIES_OVERVIEW.DESCRIPTION"
      tooltip="PROPERTIES.PROPERTIES_OVERVIEW.TOOLTIP"
    ></est-small-header>
    <est-button
      (click)="openCreatePropertyModal()"
      appliedClasses="!px-6"
      buttonStyleType="button--primary"
      buttonType="button"
      class="!ms-auto"
      imageAlt="ADD"
      imageSrc="assets/images/icons/plus.svg"
      label="CREATE_NEW"
    />
  </div>
  <div class="flex items-center justify-between mb-4 gap-y-4 max-xl:flex-col-reverse max-xl:items-start">
    <div class="flex items-center gap-4">
      <est-select-field
        (changeSelectValue)="paginatedData.currentPage.set(1)"
        [dropDownOptions]="[]"
        [initialValue]="false"
        [predefinedOptions]="portfolioPredefinedOptions"
        [selectMultipleOptions]="false"
        [selectSignal]="isFavouriteSignal"
        appliedClass="!w-[18.75rem]"
      ></est-select-field>
      <est-select-field
        (changeSelectValue)="paginatedData.currentPage.set(1)"
        [dropDownOptions]="propertyTypeOptions"
        [predefinedOptions]="propertyTypePreDefinedOptions"
        [selectMultipleOptions]="false"
        [selectSignal]="propertyTypeSignal"
        appliedClass="!w-[9.375rem]"
        initialValue=""
      ></est-select-field>
    </div>
    <est-search-input [searchSignal]="searchSignal"></est-search-input>
  </div>
  <est-large-list-table
    (heart)="favoriteProperty($event)"
    [data]="paginatedData.results()"
    [headers]="headers"
    [isLoading]="isTableLoading"
    [sortingFilterSignal]="sortingFilterSignal"
    containsHearts
    [actions]="actions"
  />
  <est-pagination [paginationValues]="paginatedData"></est-pagination>
</div>
