import { Component, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ICustomerCardInputs } from "@root/shared/customer-card/customer-card-inputs.interface";
import { CustomerCardComponent } from "@root/shared/customer-card/customer-card.component";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { InputComponent } from "@root/shared/input/input.component";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: "est-dashboard",
  standalone: true,
  imports: [UnderDevelopmentComponent, ButtonComponent, InputComponent],
  templateUrl: "./dashboard.component.html",
  styleUrl: "./dashboard.component.scss",
})
export class DashboardComponent {
  readonly #matDialog = inject(MatDialog);

  legalEntityIdControl = new FormControl<number>(73, { nonNullable: true, validators: [Validators.min(1)] });

  protected open() {
    this.#matDialog.open<CustomerCardComponent, ICustomerCardInputs>(CustomerCardComponent, {
      width: "75rem",
      height: "95dvh",
      data: {
        legalEntityId: this.legalEntityIdControl.value,
        sourceLevel: SourceLevel.Organisation,
      },
    });
  }
}
