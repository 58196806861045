import { Routes } from "@angular/router";
import { CommunicationComponent } from "@root/views/main/organization/organization-system-modules/customer-page/communication/communication.component";
import { OptionsComponent } from "@root/views/main/organization/organization-system-modules/customer-page/options/options.component";
import { StylingComponent } from "@root/views/main/organization/organization-system-modules/customer-page/styling/styling.component";
import { TextComponent } from "@root/views/main/organization/organization-system-modules/customer-page/text/text.component";
import { textPageRoutes } from "./text/text.routes";

export const customerPageRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "styling",
  },
  {
    path: "styling",
    component: StylingComponent,
    data: {
      name: "SYSTEM_MODULES.CUSTOMER_PAGE.STYLING",
    },
  },
  {
    path: "text",
    component: TextComponent,
    data: {
      name: "SYSTEM_MODULES.CUSTOMER_PAGE.TEXT",
    },
    children: textPageRoutes,
  },
  {
    path: "communication",
    component: CommunicationComponent,
    data: {
      name: "SYSTEM_MODULES.CUSTOMER_PAGE.COMMUNICATION",
    },
  },
  {
    path: "options",
    component: OptionsComponent,
    data: {
      name: "SYSTEM_MODULES.CUSTOMER_PAGE.OPTIONS",
    },
  },
];
