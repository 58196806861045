import { NgOptimizedImage } from "@angular/common";
import { Component, computed, effect, ElementRef, input, output, signal, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatIcon } from "@angular/material/icon";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { TranslateModule } from "@ngx-translate/core";
import { FileType } from "@root/shared/enums/file-type.enum";
import { MediaQuality } from "@root/shared/enums/media-quality.enum";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { StatusBadgeComponent } from "@root/shared/status-badge/status-badge.component";
import { getFileExtension } from "@root/shared/utilities/files.utilities";
import { IMediaFormGroup } from "@root/views/main/property/property-marketing/components/media-form-group.interface";
import { PROPERTY_MEDIA_TYPE_OPTIONS } from "@root/views/main/property/property-marketing/constants/property-media.constants";
import { DateTime } from "luxon";

@Component({
  selector: "est-media-card",
  standalone: true,
  imports: [
    SelectFieldComponent,
    TranslateModule,
    StatusBadgeComponent,
    MatMenu,
    MatMenuItem,
    MatIcon,
    MatMenuTrigger,
    NgOptimizedImage,
  ],
  templateUrl: "./media-card.component.html",
  styleUrl: "./media-card.component.scss",
})
export class MediaCardComponent {
  protected readonly FileType = FileType;
  protected readonly MediaQuality = MediaQuality;
  protected readonly getFileExtension = getFileExtension;

  @ViewChild("video") video?: ElementRef<HTMLVideoElement>;

  mediaSrc = signal<string>("");
  isVideoPaused = signal(true);
  mediaTypeOptions: IDropdownOption[] = PROPERTY_MEDIA_TYPE_OPTIONS;

  mediaFormGroup = input.required<FormGroup<IMediaFormGroup>>();
  uploadedBy = input.required<string>();
  fileName = input.required<string>();
  uploadDate = input<string>(new Date().toISOString());

  delete = output<void>();
  open = output<void>();
  onDropDownChange = output<{ id: number; control: any }>();

  uploadInfo = computed(() => `${this.uploadedBy()}, ${DateTime.fromISO(this.uploadDate()).toFormat("DD")}`);
  fileNameNoExtension = computed(() => {
    const fileNameSplits = this.fileName().split(".");
    if (fileNameSplits.length > 1) fileNameSplits.pop();
    return fileNameSplits.join(".");
  });

  #imageSrcUrlEffect$ = effect(
    () => {
      this.mediaSrc.set(this.mediaFormGroup().controls.filePath.value!);
    },
    { allowSignalWrites: true },
  );

  togglePlayVideo(): void {
    if (!this.video) return;
    if (this.video.nativeElement.paused) {
      this.video.nativeElement.play();
    } else {
      this.video.nativeElement.pause();
    }
  }

  emitValueChange() {
    this.onDropDownChange.emit({
      id: this.mediaFormGroup().controls.id.value!,
      control: { mediaType: this.mediaFormGroup().controls.mediaType.value },
    });
  }
}
