import { NgOptimizedImage } from "@angular/common";
import { Component, DestroyRef, effect, EffectRef, inject, signal, WritableSignal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenu, MatMenuTrigger } from "@angular/material/menu";
import { RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { DemographicQuestionsService } from "@root/data/market/demographic-questions/services/demographic-questions.service";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { ButtonComponent } from "@root/shared/button/button.component";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { ExternalTitlesPopupComponent } from "@root/shared/external-titles-popup/external-titles-popup.component";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { ITableAction } from "@root/shared/interfaces/table-action.interface";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { finalize } from "rxjs/internal/operators/finalize";
import { IDemographicQuestionPopupInputs } from "./demographic-question-popup/demographic-question-popup-inputs.interface";
import { DemographicQuestionPopupComponent } from "./demographic-question-popup/demographic-question-popup.component";
import { DemographicQuestionsTableHeader } from "./demographic-questions-table-header";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "est-demographic-questions",
  standalone: true,
  imports: [
    MatMenu,
    NgOptimizedImage,
    TranslateModule,
    MatMenuTrigger,
    RouterLink,
    SmallHeaderComponent,
    ButtonComponent,
    PaginationComponent,
    SmallListTableComponent,
  ],
  templateUrl: "./demographic-questions.component.html",
  styleUrl: "./demographic-questions.component.scss",
})
export class DemographicQuestionsComponent extends BasePaginatedTableWithSearchComponent {
  readonly #demographicQuestionsService = inject(DemographicQuestionsService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #matDialog = inject(MatDialog);
  readonly #dialogService = inject(DialogService);
  protected readonly ExternalTitlesPopupComponent = ExternalTitlesPopupComponent;

  restRow: WritableSignal<null | number> = signal(null);
  #isDeletingDemographicQuestion = signal(false);

  override headers: ITableHeader[] = DemographicQuestionsTableHeader;
  override sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "internalTitle",
    sortDescending: false,
  });
  override actions: ITableAction[] = [
    {
      isVisible: (row: ISmallListTableInput) => row["isInheritanceActive"] === true,
      callbackFn: (row: ISmallListTableInput) => this.updateDemographicInheritance(row, true),
      labelFn: () => "DEACTIVATE",
    },
    {
      isVisible: (row: ISmallListTableInput) => row["isInheritanceActive"] === false,
      callbackFn: (row: ISmallListTableInput) => this.updateDemographicInheritance(row, true),
      labelFn: () => "ACTIVATE",
    },
    {
      isVisible: (row: ISmallListTableInput) => row["source"] === SourceLevel.Organisation,
      callbackFn: (row: ISmallListTableInput) => this.#openEditPopup(row.id),
      labelFn: () => "EDIT",
    },
    {
      isVisible: (row: ISmallListTableInput) => row["source"] === SourceLevel.Organisation,
      callbackFn: (row: ISmallListTableInput) => this.#openDeletePopup(row),
      labelFn: () => "DELETE",
    },
  ];

  override loadDataEffect$: EffectRef = effect(() => this.#loadDocumentsWithParams());

  override loadData(params: IPaginationSortPayload): void {
    this.isTableLoading = true;
    this.#demographicQuestionsService
      .getPaginatedDemographicQuestions(params, SourceLevel.Organisation)
      .pipe(finalize(() => (this.isTableLoading = false)))
      .subscribe((paginatedDemographicQuestions) =>
        copyPaginatedSignalResponse(this.paginatedData, paginatedDemographicQuestions),
      );
  }

  #loadDocumentsWithParams(): void {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      pageNumber: this.paginatedData.currentPage(),
      search: this.searchSignal(),
    });
  }

  #openDeletePopup(row: ISmallListTableInput) {
    this.#dialogService.open(
      {
        title: "SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.DELETE.NAME",
        callBack: () => this.#deleteDemographicQuestion(row.id),
        submitLabel: "DELETE",
        isInputIncluded: false,
        descriptions: ["SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.DELETE.CONFIRMATION"],
        isSubmitLoading: this.#isDeletingDemographicQuestion,
      },
      "confirmation",
    );
  }

  #deleteDemographicQuestion(id: number) {
    this.#isDeletingDemographicQuestion.set(true);
    this.#demographicQuestionsService
      .deleteDemographicQuestion(SourceLevel.Organisation, id)
      .pipe(
        finalize(() => {
          this.#isDeletingDemographicQuestion.set(false);
          this.#dialogService.close();
        }),
      )
      .subscribe(() => this.#loadDocumentsWithParams());
  }

  updateDemographicInheritance(row: any, isAction?: boolean) {
    const sourceLevel: SourceLevel = row["source"] === "-" ? SourceLevel.Organisation : row["source"];

    this.#demographicQuestionsService.toggleDemographicQuestionInheritance(sourceLevel, row.id).subscribe(() => {
      if (isAction) this.#toggleRowInheritanceButton(row);
    });
  }

  #toggleRowInheritanceButton(row: any) {
    row.isInheritanceActive = !row.isInheritanceActive;
  }

  openCreatePopup() {
    this.#matDialog
      .open<DemographicQuestionPopupComponent, IDemographicQuestionPopupInputs>(DemographicQuestionPopupComponent, {
        width: "75rem",
        maxHeight: "95dvh",
        data: {
          sourceLevel: SourceLevel.Organisation,
          isEditPopup: false,
        },
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((shouldUpdateList: boolean = false) => {
        if (shouldUpdateList) this.#loadDocumentsWithParams();
      });
  }

  #openEditPopup(demographicQuestionId: number) {
    this.#matDialog
      .open<DemographicQuestionPopupComponent, IDemographicQuestionPopupInputs>(DemographicQuestionPopupComponent, {
        width: "75rem",
        maxHeight: "95dvh",
        data: {
          sourceLevel: SourceLevel.Organisation,
          isEditPopup: true,
          demographicQuestionId: demographicQuestionId,
        },
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((shouldUpdateList: boolean = false) => {
        if (shouldUpdateList) this.#loadDocumentsWithParams();
      });
  }
}
