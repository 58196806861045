import { UnitFieldType } from "@root/data/market/import/enums/unit-field-type.enum";
import { UNITS_COLUMN_NAMES } from "@root/views/main/toolbox/import-data/constants/units-fields-names.constants";

export const UNITS_FIELDS_TYPES_HASHMAP = new Map<string, UnitFieldType>([
  [UNITS_COLUMN_NAMES.unitId1, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.unitId2, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.unitId3, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.listingType, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.housingType, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.constructionYear, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.plotNumber, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.administrationStartDate, UnitFieldType.Date],
  [UNITS_COLUMN_NAMES.administrationEndDate, UnitFieldType.Date],
  [UNITS_COLUMN_NAMES.vacancyDate, UnitFieldType.Date],
  [UNITS_COLUMN_NAMES.longitude, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.latitude, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.isUnderRenovation, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.renovationStartDate, UnitFieldType.Date],
  [UNITS_COLUMN_NAMES.renovationEndDate, UnitFieldType.Date],
  [UNITS_COLUMN_NAMES.seoTitle, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.metaDescription, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.externalLink1, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.externalLink2, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.externalLink3, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.energyLabel, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.floor, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.houseNumber, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.street, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.door, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.side, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.zipCode, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.city, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.country, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.paymentFrequency, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.salePrice, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.rentPrice, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.acontoHeat, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.acontoWater, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.acontoElectricity, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.acontoOperatingCosts, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.acontoResidentsSociety, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.internetFee, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.antennaFee, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.depositMonths, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.prepaidMonths, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.nonCancellationPeriodMonths, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.rentalCondition, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.rentalPeriodMonths, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.interiorMaintenance, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.exteriorMaintenance, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.usageType, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.usageTypeVisible, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.noticePeriodDays, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.roomQuantity, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.area, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.registeredArea, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.plotArea, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.unitOrientation, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.commonArea, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.internalArea, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.bedroomQuantity, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.balconyQuantity, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.terraceQuantity, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.gardenQuantity, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.restroomQuantity, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.livingRoomQuantity, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.internalStorageRoomQuantity, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.additionalRoomQuantity, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.studentFriendly, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.seniorFriendly, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.shareFriendly, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.stepFreeEntrance, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.stepFreeEntrancePath, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.wideEntrance, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.parkingAccessible, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.stepFreeBedroomEntrance, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.wideBedroomEntrance, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.stepFreeBathroomEntrance, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.wideBathroomEntrance, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.bathroomGrabBar, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.restroomGrabBar, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.stepFreeShower, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.showerChair, UnitFieldType.Boolean],
]);
