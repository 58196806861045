import { NgClass } from "@angular/common";
import { Component, computed, DestroyRef, inject, input, OnInit, Signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatFormField, MatInput } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { MatIcon } from "@angular/material/icon";
import { colorPickerInputValidator } from "@root/shared/validators/color-picker-input.validator";

@Component({
  selector: "est-color-picker-input",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    NgClass,
    SmallHeaderComponent,
    MatInput,
    MatFormField,
    MatFormFieldModule,
    MatIcon,
  ],
  templateUrl: "./color-picker-input.component.html",
  styleUrl: "./color-picker-input.component.scss",
})
export class ColorPickerInputComponent implements OnInit {
  label = input.required<string>();
  withLabelHeader = input(true);
  control = input.required<FormControl<string>>();
  appliedClass = input<string>("");
  #destroyRef = inject(DestroyRef);
  colorValue: string = "";
  isRequired: boolean = false;
  ariaLabel: Signal<string> = computed(() => `${this.label} label`);

  ngOnInit() {
    this.updateControlWithIncludeHash();
    this.isRequired = this.control()?.hasValidator(Validators.required) || false;
    this.control().addValidators(colorPickerInputValidator());
  }

  updateControlWithIncludeHash() {
    this.colorValue = this.control().value;
    this.control()
      ?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((value) => {
        this.colorValue = value.substring(1);
      });
  }

  updateColorFromText(event: Event) {
    const textValue = (event.target as HTMLInputElement)?.value;
    if (!textValue.includes("#")) {
      const formattedValue = `#${textValue}`;
      this.control().setValue(formattedValue, { emitEvent: true });
    } else {
      this.control().setValue(textValue, { emitEvent: true });
    }
  }

  addRequiredToLabel() {
    return this.control()?.hasValidator(Validators.required) ? "REQUIRED" : "";
  }
}
