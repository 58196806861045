import { NgOptimizedImage, SlicePipe } from "@angular/common";
import { Component, inject, input } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateModule } from "@ngx-translate/core";
import { CustomDatePipe } from "@root/core/pipes/custom-date.pipe";
import { IPropertyTextCard } from "@root/data/market/properties/models/property-text-card.interface";
import { ICardAction } from "@root/shared/interfaces/card-Action.interface";
import { LinkedUnitsTablePopupComponent } from "@root/shared/linked-units-table-popup/linked-units-table-popup.component";
import { LinkingSource } from "../enums/linking-source.enum";
import { ILinkedUnitsTablePopupInputs } from "@root/shared/linked-units-table-popup/linked-units-table-popup.inputs";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "est-text-card",
  standalone: true,
  imports: [
    MatIcon,
    MatMenu,
    MatMenuItem,
    TranslateModule,
    MatMenuTrigger,
    NgOptimizedImage,
    SlicePipe,
    LinkedUnitsTablePopupComponent,
    CustomDatePipe,
  ],
  templateUrl: "./text-card.component.html",
  styleUrl: "./text-card.component.scss",
})
export class TextCardComponent {
  readonly #matDialog = inject(MatDialog);

  isPackage = input<boolean>(false);
  linkingSource = input<LinkingSource>(LinkingSource.TEXT_PACKAGE);
  actions = input.required<ICardAction[]>();
  propertyId = input.required<number>();
  textPackage = input.required<IPropertyTextCard>();

  sanitizer = inject(DomSanitizer);

  openLinkedPopup() {
    this.#matDialog.open<LinkedUnitsTablePopupComponent, ILinkedUnitsTablePopupInputs>(LinkedUnitsTablePopupComponent, {
      width: "72rem",
      maxHeight: "95dvh",
      data: {
        packageId: this.textPackage().id,
        packageName: this.textPackage().internalTitle,
        propertyId: this.propertyId(),
        linkingSource: this.linkingSource(),
      },
    });
  }
}
